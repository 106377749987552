import axios from "axios";
import { Api } from "../../api";

interface ApiGetParams {
  url: string;
  parametros?: Record<string, string>;
  queryParametros?: Record<string, any>;
  headers?: Record<string, string>;
}

const ApiGet = async ({
  url,
  parametros,
  queryParametros,
  headers,
}: ApiGetParams) => {
  try {
    let urlFormatada = url;
    if (parametros) {
      Object.keys(parametros).forEach((paramKey) => {
        urlFormatada = urlFormatada.replace(
          `:${paramKey}`,
          parametros[paramKey]
        );
      });
    }
    const response = await axios.get(Api + urlFormatada, {
      params: queryParametros,
      headers: headers || {},
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export default ApiGet;
