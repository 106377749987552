export function TextoFormatoLowerCase(text: string): string {
    const words = text.toLowerCase().split(" ");
  
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toLowerCase() + word.slice(1);
      }
      return "";
    });
  
    return capitalizedWords.join(" ");
  }
  