import React, { ChangeEvent } from "react";

export interface MiniInputProps {
    value?: string;
    type: "text" | "number" | "password" | "email" | "tel" | "date" | "time" | "datetime-local" | "month" | "week" | "url" | "search" | "color" | "file" | "range" | "hidden" | "image" | "button" | "reset" | "submit" | "checkbox" | "radio" | "select" | "textarea" | undefined;
    name: string;
    required?: boolean;
    placeholder?: string;
    classNameInputsSemBorda?: string | (() => string);
    onChange: (value: string) => void;
    readonly?: boolean;
    label?: string;
    classNameLabel?: string;
    accept?: string;
    tittle?: string;
    useRef?: any;
    datacomponent?: string;
    datamonitorid?: string;
    pattern?: any
    id?: string;
    onkeypress?: (value: any) => void;
    onBlur?: any;
    list?: string; // Nova propriedade para associar ao datalist
}

const MiniInput: React.FC<MiniInputProps> = ({
    type,
    value,
    name,
    required,
    classNameInputsSemBorda,
    onChange,
    onkeypress,
    readonly,
    placeholder,
    label,
    classNameLabel,
    accept,
    tittle,
    useRef,
    datacomponent,
    datamonitorid,
    id,
    onBlur,
    list // Adiciona a propriedade list
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };
    // Adaptação para lidar com className sendo função ou string
    const className = typeof classNameInputsSemBorda === 'function'
        ? classNameInputsSemBorda()
        : classNameInputsSemBorda;
    return (
        <input
            style={{
                fontSize: "13px",
                padding: "0px",
                margin: "0px",
                textAlign: "center",
                width: "100%"
            }}
            className={className}
            type={type}
            value={value}
            name={name}
            required={required}
            onChange={handleChange}
            readOnly={readonly}
            onKeyDown={onkeypress}
            placeholder={placeholder}
            accept={accept}
            title={tittle}
            ref={useRef}
            data-component={datacomponent}
            data-monitor-id={datamonitorid}
            id={id}
            onBlur={onBlur}
            list={list} // Adiciona a propriedade list
        />
    );
};

export default MiniInput;
