import React from "react";
import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

interface ModalUploadImageProps {
  show: boolean;
  label?: string;
}

const ModalUploadImage: React.FC<ModalUploadImageProps> = ({ show, label }) => {
  return (
    <Modal show={show} backdrop="static" size="sm" keyboard={false} centered >
      <div className="row bg-light" style={{ textAlign: "center" }}>
        <div className="col-sm">
            <br />
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </div>
      </div>
      {label && (
        <div className="row px-3  bg-light">
            <br />
          <div
            className="col-sm"
            style={{ textAlign: "center" }}
          >
            <label className="form-label" htmlFor="">
              {label}
            </label>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalUploadImage;
