import { FC } from "react";

interface ContainerTitulo {
  titulo: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ContainerTitulo: FC<ContainerTitulo> = ({ titulo }) => {
  return (
    <>
      <div className="row mt-0">
            <label className="form-label font-20 ">{titulo}</label>
      </div>
    </>
  );
};
export default ContainerTitulo;
