import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import ContainerTitulo from "../../../../../components/Formularios/Container/ContainerTitulo"
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../../../../hooks/mascaras";
import { getSessionData } from "../../../../../utils/storageUtils";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../../../../components/dadosFixos/UF";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MascaraValor from "../../../../../components/functions/mascaraValor";


function Previdencia() {

    const id_filial = sessionStorage.getItem("DadosIdfilial")!
    const token = sessionStorage.getItem("MultfilialWebToken")!

    const params = useParams()
    const navigate = useNavigate()

    const [total, setTotal] = useState("")

    const [inputsPrevidencia, setInputsPrevidencia] = useState({
        mesanobase: "",
        custoMensal: "",
        extrasAnuais: "",
        custoAnual: "",
        coment1: "",
        contribuiPC: "",
        participante: "",
        coment2: "",
        patrocinadora: "",
        coment3: "",
        obs: ""
    })

    const [modalMesAnoBase, set_modalMesAnoBase] = useState(false)
    const [mesanobase, set_mesanobase] = useState("")
    const [meses, set_meses] = useState<any>([])
    function gerarMeses() {
        const dataToday = new Date();
        const anoAtual = dataToday.getFullYear(); // Obtém o ano atual
        const mesAtual = dataToday.getMonth() + 1; // Obtém o mês atual (de 0 a 11)
        const datas = [];

        // Gera 3 meses para trás
        let ano = anoAtual;
        let mes = mesAtual;
        for (let i = 0; i < 3; i++) {
            mes--;
            if (mes < 1) {
                mes = 12;
                ano--;
            }
            datas.unshift({ mes: mes.toString().padStart(2, '0'), ano: ano }); // Adiciona ao início do array
        }

        // Reseta para o mês atual e ano
        ano = anoAtual;
        mes = mesAtual;

        // Gera 12 meses para frente
        for (let i = 0; i < 12; i++) {
            datas.push({ mes: mes.toString().padStart(2, '0'), ano: ano });
            mes++;
            if (mes > 12) {
                mes = 1;
                ano++;
            }
        }

        // Atualiza o estado com o array de datas
        set_meses(datas);
    }

    useEffect(function () {

        setInputsPrevidencia({ ...inputsPrevidencia, mesanobase: mesanobase })
    }, [mesanobase])

    useEffect(function () {
        if (params.acao != 'novo') {
            CarregarDados(params.id_previdencia!)
        }
        else {
            set_modalMesAnoBase(true)
            gerarMeses()
        }
        ListarCODSistema("RENDAATIVA")
        ListarCODSistema("RENDAPASSIVA")
    }, [])

    /*
        useEffect(function () {
            if (params.acao != "novo") {
                //CalcularNovoTotal()
            }
        }, [inputsPrevidencia.mesanobase])
    */

    const [carregando, set_carregando] = useState(false)

    function criar(id_filial: string, id_cliente: string) {

        set_carregando(true)
        const dados = {
            inputsPrevidencia,
            usuario: sessionStorage.getItem("NomeUsuario")!,

            rendaAtiva1,
            valorRendaAtiva1,
            valorRendaAtiva1Anual,
            valorRendaAtiva1Extras,
            observacaoRendaAtiva1,
            rendaPassiva1,
            valorRendaPassiva1,
            valorRendaPassiva1Anual,
            valorRendaPassiva1Extras,
            observacaoRendaPassiva1
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/nova/previdencia/${id_filial}/${id_cliente}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_carregando(false)
            toast.success(resposta.data.message)
            setTimeout(() => {
                navigate(-1)
            }, 2000);
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }

    function CarregarDados(id_previdencia: string) {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/unica/previdencia/${id_previdencia}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const previdencia = resposta.data.previdencia

            setInputsPrevidencia({
                mesanobase: previdencia.mesanobase,
                custoMensal: MascaraValor(previdencia.custovidamensal),
                extrasAnuais: MascaraValor(previdencia.extrasanuais),
                custoAnual: MascaraValor(previdencia.custovidaanual),
                coment1: previdencia.coment_01,
                contribuiPC: previdencia.prevcorp,
                participante: previdencia.vlrparticipante == null ? "" : MascaraValor(previdencia.vlrparticipante),
                coment2: previdencia.coment_02,
                patrocinadora: previdencia.vlrpatrocinador == null ? "" : MascaraValor(previdencia.vlrpatrocinador),
                coment3: previdencia.coment_03,
                obs: previdencia.observacao
            })

            //setando renda ativa 1
            setRendaAtiva1(previdencia.rendaativa1 || "")
            setValorRendaAtiva1(MascaraValor(previdencia.valorendaativa1))
            setValorRendaAtiva1Extras(MascaraValor(previdencia.valorrendativa1extras))
            setValorRendaAtiva1Anual(MascaraValor(previdencia.valorrendaativa1anual))
            setObservacaoRendaAtiva1(previdencia.observacaorenadaativa1 || "")

            //setando renda passiva
            setRendaPassiva1(previdencia.rendapassiva1 || "")
            setValorRendaPassiva1(MascaraValor(previdencia.valorendapassiva1))
            setValorRendaPassiva1Extras(MascaraValor(previdencia.valorrendpassiva1extras))
            setValorRendaPassiva1Anual(MascaraValor(previdencia.valorrendapassiva1anual))
            setObservacaoRendaPassiva1(previdencia.observacaorenadapassiva1 || "")


            set_carregando(false)
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }

    function AtualizarPrevidencia(id_previdencia: string) {
        set_carregando(true)
        const dados = {
            inputsPrevidencia,
            usuario: sessionStorage.getItem("NomeUsuario")!,

            rendaAtiva1,
            valorRendaAtiva1,
            valorRendaAtiva1Anual,
            valorRendaAtiva1Extras,
            observacaoRendaAtiva1,
            rendaPassiva1,
            valorRendaPassiva1,
            valorRendaPassiva1Anual,
            valorRendaPassiva1Extras,
            observacaoRendaPassiva1
        }
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/previdencia/${id_previdencia}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            set_carregando(false)
            toast.success(resposta.data.message)
            setTimeout(() => {
                navigate(-1)
            }, 2000);
        }).catch(function (erro) {
            set_carregando(false)
            toast.error(erro.response.data.message || erro.message)
        })
    }

    function CalcularAnual() {
        const custoMensal = parseFloat(inputsPrevidencia.custoMensal.replaceAll(".", "").replaceAll(",", "."))
        const extrasAnuais = parseFloat(inputsPrevidencia.extrasAnuais.replaceAll(".", "").replaceAll(",", "."))
        if (!isNaN(custoMensal) && !isNaN((extrasAnuais))) {
            const anual = (custoMensal * 12) + extrasAnuais
            setInputsPrevidencia({
                ...inputsPrevidencia,
                custoAnual: MascaraValor(anual),
                extrasAnuais: MascaraValor(inputsPrevidencia.extrasAnuais)
            })
        }
        else if (!isNaN(custoMensal)) {
            const anual = (custoMensal * 12)
            setInputsPrevidencia({
                ...inputsPrevidencia,
                custoAnual: MascaraValor(anual)
            })
        }
        else if (!isNaN(extrasAnuais)) {
            setInputsPrevidencia({
                ...inputsPrevidencia,
                custoAnual: MascaraValor(extrasAnuais)
            })
        }
    }

    const [listaRendaAtiva, set_listaRendaAtiva] = useState([])
    const [listaRendaPassiva, set_listaRendaPassiva] = useState([])
    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {

                if (tabela == "RENDAATIVA") {

                    set_listaRendaAtiva(resposta.data)
                }
                else if (tabela == "RENDAPASSIVA") {

                    set_listaRendaPassiva(resposta.data)
                }
                set_carregando(false)
            }).catch(function (erro) {


                toast.error(erro)
            })
    }

    //renda ativa 1
    const [rendaAtiva1, setRendaAtiva1] = useState("")
    const [valorRendaAtiva1, setValorRendaAtiva1] = useState("")
    const [valorRendaAtiva1Anual, setValorRendaAtiva1Anual] = useState("")
    const [valorRendaAtiva1Extras, setValorRendaAtiva1Extras] = useState("")
    const [observacaoRendaAtiva1, setObservacaoRendaAtiva1] = useState("")

    //renda passiva1
    const [rendaPassiva1, setRendaPassiva1] = useState("")
    const [valorRendaPassiva1, setValorRendaPassiva1] = useState("")
    const [valorRendaPassiva1Anual, setValorRendaPassiva1Anual] = useState("")
    const [valorRendaPassiva1Extras, setValorRendaPassiva1Extras] = useState("")
    const [observacaoRendaPassiva1, setObservacaoRendaPassiva1] = useState("")

    function CalcularAnualRendasAtiva() {
        //renada attiva 1
        if (valorRendaAtiva1 !== "" && valorRendaAtiva1Extras !== "") {
            const mensal = parseFloat(valorRendaAtiva1.replace(".", "").replace(",", "."))
            const extras = parseFloat(valorRendaAtiva1Extras.replace(".", "").replace(",", "."))
            const anualTotal = MascaraValor(((mensal * 12) + extras).toString())
            setValorRendaAtiva1Anual(anualTotal)
        }
        else if (valorRendaAtiva1 !== "") {
            const mensal = parseFloat(valorRendaAtiva1.replace(".", "").replace(",", "."))
            setValorRendaAtiva1Anual(MascaraValor((mensal * 12).toString()))
        }
        else if (valorRendaAtiva1Extras !== "") {
            setValorRendaAtiva1Anual(valorRendaAtiva1Extras)
        }
        else {
            setValorRendaAtiva1Anual("")
        }
        //renada passiva 1
        if (valorRendaPassiva1 !== "" && valorRendaPassiva1Extras !== "") {
            const mensal = parseFloat(valorRendaPassiva1.replace(".", "").replace(",", "."))
            const extras = parseFloat(valorRendaPassiva1Extras.replace(".", "").replace(",", "."))
            const anualTotal = MascaraValor(((mensal * 12) + extras).toString())
            setValorRendaPassiva1Anual(anualTotal)
        }
        else if (valorRendaPassiva1 !== "") {
            const mensal = parseFloat(valorRendaPassiva1.replace(".", "").replace(",", "."))
            setValorRendaPassiva1Anual(MascaraValor((mensal * 12).toString()))
        }
        else if (valorRendaPassiva1Extras !== "") {
            setValorRendaPassiva1Anual(valorRendaPassiva1Extras)
        }
        else {
            setValorRendaPassiva1Anual("")
        }
    }
    return (
        <>
            <ModalLoading show={carregando} />
            <ContainerTitulo titulo={params.acao == "novo" ? "Nova Projeção de Ativos " + inputsPrevidencia.mesanobase : params.acao == "editar" ? "Editando Projeção de Ativos " + inputsPrevidencia.mesanobase : "Visualizando Projeção de Ativos " + inputsPrevidencia.mesanobase} />
            <Card>
                <form onSubmit={function (e) {
                    e.preventDefault()
                    console.log(params)
                    if (params.acao == "novo" && params.id_previdencia == "0") {
                        criar(id_filial, params.id_cliente!)
                    }
                    else if (params.acao == "editar" && params.id_previdencia != "0") {
                        AtualizarPrevidencia(params.id_previdencia!)
                    }
                }}>
                    <Card.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm col-md col-lg-6">
                                    <p className="pb-0"><b className="d-block">Projeção de Ativos Financeiros</b></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm col-md col-lg-2">
                                    <p className="mt-3 text-center">Renda Ativa:</p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAtiva1"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={valorRendaAtiva1}
                                        onBlur={function () {
                                            if (valorRendaAtiva1 != "") {
                                                const valorMensal = valorRendaAtiva1.replace(".", "").replace(",", ".")
                                                const NovoValorAnual = parseFloat(valorMensal) * 12
                                                setValorRendaAtiva1(MascaraValor(valorRendaAtiva1))
                                                setValorRendaAtiva1Anual(MascaraValor(NovoValorAnual))
                                            }
                                            else {
                                                setValorRendaAtiva1(MascaraValor(valorRendaAtiva1))
                                            }
                                            CalcularAnualRendasAtiva()
                                        }}
                                        onChange={function (e) {
                                            setValorRendaAtiva1(e)
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Mensal"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAtiva1"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={valorRendaAtiva1Extras}
                                        onBlur={function () {
                                            setValorRendaAtiva1Extras(MascaraValor(valorRendaAtiva1Extras))
                                            CalcularAnualRendasAtiva()
                                        }}
                                        onChange={function (e) {
                                            setValorRendaAtiva1Extras(e)
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Extras Anuais"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAtiva1"
                                        readonly={true}
                                        type="text"
                                        value={valorRendaAtiva1Anual}
                                        onBlur={function () {
                                        }}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Anual"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAtiva1"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={observacaoRendaAtiva1}
                                        onChange={function (e) {
                                            setObservacaoRendaAtiva1(e)
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Observações"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm col-md col-lg-2">
                                    <p className="mt-3 text-center">Renda Passiva:</p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaPassiva1"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={valorRendaPassiva1}
                                        onBlur={function () {
                                            if (valorRendaPassiva1 != "") {
                                                const valorMensal = valorRendaPassiva1.replace(".", "").replace(",", ".")
                                                const NovoValorAnual = parseFloat(valorMensal) * 12
                                                setValorRendaPassiva1(MascaraValor(valorRendaPassiva1))
                                                setValorRendaPassiva1Anual(MascaraValor(NovoValorAnual))
                                            }
                                            else {
                                                setValorRendaPassiva1(MascaraValor(valorRendaPassiva1))
                                            }
                                            CalcularAnualRendasAtiva()
                                        }}
                                        onChange={function (e) {
                                            setValorRendaPassiva1(e)
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Mensal"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaPassiva1"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={valorRendaPassiva1Extras}
                                        onBlur={function () {
                                            setValorRendaPassiva1Extras(MascaraValor(valorRendaPassiva1Extras))
                                            CalcularAnualRendasAtiva()
                                        }}
                                        onChange={function (e) {
                                            setValorRendaPassiva1Extras(e)
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Extras Anuais"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaPassiva1"
                                        readonly={true}
                                        type="text"
                                        value={valorRendaPassiva1Anual}
                                        onBlur={function () {
                                        }}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Anual"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaPassiva1"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={observacaoRendaPassiva1}
                                        onChange={function (e) {
                                            setObservacaoRendaPassiva1(e)
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Observações"
                                    />
                                </div>
                            </div>
                            <div className="row border-top mt-2 pt-2">
                                <div className="col-sm col-md col-lg-2">
                                    <p className="mt-3 text-center">Custo de vida:</p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="textarea"
                                        required
                                        value={inputsPrevidencia.custoMensal}
                                        onBlur={function () {

                                            if (inputsPrevidencia.custoMensal != "") {
                                                const valorMensal = inputsPrevidencia.custoMensal.replace(".", "").replace(",", ".")
                                                const NovoValorAnual = parseFloat(valorMensal) * 12
                                                setInputsPrevidencia({ ...inputsPrevidencia, custoMensal: MascaraValor(inputsPrevidencia.custoMensal), custoAnual: MascaraValor(NovoValorAnual) })
                                            }
                                            else {
                                                setInputsPrevidencia({ ...inputsPrevidencia, custoMensal: MascaraValor(inputsPrevidencia.custoMensal), custoAnual: "" })
                                            }
                                            CalcularAnual()
                                        }}
                                        onChange={function (value) {

                                            setInputsPrevidencia({ ...inputsPrevidencia, custoMensal: value })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Mensal"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        type="textarea"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        value={inputsPrevidencia.extrasAnuais}
                                        onChange={function (value) {
                                            setInputsPrevidencia({
                                                ...inputsPrevidencia, extrasAnuais: value
                                            })
                                        }}
                                        onBlur={function () {
                                            CalcularAnual()
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Extras Anuais"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly
                                        type="textarea"
                                        value={inputsPrevidencia.custoAnual}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Anual"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsPrevidencia.coment1}
                                        onChange={function (e) {
                                            setInputsPrevidencia({ ...inputsPrevidencia, coment1: e })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Obervações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-2">
                                    <p className="mt-3 text-center">Contruibui com Previdência Corporativa? </p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <div className="form-floating">
                                        <select
                                            onChange={function (e) {
                                                setInputsPrevidencia({
                                                    ...inputsPrevidencia,
                                                    contribuiPC: e.target.value
                                                })
                                            }}
                                            disabled={params.acao == "visualizar" ? true : false}
                                            required value={inputsPrevidencia.contribuiPC} className="form-select bordasInferiorInput text-dark" id="floatingSelect" aria-label="Floating label select example">
                                            <option value="">Selecione...</option>
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>

                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-2">
                                    <p className="mt-3 text-center">Participante</p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsPrevidencia.participante}
                                        onBlur={function () {
                                            setInputsPrevidencia({ ...inputsPrevidencia, participante: MascaraValor(inputsPrevidencia.participante) })
                                        }}
                                        onChange={function (e) {
                                            setInputsPrevidencia({ ...inputsPrevidencia, participante: e })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Mensal"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-4">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsPrevidencia.coment2}
                                        onChange={function (e) {
                                            setInputsPrevidencia({ ...inputsPrevidencia, coment2: e })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Obervações"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-sm col-md col-lg-2">
                                    <p className="mt-3 text-center">Patrocinadora</p>
                                </div>
                                <div className="col-sm col-md col-lg-2">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsPrevidencia.patrocinadora}
                                        onBlur={function () {
                                            setInputsPrevidencia({ ...inputsPrevidencia, patrocinadora: MascaraValor(inputsPrevidencia.patrocinadora) })
                                        }}
                                        onChange={function (e) {
                                            setInputsPrevidencia({ ...inputsPrevidencia, patrocinadora: e })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Mensal"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-4">
                                    <InputSemBorda
                                        name="rendaAlmejada"
                                        readonly={params.acao == "visualizar" ? true : false}
                                        type="text"
                                        value={inputsPrevidencia.coment3}
                                        onChange={function (e) {
                                            setInputsPrevidencia({ ...inputsPrevidencia, coment3: e })
                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Obervações"
                                    />
                                </div>
                                <div className="col-sm col-md col-lg-10">
                                    <div className="col-sm col-md col-lg mt-3">
                                        <p><b>Observações gerais:</b></p>
                                    </div>
                                    <div className="form-floating">
                                        <textarea placeholder=" " className="form-control bordasInferiorInput text-dark"
                                            value={inputsPrevidencia.obs}
                                            disabled={params.acao == 'visualizar' ? true : false}
                                            onChange={function (e) {
                                                setInputsPrevidencia({ ...inputsPrevidencia, obs: e.target.value })
                                            }}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex my-2 gap-1 justify-content-between">
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                            <ButtonCustom
                                invisivel={false}
                                disabled={params.acao == 'visualizar' ? true : false}
                                type="submit"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Salvar"
                                icone={mdiCheckCircle}
                            />
                        </div>
                    </Card.Footer>
                </form>
            </Card>

            <Modal show={modalMesAnoBase}>

                <Modal.Header className="bg-white">
                    <Modal.Title>Mês/Ano Base para Projeção de Ativos.</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o mês/ano do inicio do Fluxo a ser criado.</h4>
                            </div>
                            <div className="w-100"></div>

                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select name="" id="" onChange={function (e) {
                                    const data = e.target.value.split("-")
                                    set_mesanobase(data[1] + "/" + data[0])
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value="" selected></option>
                                    {meses.map(function (data: any) {

                                        return <option className="text-center" value={`${data.ano}-${data.mes}`}>{`${data.mes}/${data.ano}`}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Cancelar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        set_modalMesAnoBase(false)
                                        navigate(-1);
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="button"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Continuar"
                                    onclick={function () {
                                        set_modalMesAnoBase(false)
                                    }}
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Previdencia