import axios from "axios";
import { Api } from "../../api";

const ApiPut = async (url: string, dados: any, token?: any) => {
  try {
    const response = await axios.put(Api + url, dados, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default ApiPut;
