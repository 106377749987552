export function TextoFormatoCaptalize(text: string): string {
  const words = text.toLowerCase().split(" ");

  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return "";
  });

  return capitalizedWords.join(" ");
}
