import React, { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import ConsultaComplexidade from "../../ComplexidadeSenha";

interface ValidaComplexidadeSenhaProps {
  senha: string;
}

const ValidaComplexidadeSenha: React.FC<ValidaComplexidadeSenhaProps> = ({
  senha,
}) => {
  const complexidade = ConsultaComplexidade(senha);

  return (
    <div>
      <ProgressBar
        variant={
          complexidade <= 20
            ? "danger"
            : complexidade > 20 && complexidade <= 40
            ? "warning"
            : complexidade > 40 && complexidade <= 60
            ? "primary"
            : complexidade > 60 && complexidade <= 80
            ? "info"
            : "success"
        }
        now={complexidade}
        // label={`${complexidade}%`}
        label={
          complexidade <= 20
            ? "Muito Fraca"
            : complexidade > 20 && complexidade <= 40
            ? "Fraca"
            : complexidade > 40 && complexidade < 60
            ? "Médio"
            : complexidade >= 60 && complexidade < 80
            ? "Forte"
            : "Muito Forte"
        }
      />
    </div>
  );
};

export default ValidaComplexidadeSenha;
