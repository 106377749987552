import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import { Row, Col, Card } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { getSessionData } from "../../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import Tabela from "../../../../components/Formularios/Table";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import ApiGet from "../../../../api/endPoints/useGet";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { mdiCarElectric, mdiFileExcel } from "@mdi/js";
import * as FileSaver from "file-saver"
import exceljs from "exceljs"

function Balanco() {

    const navigate = useNavigate()

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/clientes/form/novo/criar")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    // Função para realizar a busca
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {
            carregarClientes(getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = clientes.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.nome.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })


            set_clientes(resultados)
        }
    }

    const [clientes, set_clientes] = useState([])
    function carregarClientes(id_filial: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/cadastroativos/${id_filial}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {

                set_carregando(false)
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {

                    set_clientes(resposta.data.clientes)
                }
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }

    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_cliente" },
        { titulo: "CPF", acesso: "cpf" },
        { titulo: "Nome", acesso: "nome" },
        { titulo: "Cód Cliente", acesso: "cod_cliente" },
        { titulo: "Email", acesso: "email" },
        { titulo: "Celular", acesso: "telcelular" }
    ]

    //modal loading
    const [carregando, set_carregando] = useState(false)

    //clicar para visu
    function visualizar(dado: any) {

        navigate(`/org/financeira/balancopatrionial/${dado.id_cliente}/visualizar`)
    }

    //clicar para editar
    function editar(dado: any) {
        if (dado.situacao_projeto == 1) {
            toast.error("Projeto já finalizado, não é possível editar.")
            return
        }
        navigate(`/org/financeira/balancopatrionial/${dado.id_cliente}/editar`)
    }

    //verifica permissão --- inicio
    const [permissao_editar, set_permissao_editar] = useState(false)
    const [permissao_visualizar, set_permissao_visualizar] = useState(false)

    async function CarregarPermissões() {
        set_carregando(true);
        const headers = {
            Authorization: getSessionData("MultfilialWebToken") || "",
        };
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: getSessionData("id_perfil") || "",
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarOrgFinanceira === 1) {
                set_permissao_visualizar(false)
            }
            if (response[0].EditarOrgFinanceira === 1) {
                set_permissao_editar(false)
            }
        })
    }

    //esse useefct carrega a listagem tambem dos clienets
    useEffect(function () {
        Search(busca)
    }, [busca])

    const [ListaAtivos, set_ListaAtivos] = useState([])
    const [Listapassivos, set_ListaPassivos] = useState([])
    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {

                if (tabela == "ATIVOS") {

                    set_ListaAtivos(resposta.data)
                }
                else if (tabela == "PASSIVOS") {

                    set_ListaPassivos(resposta.data)
                }
                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }

    useEffect(function () {

        ListarCODSistema("ATIVOS")
        ListarCODSistema("PASSIVOS")
        CarregarPermissões()
    }, [])


    //#region Exportação EXCELJS
    function ExcelExport(dado: any) {

        //carregar informaçõe do usuario
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/informa/balanco/${dado.id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {

            let ativo_01: any = resposta.data.info.orgfin_balancoativo[0]["01_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["01_Ativo"]
                })
                : ""

            let ativo_02: any = resposta.data.info.orgfin_balancoativo[0]["02_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["02_Ativo"]
                })
                : ""


            let ativo_03: any = resposta.data.info.orgfin_balancoativo[0]["03_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["03_Ativo"]
                })
                : ""

            let ativo_04: any = resposta.data.info.orgfin_balancoativo[0]["04_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["04_Ativo"]
                })
                : ""

            let ativo_05: any = resposta.data.info.orgfin_balancoativo[0]["05_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["05_Ativo"]
                })
                : ""

            let ativo_06: any = resposta.data.info.orgfin_balancoativo[0]["06_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["06_Ativo"]
                })
                : ""

            let ativo_07: any = resposta.data.info.orgfin_balancoativo[0]["07_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["07_Ativo"]
                })
                : ""

            let ativo_08: any = resposta.data.info.orgfin_balancoativo[0]["08_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["08_Ativo"]
                })
                : ""

            let ativo_09: any = resposta.data.info.orgfin_balancoativo[0]["09_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["09_Ativo"]
                })
                : ""

            let ativo_10: any = resposta.data.info.orgfin_balancoativo[0]["10_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["10_Ativo"]
                })
                : ""

            let ativo_11: any = resposta.data.info.orgfin_balancoativo[0]["11_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["11_Ativo"]
                })
                : ""

            let ativo_12: any = resposta.data.info.orgfin_balancoativo[0]["12_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["12_Ativo"]
                })
                : ""

            let ativo_13: any = resposta.data.info.orgfin_balancoativo[0]["13_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["13_Ativo"]
                })
                : ""

            let ativo_14: any = resposta.data.info.orgfin_balancoativo[0]["14_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["14_Ativo"]
                })
                : ""
            let ativo_15: any = resposta.data.info.orgfin_balancoativo[0]["15_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["15_Ativo"]
                })
                : ""

            let ativo_16: any = resposta.data.info.orgfin_balancoativo[0]["16_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["16_Ativo"]
                })
                : ""

            let ativo_17: any = resposta.data.info.orgfin_balancoativo[0]["17_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["17_Ativo"]
                })
                : ""

            let ativo_18: any = resposta.data.info.orgfin_balancoativo[0]["18_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["18_Ativo"]
                })
                : ""

            let ativo_19: any = resposta.data.info.orgfin_balancoativo[0]["19_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["19_Ativo"]
                })
                : ""

            let ativo_20: any = resposta.data.info.orgfin_balancoativo[0]["20_Ativo"] != "" ?
                ListaAtivos.find(function (ativo: any) {

                    return ativo.value == resposta.data.info.orgfin_balancoativo[0]["20_Ativo"]
                })
                : ""

            let passivo_01: any = resposta.data.info.orgfin_balancopassivo[0]["01_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["01_passivo"]
                })
                : ""

            let passivo_02: any = resposta.data.info.orgfin_balancopassivo[0]["02_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["02_passivo"]
                })
                : ""


            let passivo_03: any = resposta.data.info.orgfin_balancopassivo[0]["03_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["03_passivo"]
                })
                : ""

            let passivo_04: any = resposta.data.info.orgfin_balancopassivo[0]["04_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["04_passivo"]
                })
                : ""

            let passivo_05: any = resposta.data.info.orgfin_balancopassivo[0]["05_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["05_passivo"]
                })
                : ""

            let passivo_06: any = resposta.data.info.orgfin_balancopassivo[0]["06_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["06_passivo"]
                })
                : ""

            let passivo_07: any = resposta.data.info.orgfin_balancopassivo[0]["07_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["07_passivo"]
                })
                : ""

            let passivo_08: any = resposta.data.info.orgfin_balancopassivo[0]["08_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["08_passivo"]
                })
                : ""

            let passivo_09: any = resposta.data.info.orgfin_balancopassivo[0]["09_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["09_passivo"]
                })
                : ""

            let passivo_10: any = resposta.data.info.orgfin_balancopassivo[0]["10_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["10_passivo"]
                })
                : ""

            let passivo_11: any = resposta.data.info.orgfin_balancopassivo[0]["11_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["11_passivo"]
                })
                : ""

            let passivo_12: any = resposta.data.info.orgfin_balancopassivo[0]["12_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["12_passivo"]
                })
                : ""

            let passivo_13: any = resposta.data.info.orgfin_balancopassivo[0]["13_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["13_passivo"]
                })
                : ""

            let passivo_14: any = resposta.data.info.orgfin_balancopassivo[0]["14_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["14_passivo"]
                })
                : ""
            let passivo_15: any = resposta.data.info.orgfin_balancopassivo[0]["15_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["15_passivo"]
                })
                : ""

            let passivo_16: any = resposta.data.info.orgfin_balancopassivo[0]["16_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["16_passivo"]
                })
                : ""

            let passivo_17: any = resposta.data.info.orgfin_balancopassivo[0]["17_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["17_passivo"]
                })
                : ""

            let passivo_18: any = resposta.data.info.orgfin_balancopassivo[0]["18_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["18_passivo"]
                })
                : ""

            let passivo_19: any = resposta.data.info.orgfin_balancopassivo[0]["19_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["19_passivo"]
                })
                : ""

            let passivo_20: any = resposta.data.info.orgfin_balancopassivo[0]["20_passivo"] != "" ?
                Listapassivos.find(function (passivo: any) {

                    return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["20_passivo"]
                })
                : ""


            const workbook = new exceljs.Workbook()
            const worksheet = workbook.addWorksheet("Balanço Patrimonial")

            const A1 = worksheet.getCell("A1")
            A1.value = "BALANÇO PATRIMONIAL"

            const A2 = worksheet.getCell("A2")
            A2.value = dado.cod_cliente + " - " + dado.nome + " - " + dado.cpf

            const columA = worksheet.getColumn("A")
            columA.width = 45

            const columB = worksheet.getColumn("B")
            columB.width = 25

            const columC = worksheet.getColumn("C")
            columC.width = 35

            //fazendo o bg franco
            for (let i = 1; i < 60; i = i + 1) {

                worksheet.getCell(`A${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`B${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`C${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`D${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`E${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`F${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`G${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`H${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`I${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`J${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`K${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`L${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`M${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`N${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`O${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }

                worksheet.getCell(`P${[i]}`).fill = {

                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF' }
                }
            }

            A1.font = {
                bold: true,
                size: 14
            }

            const rowB = worksheet.getRow(2)
            rowB.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF' }
            }
            const rowC = worksheet.getRow(3)
            rowC.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFF' }
            }

            //preenchendo cabecalho - ATIVO
            const A4 = worksheet.getCell("A4")
            A4.border =
            {
                bottom: { style: 'thin' }
            }
            A4.value = "ATIVO"
            A4.font = {

                bold: true
            }
            A4.alignment = {
                vertical: 'middle', horizontal: 'center'
            }

            const B4 = worksheet.getCell("B4")
            B4.border =
            {
                bottom: { style: 'thin' }
            }
            B4.value = "VALOR"
            B4.font = {

                bold: true
            }
            B4.alignment = {
                vertical: 'middle', horizontal: 'center'
            }

            const C4 = worksheet.getCell("C4")
            C4.border =
            {
                bottom: { style: 'thin' }
            }
            C4.value = "DESCRIÇÃO"
            C4.font = {

                bold: true
            }
            C4.alignment = {
                vertical: 'middle', horizontal: 'center'
            }


            //looping para preenchimento dos ativos
            const ativos = []

            for (let i = 1; i <= 20; i = i + 1) {

                ativos.push({
                    ativo: eval(`ativo_${i.toString().padStart(2, "0")}`),
                    valor: resposta.data.info.orgfin_balancoativo[0][`${i.toString().padStart(2, "0")}_valor`],
                    descricao: resposta.data.info.orgfin_balancoativo[0][`${i.toString().padStart(2, "0")}_descricao`]
                })
            }

            for (let i = 5; i < ativos.length + 5; i = i + 1) {

                let celA = worksheet.getCell(`A${i}`)
                celA.value = ativos[i - 5].ativo.label
                celA.alignment =
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }

                worksheet.getCell(`B${i}`).value = parseFloat(ativos[i - 5].valor)

                let celC = worksheet.getCell(`C${i}`)
                celC.value = ativos[i - 5].descricao
                celC.alignment =
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }

            const A27 = worksheet.getCell("A27")
            A27.value = "Observações Gerais - Ativos"
            A27.font = {
                bold: true
            }
            const A28 = worksheet.getCell("A28")
            A28.value = resposta.data.info.orgfin_balancoativo[0].observacao

            //preenchendo cabecalho - PASSIVO
            const A30 = worksheet.getCell("A30")
            A30.border =
            {
                bottom: { style: 'thin' }
            }
            A30.value = "PASSIVO"
            A30.font = {

                bold: true
            }
            A30.alignment = {
                vertical: 'middle', horizontal: 'center'
            }

            const B30 = worksheet.getCell("B30")
            B30.border =
            {
                bottom: { style: 'thin' }
            }
            B30.value = "VALOR"
            B30.font = {

                bold: true
            }
            B30.alignment = {
                vertical: 'middle', horizontal: 'center'
            }

            const C30 = worksheet.getCell("C30")
            C30.border =
            {
                bottom: { style: 'thin' }
            }
            C30.value = "DESCRIÇÃO"
            C30.font = {

                bold: true
            }
            C30.alignment = {
                vertical: 'middle', horizontal: 'center'
            }

            //looping para preenchimento dos passsivos
            const passivos = []

            for (let i = 1; i <= 20; i = i + 1) {

                passivos.push({
                    passivo: eval(`passivo_${i.toString().padStart(2, "0")}`),
                    valor: resposta.data.info.orgfin_balancopassivo[0][`${i.toString().padStart(2, "0")}_valor`],
                    descricao: resposta.data.info.orgfin_balancopassivo[0][`${i.toString().padStart(2, "0")}_descricao`]
                })
            }

            for (let i = 31; i < passivos.length + 31; i = i + 1) {

                let celA = worksheet.getCell(`A${i}`)
                celA.value = passivos[i - 31].passivo.label
                celA.alignment =
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }

                worksheet.getCell(`B${i}`).value = parseFloat(passivos[i - 31].valor)

                let celC = worksheet.getCell(`C${i}`)
                celC.value = passivos[i - 31].descricao
                celC.alignment =
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }

            const A51 = worksheet.getCell("A51")
            A51.value = "Observações Gerais - Passivos"
            A51.font = {
                bold: true
            }
            const A52 = worksheet.getCell("A52")
            A52.value = resposta.data.info.orgfin_balancopassivo[0].observacao

            workbook.xlsx.writeBuffer().then(function (bufferd) {

                const blob = new Blob([bufferd], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                FileSaver.saveAs(blob, "BalançoPatrimonial.xlsx")
            }).catch(function (erro) {

                toast("Erro ao converter blob.")
            })
            set_carregando(false)
        }).catch(function (erro) {

            set_carregando(false)
            console.log(erro)
            toast.error("Erro ao gerar excel.")
        })
    }

    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Balanço Patrimonial" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="fantasia"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Procurar por cliente"
                                            />
                                        </Col>
                                    </div>
                                </Row>

                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={clientes}
                                        itemsPerPage={10}
                                        onVisuClick={visualizar}
                                        onEditClick={editar}
                                        onEnvio={ExcelExport}
                                        iconeEnvio={mdiFileExcel}
                                        descEnvio="Exportar .xlsx"
                                        usaEnvio={true}
                                        onDeleteClick={function () {

                                        }}
                                        id="codigo"
                                        permissao_editar={permissao_editar}
                                        permissao_visualizar={permissao_visualizar}
                                        permissao_excluir={true}
                                        permissao_envio={false}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ModalLoading show={carregando} />
            </div>
        </>
    )
}

export default Balanco