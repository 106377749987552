import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/login/login';
import { MainPage } from './Pages/mainPage';
import { Usuario } from './Pages/administrativo/usuario/usuario';
import { UsuarioIncluir } from './Pages/administrativo/usuario/usuarioIncluir';
import { UsuarioEditar } from './Pages/administrativo/usuario/usuarioEditar';
import { UsuarioVisualizar } from './Pages/administrativo/usuario/usuarioVisualizar';
import { Permissao } from './Pages/administrativo/permissao/permissao';
import { CodigiodoSistema } from './Pages/administrativo/codigodoSistema/codigodosistema';
import { Empresa } from './Pages/administrativo/empresa/empresa';
import { PermissaoIncluir } from './Pages/administrativo/permissao/permissaoIncluir';
import { PermissaoVisualizar } from './Pages/administrativo/permissao/permissaoVisualizar';
import { PermissaoEditar } from './Pages/administrativo/permissao/permissaoEditar';
import { EmpresaIncluir } from './Pages/administrativo/empresa/empresaIncluir';
import { EmpresaEditar } from './Pages/administrativo/empresa/empresaEditar';
import { EmpresaVisualizar } from './Pages/administrativo/empresa/empresaVisualizar';
import { CodigiodoSistemaIncluir } from './Pages/administrativo/codigodoSistema/codigodosistemaIncluir';
import { CodigiodoSistemaVisualizar } from './Pages/administrativo/codigodoSistema/codigodosistemaVisualizar';
import { CodigiodoSistemaEditar } from './Pages/administrativo/codigodoSistema/codigodosistemaEditar';
import { Filiais } from './Pages/cadastro/filiais/filiais/filiais';
import { FilialIncluir } from './Pages/cadastro/filiais/filiais/filialIncluir';
import { FilialEditar } from './Pages/cadastro/filiais/filiais/filialEditar';
import { FilialVisualizar } from './Pages/cadastro/filiais/filiais/filialVisualizar';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledEngineProvider } from "@mui/material/styles";
import PreCadastro from './Pages/cadastro/clientes/ListapreCadastro';
import FormularioCliente from './Pages/cadastro/clientes/formulario/preCad';
import ListaClientes from './Pages/cadastro/clientes/ListaClientes';
import CadCliente from './Pages/cadastro/clientes/formulario/cadastro';
import LoginCadastroExterno from './Pages/cadastrExterno/loginCad';
import CadClienteExterno from './Pages/cadastrExterno/cad';
import OrgFinanceiraList from './Pages/cadastro/orgFinanceira/Coletas/orgFinanceiraList';
import FormOrgFinanceira from './Pages/cadastro/orgFinanceira/Coletas/formulariosList';
import Balanco from './Pages/cadastro/orgFinanceira/BalançoPatrimonial/balancoList';
import BalancoForm from './Pages/cadastro/orgFinanceira/BalançoPatrimonial/balancoForm';
import FluxoCaixaList from './Pages/cadastro/orgFinanceira/FluxoCaixa/fluxoCaixaList';
import ParceirosList from './Pages/cadastro/parceiros/parceirosList';
import ParceirosForm from './Pages/cadastro/parceiros/parceirosForm';
import Perfil from './Pages/cadastro/orgFinanceira/Coletas/formularios/perfi';
import Riscos from './Pages/cadastro/orgFinanceira/Coletas/formularios/riscos';
import Sucessao from './Pages/cadastro/orgFinanceira/Coletas/formularios/sucessao';
import IndependenciaFinanceira from './Pages/cadastro/orgFinanceira/Coletas/formularios/independenciaFinanceira';
import Previdencia from './Pages/cadastro/orgFinanceira/Coletas/formularios/previdencia';
import NovoFcForm from './Pages/cadastro/orgFinanceira/FluxoCaixa/novoFCForm';
import NovoFluxoCaixaList from './Pages/cadastro/orgFinanceira/FluxoCaixa/novoFluxoCaixaList';
import NovoOtmForm from './Pages/cadastro/orgFinanceira/FluxoCaixa/novoOtmForm';
import NovoFCPropostoForm from './Pages/cadastro/orgFinanceira/FluxoCaixa/novoFCpropostoForm';

function App() {
  return (

    <div className="App">
      <div className="wrapper">
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              theme="light"
            />
            <Routes>
              <Route path="/" element={<MainPage />}>

                {/* Administrativo -> Usuário */}
                <Route path="administrativo/usuario" element={<Usuario />} />
                <Route path="administrativo/usuario/usuario/:id" element={<UsuarioIncluir />} />
                <Route path="administrativo/usuario/usuario/visu/:id" element={<UsuarioVisualizar />} />
                <Route path="administrativo/usuario/usuario/editar/:id" element={<UsuarioEditar />} />

                {/* Administrativo -> Permissão */}
                <Route path="administrativo/permisao" element={<Permissao />} />
                <Route path="administrativo/permisao/permissao/:id" element={<PermissaoIncluir />} />
                <Route path="administrativo/permisao/permissao/visu/:id" element={<PermissaoVisualizar />} />
                <Route path="administrativo/permisao/permissao/editar/:id" element={<PermissaoEditar />} />

                {/* Administrativo -> Empresa */}
                <Route path="administrativo/empresa" element={<Empresa />} />
                <Route path="administrativo/empresa/empresa/:id" element={<EmpresaIncluir />} />
                <Route path="administrativo/empresa/empresa/visu/:id" element={<EmpresaVisualizar />} />
                <Route path="administrativo/empresa/empresa/editar/:id" element={<EmpresaEditar />} />

                {/* Administrativo -> Códigos do Sistema */}
                <Route path="administrativo/codistema" element={<CodigiodoSistema />} />
                <Route path="administrativo/codistema/codsis/:id" element={<CodigiodoSistemaIncluir />} />
                <Route path="administrativo/codistema/codsis/visu/:id" element={<CodigiodoSistemaVisualizar />} />
                <Route path="administrativo/codistema/codsis/editar/:id" element={<CodigiodoSistemaEditar />} />

                {/*Administrativo -> Cadastro -> Filial */}
                <Route path="cadastro/filiais/filial" element={<Filiais />} />
                <Route path="cadastro/filiais/filials/:id" element={<FilialIncluir />} />
                <Route path="cadastro/filiais/filial/:id" element={<FilialIncluir />} />
                <Route path="cadastro/filiais/filial/visu/:id" element={<FilialVisualizar />} />
                <Route path="cadastro/filiais/filial/editar/:id" element={<FilialEditar />} />

                {/*ROTAS PARA cadastro -> clientes */}
                <Route path="precadastro/clientes" element={<PreCadastro />} />
                <Route path="precadastro/clientes/formulario/:id_cliente/:acao" element={<FormularioCliente />} />

                <Route path="cadastro/clientes/listagem" element={<ListaClientes />} />
                <Route path="cadastro/clientes/form/:id_cliente/:acao" element={<CadCliente />} />

                {/*ROTAS PARA CADASTRO DEO PARCEIROS*/}
                <Route path="cadastro/parceiros" element={<ParceirosList />} />
                <Route path="cadastro/parceiros/form/:id_parceiro/:acao" element={<ParceirosForm />} />

                {/*ORG FINANCEIRA (coletas) -- inicio*/}
                <Route path="org/financeira/list" element={<OrgFinanceiraList />} />
                <Route path="org/formslist/:id_cliente" element={<FormOrgFinanceira />} />

                <Route path="org/analiseperfil/list/:id_cliente/:acao/:id_analisedeperfil" element={<Perfil />} />
                <Route path="org/analiserisco/list/:id_cliente/:acao/:id_analisederisco" element={<Riscos />} />
                <Route path="org/sucessao/list/:id_cliente/:acao/:id_sucessao" element={<Sucessao />} />
                <Route path="org/independencia/list/:id_cliente/:acao/:id_independencia" element={<IndependenciaFinanceira />} />
                <Route path="org/previdencia/list/:id_cliente/:acao/:id_previdencia" element={<Previdencia />} />
                {/*ORG FINANCEIRA (coletas) -- fim*/}

                {/*ORG FINANCEIRA (balanço) inicio*/}
                <Route path="org/financeira/balancopatrionial/list" element={<Balanco />} />
                <Route path="org/financeira/balancopatrionial/:id_cliente/:acao" element={<BalancoForm />} />
                {/*ORG FINANCEIRA (balanço) fim*/}

                {/*FLUXO CAIXA e OTIMIZAÇÃO e fc proposto*/}
                <Route path="org/financeira/fluxocaixa/list" element={<FluxoCaixaList />} />
                <Route path="org/financeira/fluxocaixa/:id_cliente" element={<NovoFluxoCaixaList />} />
                <Route path="orgform/financeira/fluxocaixa/:id_cliente/:id_fluxo/:acao" element={<NovoFcForm />} />
                <Route path="orgform/financeira/otimizacao/:id_cliente/:id_otimizacao/:acao/:id_fluxo" element={<NovoOtmForm />} />
                <Route path="orgform/financeira/fcproposto/:id_cliente/:id_otm/:acao/:id_fluxo" element={<NovoFCPropostoForm />} />

              </Route>

              <Route path="/login" element={<Login />} />

              {/*ROTA PRA O CLIENTE FAZER O FORM*/}
              <Route path="/cad/verifica/:token_cliente" element={<LoginCadastroExterno />} />
              <Route path="/complet/cad/:id_cliente" element={<CadClienteExterno />} />
            </Routes>
          </StyledEngineProvider>
        </BrowserRouter>
      </div>
    </div>

  );
}

export default App;
