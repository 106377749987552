import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import { Row, Col, Card } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InputSemBorda from "../../../../components/Formularios/Inputs/InputsSemBorda";
import { getSessionData, setSessionData } from "../../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import Tabela from "../../../../components/Formularios/Table";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import ApiGet from "../../../../api/endPoints/useGet";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import * as FileSaver from "file-saver"
import exeljs from "exceljs"
import { mdiFileExcel } from "@mdi/js";

function FluxoCaixaList() {
    const navigate = useNavigate()
    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/cadastro/clientes/form/novo/criar")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    // Função para realizar a busca
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {
            carregarClientes(getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = clientes.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.nome.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })


            set_clientes(resultados)
        }
    }

    const [clientes, set_clientes] = useState([])
    function carregarClientes(id_filial: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/cadastroativos/${id_filial}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {

                set_carregando(false)
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {

                    set_clientes(resposta.data.clientes)
                }
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }

    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_cliente" },
        { titulo: "CPF", acesso: "cpf" },
        { titulo: "Nome", acesso: "nome" },
        { titulo: "Cód Cliente", acesso: "cod_cliente" },
        { titulo: "Email", acesso: "email" },
        { titulo: "Celular", acesso: "telcelular" }
    ]

    //modal loading
    const [carregando, set_carregando] = useState(false)

    //clicar para visu
    function visualizar(dado: any) {

        sessionStorage.setItem("cod_cliente", `${dado.cod_cliente}`)
        navigate(`/org/financeira/fluxocaixa/${dado.id_cliente}`)
    }

    //clicar para editar
    function editar(dado: any) {

        navigate(`/org/financeira/fluxocaixa/${dado.id_cliente}`)
    }

    //verifica permissão --- inicio
    const [permissao_editar, set_permissao_editar] = useState(false)
    const [permissao_visualizar, set_permissao_visualizar] = useState(false)

    async function CarregarPermissões() {
        set_carregando(true)
        const headers = {
            Authorization: getSessionData("MultfilialWebToken") || "",
        };
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: getSessionData("id_perfil") || "",
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].visualizarOrgFinanceira === 1) {
                set_permissao_visualizar(false)
            }
        })
    }

    //esse useefct carrega a listagem tambem dos clienets
    useEffect(function () {
        Search(busca)
    }, [busca])

    useEffect(function () {

        CarregarPermissões()
    }, [])


    //#region EXPORTAÇÃO DE 3 EXCEL- FC PROPOSTO FC PESSOAL OTIMIZAÇÃO

    // Função para converter base64 para buffer
    function base64ToBuffer(base64: any) {
        const binaryString = atob(base64.split(',')[1]);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    const [ListaMeses, set_ListaMeses] = useState<any>([])
    function listarProximosMesesEAnos(numeroMes: any, anoAtual: any) {
        // Validar se o número do mês está dentro do intervalo válido (1 a 12)
        if (numeroMes < 1 || numeroMes > 12) {
            return null;
        }

        // Array de nomes dos meses
        let meses = [
            "Jan", "Fev", "Mar", "Abr",
            "Mai", "Jun", "Jul", "Ago",
            "Set", "Out", "Nov", "Dez"
        ];

        // Lista para armazenar os próximos 12 meses e anos
        let proximosMesesEAnos = [];

        // Loop para adicionar os próximos 12 meses e anos à lista
        for (let i = 0; i < 12; i++) {
            let mesIndex = (numeroMes + i - 1) % 12;
            let ano = anoAtual + Math.floor((numeroMes + i - 1) / 12);
            proximosMesesEAnos.push({ mes: meses[mesIndex], ano: ano });
        }

        set_ListaMeses(proximosMesesEAnos)
        return proximosMesesEAnos
    }

    //função que gera o cabecalho para o excel
    function gerarCabecalho(data: any, tabela: any = 0) {

        const cabecalho = []
        cabecalho.push(tabela == 0 ? "ORIGEM DA RENDA" : tabela == 1 ? "CONSOLIDADO" : "DESPESA")

        //gerar os meses e colcoar no cabecalho
        const meses = listarProximosMesesEAnos(parseInt(data[0].mesanobase.split("/")[0]), parseInt(data[0].mesanobase.split("/")[1]))!
        for (let i = 0; i < meses.length; i = i + 1) {

            cabecalho.push(meses[i].mes + "-" + meses[i].ano)
        }

        return cabecalho
    }

    const [listaRendaAtiva, set_listaRendaAtiva] = useState([])
    const [listaRendaPassiva, set_listaRendaPassiva] = useState([])
    const [listadespesas, set_listadespesas] = useState([])
    const [ListaAtivos, set_ListaAtivos] = useState([])
    const [Listapassivos, set_ListaPassivos] = useState([])
    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {

                if (tabela == "RENDAATIVA") {

                    set_listaRendaAtiva(resposta.data)
                }
                else if (tabela == "RENDAPASSIVA") {

                    set_listaRendaPassiva(resposta.data)
                }
                else if (tabela == "DESPESAS") {

                    set_listadespesas(resposta.data)
                } else if (tabela == "PERFIL") {

                    set_PerfilGeral(resposta.data)
                }
                else if (tabela == "PERFILRESP") {

                    set_Resposta(resposta.data)
                }
                else if (tabela == "RISCOS") {

                    set_ListaEvento(resposta.data)
                }
                else if (tabela == "SEGUROS") {

                    set_ListaSeguradora(resposta.data)
                }
                else if (tabela == "NACIONALIDADE") {

                    set_ListaNacionalidade(resposta.data)
                }
                else if (tabela == "ESTADOCIVIL") {

                    set_ListaEstadoCivil(resposta.data)
                }
                else if (tabela == "PARENTESCO") {

                    set_ListaParentesco(resposta.data)
                }
                else if (tabela == "ATIVOS") {
                    set_ListaAtivos(resposta.data)
                }
                else if (tabela == "PASSIVOS") {
                    set_ListaPassivos(resposta.data)
                }
                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }


    //abre o modal apra escolhe ro mes ano base do fc - pessoal para sair no excel
    const [mesAnoBaseEscolhido, setMesAnoBaseEscolhido] = useState("")
    const [ListaMesAnoBaseCliente, setListaMesAnoBaseCliente] = useState<any>([])
    const [idCliente, setIdCliente] = useState("")
    const [dadoCliente, setdadoCliente] = useState<any>()
    function abrirModal(dado: any) {

        setdadoCliente(dado)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cabecalho/fluxos/caixa/${dado.id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {


            setListaMesAnoBaseCliente(resposta.data.cabecalhos)
        }).catch(function (erro) {

            toast.error(erro.response.data || erro.statusText || erro.message)
        })

        setIdCliente(dado.id_cliente)
        setMesAnoBaseEscolhido("")
        const btn = document.querySelector("#btnEscolherFC") as HTMLElement
        btn.click()
    }

    const [ListaPerfilGeral, set_PerfilGeral] = useState([])
    const [ListaResposta, set_Resposta] = useState([])
    const [ListaEvento, set_ListaEvento] = useState([])
    const [ListaSeguradora, set_ListaSeguradora] = useState([])
    const [ListaNacionalidade, set_ListaNacionalidade] = useState([])
    const [ListaEstadoCivil, set_ListaEstadoCivil] = useState([])
    const [ListaParentesco, set_ListaParentesco] = useState([])


    useEffect(function () {

        CarregarPermissões()

        ListarCODSistema("PERFIL")
        ListarCODSistema("PERFILRESP")
        ListarCODSistema("RENDAATIVA")
        ListarCODSistema("RENDAPASSIVA")
        ListarCODSistema("DESPESAS")
        ListarCODSistema("RISCOS")
        ListarCODSistema("SEGUROS")
        ListarCODSistema("NACIONALIDADE")
        ListarCODSistema("ESTADOCIVIL")
        ListarCODSistema("PARENTESCO")
        ListarCODSistema("ATIVOS")
        ListarCODSistema("PASSIVOS")
    }, [])
    const token = sessionStorage.getItem("MultfilialWebToken")!
    function formatarValor(valor: string) {
        if (!valor) return "0,00";

        // Verifica se o valor já está formatado no padrão com vírgula e ponto
        const regexFormato = /^\d{1,3}(\.\d{3})*,\d{2}$/;
        if (regexFormato.test(valor)) {
            return valor;
        }

        return parseFloat(valor)
            .toFixed(2) // Garante duas casas decimais
            .replace('.', ',') // Substitui o ponto por vírgula
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Adiciona o ponto como separador de milhar
    }
    function NovoExportaFCCompleto() {
        try {
            set_carregando(true)
            const workbook = new exeljs.Workbook()
            //#region Fluxo Pessoal
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/mesanobase/${mesAnoBaseEscolhido}/${dadoCliente.id_cliente}`, {
                headers: {
                    Authorization: token
                }
            }).then(async function (resposta) {
                // Cria um novo workbook e uma planilha
                const worksheetFC = workbook.addWorksheet('FC - Pessoal', { properties: { tabColor: { argb: 'ff0000' } } })

                const img64 = "data:image/png;base64," + getSessionData("imgFilial")!.replace(/["]/g, '')

                const imgBuffer = base64ToBuffer(img64)

                // Adiciona a imagem ao workbook
                const imagemID = workbook.addImage({
                    buffer: imgBuffer,
                    extension: "png"
                })

                worksheetFC.addImage(imagemID, {
                    tl: { col: 0, row: 0 }, // Top-left corner position (coluna e linha são baseadas em 1, não 0)
                    ext: { width: 150, height: 120 } // Defina a largura e altura que desejar
                })

                worksheetFC.getCell("A1").value = '                               PLANEJAMENTO FINANCEIRO E PATRIMONIAL: GESTÃO FINANCEIRA'
                worksheetFC.getRow(1).height = 60
                worksheetFC.getCell("A1").font = {
                    size: 20,
                    bold: true
                }

                worksheetFC.getCell("B2").value = `                               Projeto ${dadoCliente.cod_cliente} - ${dadoCliente.nome} - ${dadoCliente.cpf}`
                worksheetFC.getRow(2).height = 40
                worksheetFC.getCell("B2").font = {
                    size: 15,
                    bold: true
                }

                worksheetFC.getCell("A5").value = 'PROJEÇÃO DE RECEITAS E DESPESAS'
                worksheetFC.getRow(5).height = 30
                worksheetFC.getCell("A5").font = {
                    size: 15,
                    bold: true
                }

                const arrayDiaMesAno = new Date().toISOString().split("T")[0].split("-")
                worksheetFC.getCell("N2").value = arrayDiaMesAno[2] + "/" + arrayDiaMesAno[1] + "/" + arrayDiaMesAno[0]
                worksheetFC.getCell("N2").alignment = {
                    vertical: "middle",
                    horizontal: "center"
                }
                //retira pinta de branco rows de 1 a 6 
                for (let i = 1; i <= 160; i = i + 1) {

                    worksheetFC.getCell(`A${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`B${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`C${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`E${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`D${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`F${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`G${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`H${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`I${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`J${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`K${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`L${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`M${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`N${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`M${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`O${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`P${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`Q${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`R${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`S${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`T${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`U${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`V${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }

                    worksheetFC.getCell(`W${i}`).fill = {

                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                    }
                }
                //congelo parte da tela para ficar de cabeçalho
                worksheetFC.views = [
                    { state: "frozen", ySplit: 7 }
                ]
                //linha 7 deve ser o cabecalho
                const meses = listarProximosMesesEAnos(parseInt(resposta.data.cabecalho.mesanobase.split("/")[0]), resposta.data.cabecalho.mesanobase.split("/")[1])
                for (let i = 0; i < 1; i = i + 1) {
                    worksheetFC.getCell("A7").value = "CONSOLIDADO"
                    worksheetFC.getCell("B7").value = meses![0].mes + "/" + meses![0].ano
                    worksheetFC.getCell("C7").value = meses![1].mes + "/" + meses![1].ano
                    worksheetFC.getCell("D7").value = meses![2].mes + "/" + meses![2].ano
                    worksheetFC.getCell("E7").value = meses![3].mes + "/" + meses![3].ano
                    worksheetFC.getCell("F7").value = meses![4].mes + "/" + meses![4].ano
                    worksheetFC.getCell("G7").value = meses![5].mes + "/" + meses![5].ano
                    worksheetFC.getCell("H7").value = meses![6].mes + "/" + meses![6].ano
                    worksheetFC.getCell("I7").value = meses![7].mes + "/" + meses![7].ano
                    worksheetFC.getCell("J7").value = meses![8].mes + "/" + meses![8].ano
                    worksheetFC.getCell("K7").value = meses![9].mes + "/" + meses![9].ano
                    worksheetFC.getCell("L7").value = meses![10].mes + "/" + meses![10].ano
                    worksheetFC.getCell("M7").value = meses![11].mes + "/" + meses![11].ano
                    worksheetFC.getCell("A7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' } // Cor de preenchimento (background) preto
                    };

                    worksheetFC.getCell("B7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("C7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("D7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("E7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("F7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("G7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("H7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("I7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("J7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("K7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("L7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("M7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("N7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };

                    worksheetFC.getCell("O7").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000000' }
                    };
                    worksheetFC.getCell("A7").font = {
                        color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                        bold: true // Deixa em negrito
                    };

                    worksheetFC.getCell("B7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("C7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("D7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("E7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("F7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("G7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("H7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("I7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("J7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("K7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("L7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("M7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("N7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("O7").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    }
                    worksheetFC.getCell("N7").value = "Total"
                    worksheetFC.getCell("O7").value = "%Receita"
                    worksheetFC.getCell("A7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("B7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("C7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("D7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("E7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("F7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("G7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("K7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("N7").alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheetFC.getCell("O7").alignment = { vertical: 'middle', horizontal: 'center' };
                }
                worksheetFC.getRow(7).height = 20
                const columA = worksheetFC.getColumn("A")
                columA.width = 25
                const columB = worksheetFC.getColumn("B")
                columB.width = 10
                const columnB = worksheetFC.getColumn("B");
                columnB.width = 10;
                const columnC = worksheetFC.getColumn("C");
                columnC.width = 10;
                const columnD = worksheetFC.getColumn("D");
                columnD.width = 10;
                const columnE = worksheetFC.getColumn("E");
                columnE.width = 10;
                const columnF = worksheetFC.getColumn("F");
                columnF.width = 10;
                const columnG = worksheetFC.getColumn("G");
                columnG.width = 10;
                const columnH = worksheetFC.getColumn("H");
                columnH.width = 10;
                const columnI = worksheetFC.getColumn("I");
                columnI.width = 10;
                const columnJ = worksheetFC.getColumn("J");
                columnJ.width = 10;
                const columnK = worksheetFC.getColumn("K");
                columnK.width = 10;
                const columnL = worksheetFC.getColumn("L");
                columnL.width = 10;
                const columnM = worksheetFC.getColumn("M");
                columnM.width = 10;
                const columnN = worksheetFC.getColumn("N");
                columnN.width = 10;
                const columnO = worksheetFC.getColumn("O");
                columnO.width = 10;
                const cellA8 = worksheetFC.getCell("A8")
                cellA8.value = "Receitas"
                cellA8.alignment = { horizontal: "center", vertical: "middle" }
                const cellA9 = worksheetFC.getCell("A9")
                cellA9.value = "Despesas"
                cellA9.alignment = { horizontal: "center", vertical: "middle" }
                const cellA10 = worksheetFC.getCell("A10")
                cellA10.value = "Variação"
                cellA10.alignment = { horizontal: "center", vertical: "middle" }
                const cellA11 = worksheetFC.getCell("A11")
                cellA11.alignment = { horizontal: "center", vertical: "middle" }
                cellA11.value = "Saldo Projetado"
                //calculando receitas (consolidado)
                const totalReceitasMes = {
                    mes1: 0,
                    mes2: 0,
                    mes3: 0,
                    mes4: 0,
                    mes5: 0,
                    mes6: 0,
                    mes7: 0,
                    mes8: 0,
                    mes9: 0,
                    mes10: 0,
                    mes11: 0,
                    mes12: 0
                }
                for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                    totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.ativos[i].mes1;
                    totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.ativos[i].mes2;
                    totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.ativos[i].mes3;
                    totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.ativos[i].mes4;
                    totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.ativos[i].mes5;
                    totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.ativos[i].mes6;
                    totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.ativos[i].mes7;
                    totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.ativos[i].mes8;
                    totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.ativos[i].mes9;
                    totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.ativos[i].mes10;
                    totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.ativos[i].mes11;
                    totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.ativos[i].mes12;
                }
                for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                    totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.passivos[i].mes1;
                    totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.passivos[i].mes2;
                    totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.passivos[i].mes3;
                    totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.passivos[i].mes4;
                    totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.passivos[i].mes5;
                    totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.passivos[i].mes6;
                    totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.passivos[i].mes7;
                    totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.passivos[i].mes8;
                    totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.passivos[i].mes9;
                    totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.passivos[i].mes10;
                    totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.passivos[i].mes11;
                    totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.passivos[i].mes12;
                }
                const celB8 = worksheetFC.getCell("B8");
                celB8.value = formatarValor(totalReceitasMes.mes1.toString())
                const celC8 = worksheetFC.getCell("C8");
                celC8.value = formatarValor(totalReceitasMes.mes2.toString())
                const celD8 = worksheetFC.getCell("D8");
                celD8.value = formatarValor(totalReceitasMes.mes3.toString())
                const celE8 = worksheetFC.getCell("E8");
                celE8.value = formatarValor(totalReceitasMes.mes4.toString())
                const celF8 = worksheetFC.getCell("F8");
                celF8.value = formatarValor(totalReceitasMes.mes5.toString())
                const celG8 = worksheetFC.getCell("G8");
                celG8.value = formatarValor(totalReceitasMes.mes6.toString())
                const celH8 = worksheetFC.getCell("H8");
                celH8.value = formatarValor(totalReceitasMes.mes7.toString())
                const celI8 = worksheetFC.getCell("I8");
                celI8.value = formatarValor(totalReceitasMes.mes8.toString())
                const celJ8 = worksheetFC.getCell("J8");
                celJ8.value = formatarValor(totalReceitasMes.mes9.toString())
                const celK8 = worksheetFC.getCell("K8");
                celK8.value = formatarValor(totalReceitasMes.mes10.toString());
                const celL8 = worksheetFC.getCell("L8");
                celL8.value = formatarValor(totalReceitasMes.mes11.toString());
                const celM8 = worksheetFC.getCell("M8");
                celM8.value = formatarValor(totalReceitasMes.mes12.toString());
                const celN8 = worksheetFC.getCell("N8");
                celN8.value = formatarValor((totalReceitasMes.mes1 + totalReceitasMes.mes2 + totalReceitasMes.mes3 +
                    totalReceitasMes.mes4 + totalReceitasMes.mes5 + totalReceitasMes.mes6 +
                    totalReceitasMes.mes7 + totalReceitasMes.mes8 + totalReceitasMes.mes9 +
                    totalReceitasMes.mes10 + totalReceitasMes.mes11 + totalReceitasMes.mes12).toString())
                worksheetFC.getCell("O8").value = "100"
                //gerando despesas totais(mes) consolidado
                const totalDespesasMes = {
                    mes1: 0,
                    mes2: 0,
                    mes3: 0,
                    mes4: 0,
                    mes5: 0,
                    mes6: 0,
                    mes7: 0,
                    mes8: 0,
                    mes9: 0,
                    mes10: 0,
                    mes11: 0,
                    mes12: 0
                }
                for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                    totalDespesasMes.mes1 = totalDespesasMes.mes1 + resposta.data.despesas[i].mes1;
                    totalDespesasMes.mes2 = totalDespesasMes.mes2 + resposta.data.despesas[i].mes2;
                    totalDespesasMes.mes3 = totalDespesasMes.mes3 + resposta.data.despesas[i].mes3;
                    totalDespesasMes.mes4 = totalDespesasMes.mes4 + resposta.data.despesas[i].mes4;
                    totalDespesasMes.mes5 = totalDespesasMes.mes5 + resposta.data.despesas[i].mes5;
                    totalDespesasMes.mes6 = totalDespesasMes.mes6 + resposta.data.despesas[i].mes6;
                    totalDespesasMes.mes7 = totalDespesasMes.mes7 + resposta.data.despesas[i].mes7;
                    totalDespesasMes.mes8 = totalDespesasMes.mes8 + resposta.data.despesas[i].mes8;
                    totalDespesasMes.mes9 = totalDespesasMes.mes9 + resposta.data.despesas[i].mes9;
                    totalDespesasMes.mes10 = totalDespesasMes.mes10 + resposta.data.despesas[i].mes10;
                    totalDespesasMes.mes11 = totalDespesasMes.mes11 + resposta.data.despesas[i].mes11;
                    totalDespesasMes.mes12 = totalDespesasMes.mes12 + resposta.data.despesas[i].mes12;
                }
                const celB9 = worksheetFC.getCell("B9");
                celB9.value = formatarValor(totalDespesasMes.mes1.toString());
                const celC9 = worksheetFC.getCell("C9");
                celC9.value = formatarValor(totalDespesasMes.mes2.toString());
                const celD9 = worksheetFC.getCell("D9");
                celD9.value = formatarValor(totalDespesasMes.mes3.toString());
                const celE9 = worksheetFC.getCell("E9");
                celE9.value = formatarValor(totalDespesasMes.mes4.toString());
                const celF9 = worksheetFC.getCell("F9");
                celF9.value = formatarValor(totalDespesasMes.mes5.toString());
                const celG9 = worksheetFC.getCell("G9");
                celG9.value = formatarValor(totalDespesasMes.mes6.toString());
                const celH9 = worksheetFC.getCell("H9");
                celH9.value = formatarValor(totalDespesasMes.mes7.toString());
                const celI9 = worksheetFC.getCell("I9");
                celI9.value = formatarValor(totalDespesasMes.mes8.toString());
                const celJ9 = worksheetFC.getCell("J9");
                celJ9.value = formatarValor(totalDespesasMes.mes9.toString());
                const celK9 = worksheetFC.getCell("K9");
                celK9.value = formatarValor(totalDespesasMes.mes10.toString());
                const celL9 = worksheetFC.getCell("L9");
                celL9.value = formatarValor(totalDespesasMes.mes11.toString());
                const celM9 = worksheetFC.getCell("M9");
                celM9.value = formatarValor(totalDespesasMes.mes12.toString());
                const celN9 = worksheetFC.getCell("N9");
                celN9.value = formatarValor((totalDespesasMes.mes1 + totalDespesasMes.mes2 + totalDespesasMes.mes3 +
                    totalDespesasMes.mes4 + totalDespesasMes.mes5 + totalDespesasMes.mes6 +
                    totalDespesasMes.mes7 + totalDespesasMes.mes8 + totalDespesasMes.mes9 +
                    totalDespesasMes.mes10 + totalDespesasMes.mes11 + totalDespesasMes.mes12).toString())
                worksheetFC.getCell("O9").value = ((parseFloat(celN9.value) / parseFloat(celN8.value)) * 100).toPrecision(2).toString()
                const totalVariacaoMes = {
                    mes1: 0,
                    mes2: 0,
                    mes3: 0,
                    mes4: 0,
                    mes5: 0,
                    mes6: 0,
                    mes7: 0,
                    mes8: 0,
                    mes9: 0,
                    mes10: 0,
                    mes11: 0,
                    mes12: 0
                }
                for (let i = 0; i < 12; i = i + 1) {
                    totalVariacaoMes.mes1 = totalReceitasMes.mes1 - totalDespesasMes.mes1;
                    totalVariacaoMes.mes2 = totalReceitasMes.mes2 - totalDespesasMes.mes2;
                    totalVariacaoMes.mes3 = totalReceitasMes.mes3 - totalDespesasMes.mes3;
                    totalVariacaoMes.mes4 = totalReceitasMes.mes4 - totalDespesasMes.mes4;
                    totalVariacaoMes.mes5 = totalReceitasMes.mes5 - totalDespesasMes.mes5;
                    totalVariacaoMes.mes6 = totalReceitasMes.mes6 - totalDespesasMes.mes6;
                    totalVariacaoMes.mes7 = totalReceitasMes.mes7 - totalDespesasMes.mes7;
                    totalVariacaoMes.mes8 = totalReceitasMes.mes8 - totalDespesasMes.mes8;
                    totalVariacaoMes.mes9 = totalReceitasMes.mes9 - totalDespesasMes.mes9;
                    totalVariacaoMes.mes10 = totalReceitasMes.mes10 - totalDespesasMes.mes10;
                    totalVariacaoMes.mes11 = totalReceitasMes.mes11 - totalDespesasMes.mes11;
                    totalVariacaoMes.mes12 = totalReceitasMes.mes12 - totalDespesasMes.mes12;
                }
                const celB10 = worksheetFC.getCell("B10");
                celB10.value = formatarValor(totalVariacaoMes.mes1.toString());
                const celC10 = worksheetFC.getCell("C10");
                celC10.value = formatarValor(totalVariacaoMes.mes2.toString());
                const celD10 = worksheetFC.getCell("D10");
                celD10.value = formatarValor(totalVariacaoMes.mes3.toString());
                const celE10 = worksheetFC.getCell("E10");
                celE10.value = formatarValor(totalVariacaoMes.mes4.toString());
                const celF10 = worksheetFC.getCell("F10");
                celF10.value = formatarValor(totalVariacaoMes.mes5.toString());
                const celG10 = worksheetFC.getCell("G10");
                celG10.value = formatarValor(totalVariacaoMes.mes6.toString());
                const celH10 = worksheetFC.getCell("H10");
                celH10.value = formatarValor(totalVariacaoMes.mes7.toString());
                const celI10 = worksheetFC.getCell("I10");
                celI10.value = formatarValor(totalVariacaoMes.mes8.toString());
                const celJ10 = worksheetFC.getCell("J10");
                celJ10.value = formatarValor(totalVariacaoMes.mes9.toString());
                const celK10 = worksheetFC.getCell("K10");
                celK10.value = formatarValor(totalVariacaoMes.mes10.toString());
                const celL10 = worksheetFC.getCell("L10");
                celL10.value = formatarValor(totalVariacaoMes.mes11.toString());
                const celM10 = worksheetFC.getCell("M10");
                celM10.value = formatarValor(totalVariacaoMes.mes12.toString());
                const totalProjetadoMes = {
                    mes1: 0,
                    mes2: 0,
                    mes3: 0,
                    mes4: 0,
                    mes5: 0,
                    mes6: 0,
                    mes7: 0,
                    mes8: 0,
                    mes9: 0,
                    mes10: 0,
                    mes11: 0,
                    mes12: 0
                }
                for (let i = 0; i < 12; i = i + 1) {
                    totalProjetadoMes.mes1 = totalVariacaoMes.mes1;
                    totalProjetadoMes.mes2 = totalProjetadoMes.mes1 + totalVariacaoMes.mes2
                    totalProjetadoMes.mes3 = totalProjetadoMes.mes2 + totalVariacaoMes.mes3;
                    totalProjetadoMes.mes4 = totalProjetadoMes.mes3 + totalVariacaoMes.mes4;
                    totalProjetadoMes.mes5 = totalProjetadoMes.mes4 + totalVariacaoMes.mes5;
                    totalProjetadoMes.mes6 = totalProjetadoMes.mes5 + totalVariacaoMes.mes6;
                    totalProjetadoMes.mes7 = totalProjetadoMes.mes6 + totalVariacaoMes.mes7;
                    totalProjetadoMes.mes8 = totalProjetadoMes.mes7 + totalVariacaoMes.mes8;
                    totalProjetadoMes.mes9 = totalProjetadoMes.mes8 + totalVariacaoMes.mes9;
                    totalProjetadoMes.mes10 = totalProjetadoMes.mes9 + totalVariacaoMes.mes10;
                    totalProjetadoMes.mes11 = totalProjetadoMes.mes10 + totalVariacaoMes.mes11;
                    totalProjetadoMes.mes12 = totalProjetadoMes.mes11 + totalVariacaoMes.mes12
                }
                const celB11 = worksheetFC.getCell("B11");
                celB11.value = formatarValor(totalProjetadoMes.mes1.toString());
                const celC11 = worksheetFC.getCell("C11");
                celC11.value = formatarValor(totalProjetadoMes.mes2.toString());
                const celD11 = worksheetFC.getCell("D11");
                celD11.value = formatarValor(totalProjetadoMes.mes3.toString());
                const celE11 = worksheetFC.getCell("E11");
                celE11.value = formatarValor(totalProjetadoMes.mes4.toString());
                const celF11 = worksheetFC.getCell("F11");
                celF11.value = formatarValor(totalProjetadoMes.mes5.toString());
                const celG11 = worksheetFC.getCell("G11");
                celG11.value = formatarValor(totalProjetadoMes.mes6.toString());
                const celH11 = worksheetFC.getCell("H11");
                celH11.value = formatarValor(totalProjetadoMes.mes7.toString());
                const celI11 = worksheetFC.getCell("I11");
                celI11.value = formatarValor(totalProjetadoMes.mes8.toString())
                const celJ11 = worksheetFC.getCell("J11");
                celJ11.value = formatarValor(totalProjetadoMes.mes9.toString())
                const celK11 = worksheetFC.getCell("K11");
                celK11.value = formatarValor(totalProjetadoMes.mes10.toString())
                const celL11 = worksheetFC.getCell("L11");
                celL11.value = formatarValor(totalProjetadoMes.mes11.toString())
                const celM11 = worksheetFC.getCell("M11");
                celM11.value = formatarValor(totalProjetadoMes.mes12.toString())
                const cellA12 = worksheetFC.getCell("A12")
                cellA12.value = `RELAÇÃO DE RECEITAS`
                cellA12.font = {
                    color: {
                        argb: "000080"
                    },
                    size: 15,
                    bold: true
                }
                for (let i = 0; i < 1; i = i + 1) {
                    worksheetFC.getCell("A13").value = "ORIGEM DA RENDA"
                    worksheetFC.getCell("A13").alignment = { vertical: "middle", horizontal: "center" }
                    worksheetFC.getCell("B13").value = meses![0].mes + "/" + meses![0].ano
                    worksheetFC.getCell("C13").value = meses![1].mes + "/" + meses![1].ano
                    worksheetFC.getCell("D13").value = meses![2].mes + "/" + meses![2].ano
                    worksheetFC.getCell("E13").value = meses![3].mes + "/" + meses![3].ano
                    worksheetFC.getCell("F13").value = meses![4].mes + "/" + meses![4].ano
                    worksheetFC.getCell("G13").value = meses![5].mes + "/" + meses![5].ano
                    worksheetFC.getCell("H13").value = meses![6].mes + "/" + meses![6].ano
                    worksheetFC.getCell("I13").value = meses![7].mes + "/" + meses![7].ano
                    worksheetFC.getCell("J13").value = meses![8].mes + "/" + meses![8].ano
                    worksheetFC.getCell("K13").value = meses![9].mes + "/" + meses![9].ano
                    worksheetFC.getCell("L13").value = meses![10].mes + "/" + meses![10].ano
                    worksheetFC.getCell("M13").value = meses![11].mes + "/" + meses![11].ano
                    worksheetFC.getCell("A13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' } // Cor de preenchimento (background) preto
                    };

                    worksheetFC.getCell("B13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("C13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("D13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("E13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("F13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("G13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("H13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("I13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("J13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("K13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("L13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("M13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("N13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };

                    worksheetFC.getCell("O13").fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '000080' }
                    };
                    worksheetFC.getCell("A13").font = {
                        color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                        bold: true // Deixa em negrito
                    };

                    worksheetFC.getCell("B13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("C13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("D13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("E13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("F13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("G13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("H13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("I13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("J13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("K13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("L13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("M13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("N13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell("O13").font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    }
                    worksheetFC.getCell("N13").value = "Total"
                    worksheetFC.getCell("O13").value = "%Receita"
                }
                const cellA14 = worksheetFC.getCell("A14")
                cellA14.value = "RENDA ATIVA"
                cellA14.font = {
                    bold: true
                }
                cellA14.alignment = {
                    horizontal: "center", vertical: "middle"
                }
                const totalRendaAtivaMes = {
                    mes1: 0,
                    mes2: 0,
                    mes3: 0,
                    mes4: 0,
                    mes5: 0,
                    mes6: 0,
                    mes7: 0,
                    mes8: 0,
                    mes9: 0,
                    mes10: 0,
                    mes11: 0,
                    mes12: 0
                }
                //soma valores MES
                for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                    totalRendaAtivaMes.mes1 = totalRendaAtivaMes.mes1 + resposta.data.ativos[i].mes1;
                    totalRendaAtivaMes.mes2 = totalRendaAtivaMes.mes2 + resposta.data.ativos[i].mes2;
                    totalRendaAtivaMes.mes3 = totalRendaAtivaMes.mes3 + resposta.data.ativos[i].mes3;
                    totalRendaAtivaMes.mes4 = totalRendaAtivaMes.mes4 + resposta.data.ativos[i].mes4;
                    totalRendaAtivaMes.mes5 = totalRendaAtivaMes.mes5 + resposta.data.ativos[i].mes5;
                    totalRendaAtivaMes.mes6 = totalRendaAtivaMes.mes6 + resposta.data.ativos[i].mes6;
                    totalRendaAtivaMes.mes7 = totalRendaAtivaMes.mes7 + resposta.data.ativos[i].mes7;
                    totalRendaAtivaMes.mes8 = totalRendaAtivaMes.mes8 + resposta.data.ativos[i].mes8;
                    totalRendaAtivaMes.mes9 = totalRendaAtivaMes.mes9 + resposta.data.ativos[i].mes9;
                    totalRendaAtivaMes.mes10 = totalRendaAtivaMes.mes10 + resposta.data.ativos[i].mes10;
                    totalRendaAtivaMes.mes11 = totalRendaAtivaMes.mes11 + resposta.data.ativos[i].mes11;
                    totalRendaAtivaMes.mes12 = totalRendaAtivaMes.mes12 + resposta.data.ativos[i].mes12;
                }
                //soma valores LINHA
                for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                    //soma os valores do mes para dar o total da linha
                    const ativo = resposta.data.ativos[i]
                    const total = ativo.mes1 + ativo.mes2 + ativo.mes3 + ativo.mes4 + ativo.mes5 + ativo.mes6 + ativo.mes7 + ativo.mes8 + ativo.mes9 + ativo.mes10 + ativo.mes11 + ativo.mes12
                    worksheetFC.getCell(`N${i + 15}`).value = formatarValor((total).toString())
                    const totalReceita: any = worksheetFC.getCell("N8")!.value
                    worksheetFC.getCell(`O${i + 15}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
                }
                for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                    const ativo = resposta.data.ativos[i]
                    const cellA = worksheetFC.getCell(`A${15 + i}`)
                    cellA.value = ativo.label
                    cellA.alignment = { horizontal: "center", vertical: "middle" }
                    const cellB = worksheetFC.getCell(`B${15 + i}`);
                    cellB.value = formatarValor(ativo.mes1.toString());
                    const cellC = worksheetFC.getCell(`C${15 + i}`);
                    cellC.value = formatarValor(ativo.mes2.toString());
                    const cellD = worksheetFC.getCell(`D${15 + i}`);
                    cellD.value = formatarValor(ativo.mes3.toString());
                    const cellE = worksheetFC.getCell(`E${15 + i}`);
                    cellE.value = formatarValor(ativo.mes4.toString());
                    const cellF = worksheetFC.getCell(`F${15 + i}`);
                    cellF.value = formatarValor(ativo.mes5.toString())
                    const cellG = worksheetFC.getCell(`G${15 + i}`);
                    cellG.value = formatarValor(ativo.mes6.toString());
                    const cellH = worksheetFC.getCell(`H${15 + i}`);
                    cellH.value = formatarValor(ativo.mes7.toString());
                    const cellI = worksheetFC.getCell(`I${15 + i}`);
                    cellI.value = formatarValor(ativo.mes8.toString());
                    const cellJ = worksheetFC.getCell(`J${15 + i}`);
                    cellJ.value = formatarValor(ativo.mes9.toString());
                    const cellK = worksheetFC.getCell(`K${15 + i}`);
                    cellK.value = formatarValor(ativo.mes10.toString());
                    const cellL = worksheetFC.getCell(`L${15 + i}`);
                    cellL.value = formatarValor(ativo.mes11.toString());
                    const cellM = worksheetFC.getCell(`M${15 + i}`);
                    cellM.value = formatarValor(ativo.mes12.toString());
                }
                //preenchendo totais
                const cellA_anyAtivo = worksheetFC.getCell(`A${resposta.data.ativos.length + 15}`)
                cellA_anyAtivo.value = "Total Renda Ativa"
                cellA_anyAtivo.font = {
                    bold: true
                }
                cellA_anyAtivo.alignment = {
                    vertical: "middle", horizontal: "center"
                }
                const cellB_anyTotalatv1 = worksheetFC.getCell(`B${resposta.data.ativos.length + 15}`);
                cellB_anyTotalatv1.value = formatarValor(totalRendaAtivaMes.mes1.toString());
                const cellC_anyTotalatv2 = worksheetFC.getCell(`C${resposta.data.ativos.length + 15}`);
                cellC_anyTotalatv2.value = formatarValor(totalRendaAtivaMes.mes2.toString());
                const cellD_anyTotalatv3 = worksheetFC.getCell(`D${resposta.data.ativos.length + 15}`);
                cellD_anyTotalatv3.value = formatarValor(totalRendaAtivaMes.mes3.toString());
                const cellE_anyTotalatv4 = worksheetFC.getCell(`E${resposta.data.ativos.length + 15}`);
                cellE_anyTotalatv4.value = formatarValor(totalRendaAtivaMes.mes4.toString());
                const cellF_anyTotalatv5 = worksheetFC.getCell(`F${resposta.data.ativos.length + 15}`);
                cellF_anyTotalatv5.value = formatarValor(totalRendaAtivaMes.mes5.toString());
                const cellG_anyTotalatv6 = worksheetFC.getCell(`G${resposta.data.ativos.length + 15}`);
                cellG_anyTotalatv6.value = formatarValor(totalRendaAtivaMes.mes6.toString());
                const cellH_anyTotalatv7 = worksheetFC.getCell(`H${resposta.data.ativos.length + 15}`);
                cellH_anyTotalatv7.value = formatarValor(totalRendaAtivaMes.mes7.toString());
                const cellI_anyTotalatv8 = worksheetFC.getCell(`I${resposta.data.ativos.length + 15}`);
                cellI_anyTotalatv8.value = formatarValor(totalRendaAtivaMes.mes8.toString());
                const cellJ_anyTotalatv9 = worksheetFC.getCell(`J${resposta.data.ativos.length + 15}`);
                cellJ_anyTotalatv9.value = formatarValor(totalRendaAtivaMes.mes9.toString());
                const cellK_anyTotalatv10 = worksheetFC.getCell(`K${resposta.data.ativos.length + 15}`);
                cellK_anyTotalatv10.value = formatarValor(totalRendaAtivaMes.mes10.toString());
                const cellL_anyTotalatv11 = worksheetFC.getCell(`L${resposta.data.ativos.length + 15}`);
                cellL_anyTotalatv11.value = formatarValor(totalRendaAtivaMes.mes11.toString());
                const cellM_anyTotalatv12 = worksheetFC.getCell(`M${resposta.data.ativos.length + 15}`);
                cellM_anyTotalatv12.value = formatarValor(totalRendaAtivaMes.mes12.toString());
                worksheetFC.getCell(`N${resposta.data.ativos.length + 15}`).value = formatarValor((totalRendaAtivaMes.mes1 + totalRendaAtivaMes.mes2 + totalRendaAtivaMes.mes3 +
                    totalRendaAtivaMes.mes4 + totalRendaAtivaMes.mes5 + totalRendaAtivaMes.mes6 +
                    totalRendaAtivaMes.mes7 + totalRendaAtivaMes.mes8 + totalRendaAtivaMes.mes9 +
                    totalRendaAtivaMes.mes10 + totalRendaAtivaMes.mes11 + totalRendaAtivaMes.mes12).toString())
                //gerar renda passiva
                const totalRendaPassivaMes = {
                    mes1: 0,
                    mes2: 0,
                    mes3: 0,
                    mes4: 0,
                    mes5: 0,
                    mes6: 0,
                    mes7: 0,
                    mes8: 0,
                    mes9: 0,
                    mes10: 0,
                    mes11: 0,
                    mes12: 0
                }
                for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                    totalRendaPassivaMes.mes1 = totalRendaPassivaMes.mes1 + resposta.data.passivos[i].mes1;
                    totalRendaPassivaMes.mes2 = totalRendaPassivaMes.mes2 + resposta.data.passivos[i].mes2;
                    totalRendaPassivaMes.mes3 = totalRendaPassivaMes.mes3 + resposta.data.passivos[i].mes3;
                    totalRendaPassivaMes.mes4 = totalRendaPassivaMes.mes4 + resposta.data.passivos[i].mes4;
                    totalRendaPassivaMes.mes5 = totalRendaPassivaMes.mes5 + resposta.data.passivos[i].mes5;
                    totalRendaPassivaMes.mes6 = totalRendaPassivaMes.mes6 + resposta.data.passivos[i].mes6;
                    totalRendaPassivaMes.mes7 = totalRendaPassivaMes.mes7 + resposta.data.passivos[i].mes7;
                    totalRendaPassivaMes.mes8 = totalRendaPassivaMes.mes8 + resposta.data.passivos[i].mes8;
                    totalRendaPassivaMes.mes9 = totalRendaPassivaMes.mes9 + resposta.data.passivos[i].mes9;
                    totalRendaPassivaMes.mes10 = totalRendaPassivaMes.mes10 + resposta.data.passivos[i].mes10;
                    totalRendaPassivaMes.mes11 = totalRendaPassivaMes.mes11 + resposta.data.passivos[i].mes11;
                    totalRendaPassivaMes.mes12 = totalRendaPassivaMes.mes12 + resposta.data.passivos[i].mes12;
                }
                //soma valores LINHA
                for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                    //soma os valores do mes para dar o total da linha
                    const passivo = resposta.data.passivos[i]
                    const total = passivo.mes1 + passivo.mes2 + passivo.mes3 + passivo.mes4 + passivo.mes5 + passivo.mes6 + passivo.mes7 + passivo.mes8 + passivo.mes9 + passivo.mes10 + passivo.mes11 + passivo.mes12
                    worksheetFC.getCell(`N${i + 17 + resposta.data.ativos.length}`).value = formatarValor((total).toString())
                    const totalReceita: any = worksheetFC.getCell("N8")!.value
                    worksheetFC.getCell(`O${i + 17 + resposta.data.ativos.length}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
                }
                const cellA_any = worksheetFC.getCell(`A${resposta.data.ativos.length + 16}`)
                cellA_any.value = "RENDA PASSIVA"
                cellA_any.font = {
                    bold: true
                }
                cellA_any.alignment = { horizontal: "center", vertical: "middle" }
                for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                    const passivo = resposta.data.passivos[i]
                    const ultimoAtivo = resposta.data.ativos.length + 17
                    const cellA = worksheetFC.getCell(`A${ultimoAtivo + i}`)
                    cellA.value = passivo.label
                    cellA.alignment = { horizontal: "center", vertical: "middle" }
                    const cellB = worksheetFC.getCell(`B${ultimoAtivo + i}`);
                    cellB.value = formatarValor(passivo.mes1.toString());
                    const cellC = worksheetFC.getCell(`C${ultimoAtivo + i}`);
                    cellC.value = formatarValor(passivo.mes2.toString());
                    const cellD = worksheetFC.getCell(`D${ultimoAtivo + i}`);
                    cellD.value = formatarValor(passivo.mes3.toString());
                    const cellE = worksheetFC.getCell(`E${ultimoAtivo + i}`);
                    cellE.value = formatarValor(passivo.mes4.toString());
                    const cellF = worksheetFC.getCell(`F${ultimoAtivo + i}`);
                    cellF.value = formatarValor(passivo.mes5.toString())
                    const cellG = worksheetFC.getCell(`G${ultimoAtivo + i}`);
                    cellG.value = formatarValor(passivo.mes6.toString());
                    const cellH = worksheetFC.getCell(`H${ultimoAtivo + i}`);
                    cellH.value = formatarValor(passivo.mes7.toString());
                    const cellI = worksheetFC.getCell(`I${ultimoAtivo + i}`);
                    cellI.value = formatarValor(passivo.mes8.toString());
                    const cellJ = worksheetFC.getCell(`J${ultimoAtivo + i}`);
                    cellJ.value = formatarValor(passivo.mes9.toString());
                    const cellK = worksheetFC.getCell(`K${ultimoAtivo + i}`);
                    cellK.value = formatarValor(passivo.mes10.toString());
                    const cellL = worksheetFC.getCell(`L${ultimoAtivo + i}`);
                    cellL.value = formatarValor(passivo.mes11.toString());
                    const cellM = worksheetFC.getCell(`M${ultimoAtivo + i}`);
                    cellM.value = formatarValor(passivo.mes12.toString());
                }
                //preenchendo totais
                const cellA_anyPassivo = worksheetFC.getCell(`A${resposta.data.ativos.length + resposta.data.passivos.length + 17}`)
                cellA_anyPassivo.value = "Total Renda Passiva"
                cellA_anyPassivo.font = {
                    bold: true
                }
                cellA_anyPassivo.alignment = {
                    vertical: "middle", horizontal: "center"
                }
                const cellB_anyTotalpassivo1 = worksheetFC.getCell(`B${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellB_anyTotalpassivo1.value = formatarValor(totalRendaPassivaMes.mes1.toString());
                const cellC_anyTotalpassivo2 = worksheetFC.getCell(`C${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellC_anyTotalpassivo2.value = formatarValor(totalRendaPassivaMes.mes2.toString());
                const cellD_anyTotalpassivo3 = worksheetFC.getCell(`D${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellD_anyTotalpassivo3.value = formatarValor(totalRendaPassivaMes.mes3.toString());
                const cellE_anyTotalpassivo4 = worksheetFC.getCell(`E${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellE_anyTotalpassivo4.value = formatarValor(totalRendaPassivaMes.mes4.toString());
                const cellF_anyTotalpassivo5 = worksheetFC.getCell(`F${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellF_anyTotalpassivo5.value = formatarValor(totalRendaPassivaMes.mes5.toString());
                const cellG_anyTotalpassivo6 = worksheetFC.getCell(`G${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellG_anyTotalpassivo6.value = formatarValor(totalRendaPassivaMes.mes6.toString());
                const cellH_anyTotalpassivo7 = worksheetFC.getCell(`H${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellH_anyTotalpassivo7.value = formatarValor(totalRendaPassivaMes.mes7.toString());
                const cellI_anyTotalpassivo8 = worksheetFC.getCell(`I${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellI_anyTotalpassivo8.value = formatarValor(totalRendaPassivaMes.mes8.toString());
                const cellJ_anyTotalpassivo9 = worksheetFC.getCell(`J${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellJ_anyTotalpassivo9.value = formatarValor(totalRendaPassivaMes.mes9.toString());
                const cellK_anyTotalpassivo10 = worksheetFC.getCell(`K${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellK_anyTotalpassivo10.value = formatarValor(totalRendaPassivaMes.mes10.toString());
                const cellL_anyTotalpassivo11 = worksheetFC.getCell(`L${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellL_anyTotalpassivo11.value = formatarValor(totalRendaPassivaMes.mes11.toString());
                const cellM_anyTotalpassivo12 = worksheetFC.getCell(`M${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                cellM_anyTotalpassivo12.value = formatarValor(totalRendaPassivaMes.mes12.toString());
                worksheetFC.getCell(`N${resposta.data.ativos.length + resposta.data.passivos.length + 17}`).value = formatarValor((totalRendaPassivaMes.mes1 + totalRendaPassivaMes.mes2 + totalRendaPassivaMes.mes3 +
                    totalRendaPassivaMes.mes4 + totalRendaPassivaMes.mes5 + totalRendaPassivaMes.mes6 +
                    totalRendaPassivaMes.mes7 + totalRendaPassivaMes.mes8 + totalRendaPassivaMes.mes9 +
                    totalRendaPassivaMes.mes10 + totalRendaPassivaMes.mes11 + totalRendaPassivaMes.mes12).toString())
                //#region despesa
                const linha = 18 + resposta.data.ativos.length + resposta.data.passivos.length
                for (let i = 0; i < 1; i = i + 1) {
                    worksheetFC.getCell(`A${linha}`).value = "DESPESAS"
                    worksheetFC.getCell(`A${linha}`).alignment = { vertical: "middle", horizontal: "center" }
                    worksheetFC.getCell(`B${linha}`).value = meses![0].mes + "/" + meses![0].ano
                    worksheetFC.getCell(`C${linha}`).value = meses![1].mes + "/" + meses![1].ano
                    worksheetFC.getCell(`D${linha}`).value = meses![2].mes + "/" + meses![2].ano
                    worksheetFC.getCell(`E${linha}`).value = meses![3].mes + "/" + meses![3].ano
                    worksheetFC.getCell(`F${linha}`).value = meses![4].mes + "/" + meses![4].ano
                    worksheetFC.getCell(`G${linha}`).value = meses![5].mes + "/" + meses![5].ano
                    worksheetFC.getCell(`H${linha}`).value = meses![6].mes + "/" + meses![6].ano
                    worksheetFC.getCell(`I${linha}`).value = meses![7].mes + "/" + meses![7].ano
                    worksheetFC.getCell(`J${linha}`).value = meses![8].mes + "/" + meses![8].ano
                    worksheetFC.getCell(`K${linha}`).value = meses![9].mes + "/" + meses![9].ano
                    worksheetFC.getCell(`L${linha}`).value = meses![10].mes + "/" + meses![10].ano
                    worksheetFC.getCell(`M${linha}`).value = meses![11].mes + "/" + meses![11].ano
                    worksheetFC.getCell(`A${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' } // Cor de preenchimento (background) preto
                    };

                    worksheetFC.getCell(`B${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`C${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`D${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`E${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`F${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`G${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`H${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`I${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`J${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`K${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`L${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`M${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`N${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };

                    worksheetFC.getCell(`O${linha}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'dc3545' }
                    };
                    worksheetFC.getCell(`A${linha}`).font = {
                        color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                        bold: true // Deixa em negrito
                    };

                    worksheetFC.getCell(`B${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`C${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`D${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`E${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`F${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`G${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`H${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`I${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`J${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`K${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`L${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`M${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`N${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    };

                    worksheetFC.getCell(`O${linha}`).font = {
                        color: { argb: 'FFFFFFFF' },
                        bold: true
                    }

                    worksheetFC.getCell(`N${linha} `).value = "Total"
                    worksheetFC.getCell(`O${linha}`).value = "%Receita"
                }
                //gerar despesa
                const totalDespesaMes = {
                    mes1: 0,
                    mes2: 0,
                    mes3: 0,
                    mes4: 0,
                    mes5: 0,
                    mes6: 0,
                    mes7: 0,
                    mes8: 0,
                    mes9: 0,
                    mes10: 0,
                    mes11: 0,
                    mes12: 0
                }
                for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                    totalDespesaMes.mes1 = totalDespesaMes.mes1 + resposta.data.despesas[i].mes1;
                    totalDespesaMes.mes2 = totalDespesaMes.mes2 + resposta.data.despesas[i].mes2;
                    totalDespesaMes.mes3 = totalDespesaMes.mes3 + resposta.data.despesas[i].mes3;
                    totalDespesaMes.mes4 = totalDespesaMes.mes4 + resposta.data.despesas[i].mes4;
                    totalDespesaMes.mes5 = totalDespesaMes.mes5 + resposta.data.despesas[i].mes5;
                    totalDespesaMes.mes6 = totalDespesaMes.mes6 + resposta.data.despesas[i].mes6;
                    totalDespesaMes.mes7 = totalDespesaMes.mes7 + resposta.data.despesas[i].mes7;
                    totalDespesaMes.mes8 = totalDespesaMes.mes8 + resposta.data.despesas[i].mes8;
                    totalDespesaMes.mes9 = totalDespesaMes.mes9 + resposta.data.despesas[i].mes9;
                    totalDespesaMes.mes10 = totalDespesaMes.mes10 + resposta.data.despesas[i].mes10;
                    totalDespesaMes.mes11 = totalDespesaMes.mes11 + resposta.data.despesas[i].mes11;
                    totalDespesaMes.mes12 = totalDespesaMes.mes12 + resposta.data.despesas[i].mes12;
                }
                //soma valores LINHA
                for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                    //soma os valores do mes para dar o total da linha
                    const despesa = resposta.data.despesas[i]
                    const total = despesa.mes1 + despesa.mes2 + despesa.mes3 + despesa.mes4 + despesa.mes5 + despesa.mes6 + despesa.mes7 + despesa.mes8 + despesa.mes9 + despesa.mes10 + despesa.mes11 + despesa.mes12
                    worksheetFC.getCell(`N${i + linha + 1}`).value = formatarValor((total).toString())
                    const totalDespesa: any = worksheetFC.getCell("N9")!.value
                    worksheetFC.getCell(`O${i + linha + 1}`).value = ((total / parseFloat(totalDespesa.replaceAll(".", "").replaceAll(",", "."))) * 100).toString()
                }
                for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                    const despesa = resposta.data.despesas[i]
                    const cellA = worksheetFC.getCell(`A${linha + 1 + i}`)
                    cellA.value = despesa.label
                    cellA.alignment = { horizontal: "center", vertical: "middle" }
                    const cellB = worksheetFC.getCell(`B${linha + 1 + i}`);
                    cellB.value = formatarValor(despesa.mes1.toString());
                    const cellC = worksheetFC.getCell(`C${linha + 1 + i}`);
                    cellC.value = formatarValor(despesa.mes2.toString());
                    const cellD = worksheetFC.getCell(`D${linha + 1 + i}`);
                    cellD.value = formatarValor(despesa.mes3.toString());
                    const cellE = worksheetFC.getCell(`E${linha + 1 + i}`);
                    cellE.value = formatarValor(despesa.mes4.toString());
                    const cellF = worksheetFC.getCell(`F${linha + 1 + i}`);
                    cellF.value = formatarValor(despesa.mes5.toString())
                    const cellG = worksheetFC.getCell(`G${linha + 1 + i}`);
                    cellG.value = formatarValor(despesa.mes6.toString());
                    const cellH = worksheetFC.getCell(`H${linha + 1 + i}`);
                    cellH.value = formatarValor(despesa.mes7.toString());
                    const cellI = worksheetFC.getCell(`I${linha + 1 + i}`);
                    cellI.value = formatarValor(despesa.mes8.toString());
                    const cellJ = worksheetFC.getCell(`J${linha + 1 + i}`);
                    cellJ.value = formatarValor(despesa.mes9.toString());
                    const cellK = worksheetFC.getCell(`K${linha + 1 + i}`);
                    cellK.value = formatarValor(despesa.mes10.toString());
                    const cellL = worksheetFC.getCell(`L${linha + 1 + i}`);
                    cellL.value = formatarValor(despesa.mes11.toString());
                    const cellM = worksheetFC.getCell(`M${linha + 1 + i}`);
                    cellM.value = formatarValor(despesa.mes12.toString());
                }
                //preenchendo totais
                const cellA_anyDespesas = worksheetFC.getCell(`A${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`)
                cellA_anyDespesas.value = "Total de Despesas"
                cellA_anyDespesas.font = {
                    bold: true
                }
                cellA_anyDespesas.alignment = {
                    vertical: "middle", horizontal: "center"
                }
                const cellB_anyTotaldespesa1 = worksheetFC.getCell(`B${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellB_anyTotaldespesa1.value = formatarValor(totalDespesaMes.mes1.toString());
                const cellC_anyTotaldespesa2 = worksheetFC.getCell(`C${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellC_anyTotaldespesa2.value = formatarValor(totalDespesaMes.mes2.toString());
                const cellD_anyTotaldespesa3 = worksheetFC.getCell(`D${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellD_anyTotaldespesa3.value = formatarValor(totalDespesaMes.mes3.toString());
                const cellE_anyTotaldespesa4 = worksheetFC.getCell(`E${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellE_anyTotaldespesa4.value = formatarValor(totalDespesaMes.mes4.toString());
                const cellF_anyTotaldespesa5 = worksheetFC.getCell(`F${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellF_anyTotaldespesa5.value = formatarValor(totalDespesaMes.mes5.toString());
                const cellG_anyTotaldespesa6 = worksheetFC.getCell(`G${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellG_anyTotaldespesa6.value = formatarValor(totalDespesaMes.mes6.toString());
                const cellH_anyTotaldespesa7 = worksheetFC.getCell(`H${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellH_anyTotaldespesa7.value = formatarValor(totalDespesaMes.mes7.toString());
                const cellI_anyTotaldespesa8 = worksheetFC.getCell(`I${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellI_anyTotaldespesa8.value = formatarValor(totalDespesaMes.mes8.toString());
                const cellJ_anyTotaldespesa9 = worksheetFC.getCell(`J${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellJ_anyTotaldespesa9.value = formatarValor(totalDespesaMes.mes9.toString());
                const cellK_anyTotaldespesa10 = worksheetFC.getCell(`K${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellK_anyTotaldespesa10.value = formatarValor(totalDespesaMes.mes10.toString());
                const cellL_anyTotaldespesa11 = worksheetFC.getCell(`L${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellL_anyTotaldespesa11.value = formatarValor(totalDespesaMes.mes11.toString());
                const cellM_anyTotaldespesa12 = worksheetFC.getCell(`M${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                cellM_anyTotaldespesa12.value = formatarValor(totalDespesaMes.mes12.toString());
                worksheetFC.getCell(`N${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`).value = formatarValor((totalDespesaMes.mes1 + totalDespesaMes.mes2 + totalDespesaMes.mes3 +
                    totalDespesaMes.mes4 + totalDespesaMes.mes5 + totalDespesaMes.mes6 +
                    totalDespesaMes.mes7 + totalDespesaMes.mes8 + totalDespesaMes.mes9 +
                    totalDespesaMes.mes10 + totalDespesaMes.mes11 + totalDespesaMes.mes12).toString())
                //#region otimizacao
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/linhas/otmz/mesanobase/${mesAnoBaseEscolhido}/${dadoCliente.id_cliente}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(async function (resposta) {
                    const linhas = resposta.data.linhas
                    if (linhas.length > 0) {
                        const worksheetOtimizacao = workbook.addWorksheet("Otimização", { properties: { tabColor: { argb: 'ff0000' } } })
                        const img64 = "data:image/png;base64," + getSessionData("imgFilial")!.replace(/["]/g, '')

                        const imgBuffer = base64ToBuffer(img64)

                        // Adiciona a imagem ao workbook
                        const imagemID = workbook.addImage({
                            buffer: imgBuffer,
                            extension: "png"
                        })

                        worksheetOtimizacao.addImage(imagemID, {
                            tl: { col: 0, row: 0 }, // Top-left corner position (coluna e linha são baseadas em 1, não 0)
                            ext: { width: 150, height: 120 } // Defina a largura e altura que desejar
                        })

                        worksheetOtimizacao.getCell("A1").value = '                               PLANEJAMENTO FINANCEIRO E PATRIMONIAL: GESTÃO FINANCEIRA'
                        worksheetOtimizacao.getRow(1).height = 60
                        worksheetOtimizacao.getCell("A1").font = {
                            size: 20,
                            bold: true
                        }

                        worksheetOtimizacao.getCell("B2").value = `                               Projeto ${dadoCliente.cod_cliente} - ${dadoCliente.nome} - ${dadoCliente.cpf}`
                        worksheetOtimizacao.getRow(2).height = 40
                        worksheetOtimizacao.getCell("B2").font = {
                            size: 15,
                            bold: true
                        }

                        worksheetOtimizacao.getCell("A5").value = 'PROPOSIÇÃO DE MELHORIAS NAS DESPESAS MENSAIS'
                        worksheetOtimizacao.getRow(5).height = 30
                        worksheetOtimizacao.getCell("A5").font = {
                            size: 15,
                            bold: true
                        }

                        const arrayDiaMesAno = new Date().toISOString().split("T")[0].split("-")
                        worksheetOtimizacao.getCell("N2").value = arrayDiaMesAno[2] + "/" + arrayDiaMesAno[1] + "/" + arrayDiaMesAno[0]
                        worksheetOtimizacao.getCell("N2").alignment = {
                            vertical: "middle",
                            horizontal: "center"
                        }
                        //retira pinta de branco rows de 1 a 6 
                        for (let i = 1; i <= 160; i = i + 1) {

                            worksheetOtimizacao.getCell(`A${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`B${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`C${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`E${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`D${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`F${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`G${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`H${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`I${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`J${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`K${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`L${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`M${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`N${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`M${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`O${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`P${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`Q${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`R${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`S${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`T${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`U${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`V${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }

                            worksheetOtimizacao.getCell(`W${i}`).fill = {

                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                            }
                        }
                        //montando cabecalho
                        const columA = worksheetOtimizacao.getColumn("A")
                        columA.width = 20
                        const columB = worksheetOtimizacao.getColumn("B")
                        columB.width = 15
                        const columC = worksheetOtimizacao.getColumn("C")
                        columC.width = 15
                        const columD = worksheetOtimizacao.getColumn("D")
                        columD.width = 15
                        const columE = worksheetOtimizacao.getColumn("E")
                        columE.width = 15
                        const columF = worksheetOtimizacao.getColumn("F")
                        columF.width = 25
                        const columG = worksheetOtimizacao.getColumn("G")
                        columG.width = 25
                        const columH = worksheetOtimizacao.getColumn("H")
                        columH.width = 35
                        const cellA6 = worksheetOtimizacao.getCell("A6")
                        cellA6.alignment = { horizontal: "center", vertical: "middle" }
                        cellA6.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF,"
                            }
                        }
                        cellA6.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellA6.value = "DESPESA"
                        const cellB6 = worksheetOtimizacao.getCell("B6")
                        cellB6.alignment = { horizontal: "center", vertical: "middle" }
                        cellB6.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF,"
                            }
                        }
                        cellB6.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellB6.value = "MENSAL"
                        const cellC6 = worksheetOtimizacao.getCell("C6")
                        cellC6.alignment = { horizontal: "center", vertical: "middle" }
                        cellC6.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF,"
                            }
                        }
                        cellC6.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellC6.value = "ANUAL"
                        const cellD6 = worksheetOtimizacao.getCell("D6")
                        cellD6.alignment = { horizontal: "center", vertical: "middle" }
                        cellD6.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF,"
                            }
                        }
                        cellD6.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellD6.value = "NOVO MENSAL"
                        const cellE6 = worksheetOtimizacao.getCell("E6")
                        cellE6.alignment = { horizontal: "center", vertical: "middle" }
                        cellE6.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF,"
                            }
                        }
                        cellE6.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellE6.value = "NOVO ANUAL"
                        const cellF6 = worksheetOtimizacao.getCell("F6")
                        cellF6.alignment = { horizontal: "center", vertical: "middle" }
                        cellF6.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF,"
                            }
                        }
                        cellF6.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellF6.value = "ECONOMIA MENSAL"
                        const cellG7 = worksheetOtimizacao.getCell("G6")
                        cellG7.alignment = { horizontal: "center", vertical: "middle" }
                        cellG7.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF,"
                            }
                        }
                        cellG7.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellG7.value = "ECONOMIA ANUAL"
                        const cellH6 = worksheetOtimizacao.getCell("H6")
                        cellH6.alignment = { horizontal: "center", vertical: "middle" }
                        cellH6.font = {
                            bold: true,
                            color: {
                                argb: "FFFFFF"
                            }
                        }
                        cellH6.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '000000' }
                        }
                        cellH6.value = "AÇÃO COMBINADA"
                        let valorMensalAtual = 0
                        let valorAnualAtual = 0
                        let valorNovoMensal = 0
                        let valorNovoAnual = 0
                        let valorEconomiaMensal = 0
                        let valorEconomiaAnual = 0
                        for (let i = 0; i < linhas.length; i = i + 1) {
                            const linha = linhas[i]
                            const cellA6 = worksheetOtimizacao.getCell(`A${i + 7}`)
                            cellA6.alignment = { horizontal: "center", vertical: "middle" }
                            cellA6.value = linha.despesa
                            const cellB6 = worksheetOtimizacao.getCell(`B${i + 7}`)
                            cellB6.alignment = { horizontal: "center", vertical: "middle" }
                            cellB6.value = formatarValor(linha.mensal.toString())
                            valorMensalAtual = valorMensalAtual + linha.mensal
                            const cellC6 = worksheetOtimizacao.getCell(`C${i + 7}`)
                            cellC6.alignment = { horizontal: "center", vertical: "middle" }
                            cellC6.value = formatarValor(linha.anual.toString())
                            valorAnualAtual = valorAnualAtual + linha.anual
                            const cellD6 = worksheetOtimizacao.getCell(`D${i + 7}`)
                            cellD6.alignment = { horizontal: "center", vertical: "middle" }
                            cellD6.value = formatarValor(linha.novoMensal.toString())
                            valorNovoMensal = valorNovoMensal + linha.novoMensal
                            const cellE6 = worksheetOtimizacao.getCell(`E${i + 7}`)
                            cellE6.alignment = { horizontal: "center", vertical: "middle" }
                            cellE6.value = formatarValor(linha.novoAnual.toString())
                            valorNovoAnual = valorNovoAnual + linha.novoAnual
                            const cellF6 = worksheetOtimizacao.getCell(`F${i + 7}`)
                            cellF6.alignment = { horizontal: "center", vertical: "middle" }
                            cellF6.value = formatarValor(linha.economiaMensal.toString())
                            valorEconomiaMensal = valorEconomiaMensal + linha.economiaMensal
                            const cellG7 = worksheetOtimizacao.getCell(`G${i + 7}`)
                            cellG7.alignment = { horizontal: "center", vertical: "middle" }
                            cellG7.value = formatarValor(linha.economiaAnual.toString())
                            valorEconomiaAnual = valorEconomiaAnual + linha.economiaAnual
                            const cellH7 = worksheetOtimizacao.getCell(`H${i + 7}`)
                            cellH7.alignment = { horizontal: "center", vertical: "middle" }
                            cellH7.value = linha.acaoCombinada
                        }
                        //gerando os totais
                        const index = linhas.length + 7
                        const cellA = worksheetOtimizacao.getCell(`A${index}`)
                        cellA.value = "TOTAL"
                        cellA.alignment = { horizontal: "center", vertical: "justify" }
                        const cellB = worksheetOtimizacao.getCell(`B${index}`)
                        cellB.value = formatarValor(valorMensalAtual.toString())
                        cellB.alignment = { horizontal: "center", vertical: "justify" }
                        const cellC = worksheetOtimizacao.getCell(`C${index}`)
                        cellC.value = formatarValor(valorAnualAtual.toString())
                        cellC.alignment = { horizontal: "center", vertical: "justify" }
                        const cellD = worksheetOtimizacao.getCell(`D${index}`)
                        cellD.value = formatarValor(valorNovoMensal.toString())
                        cellD.alignment = { horizontal: "center", vertical: "justify" }
                        const cellE = worksheetOtimizacao.getCell(`E${index}`)
                        cellE.value = formatarValor(valorNovoAnual.toString())
                        cellE.alignment = { horizontal: "center", vertical: "justify" }
                        const cellF = worksheetOtimizacao.getCell(`F${index}`)
                        cellF.value = formatarValor(valorEconomiaMensal.toString())
                        cellF.alignment = { horizontal: "center", vertical: "justify" }
                        const cellG = worksheetOtimizacao.getCell(`G${index}`)
                        cellG.value = formatarValor(valorEconomiaAnual.toString())
                        cellG.alignment = { horizontal: "center", vertical: "justify" }
                    }
                    //#region FC PRoposto
                    // Gera o arquivo Excel em formato de blob
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/proposto/criado/mesanobase/${mesAnoBaseEscolhido}/${dadoCliente.id_cliente}`, {
                        headers: {
                            Authorization: token
                        }
                    }).then(async function (resposta) {
                        // Cria um novo workbook e uma planilha
                        if (resposta.data.cabecalho.length > 0) {
                            const worksheetFCProposto = workbook.addWorksheet('FC - Proposto', { properties: { tabColor: { argb: 'ff0000' } } })

                            const img64 = "data:image/png;base64," + getSessionData("imgFilial")!.replace(/["]/g, '')

                            const imgBuffer = base64ToBuffer(img64)

                            // Adiciona a imagem ao workbook
                            const imagemID = workbook.addImage({
                                buffer: imgBuffer,
                                extension: "png"
                            })

                            worksheetFCProposto.addImage(imagemID, {
                                tl: { col: 0, row: 0 }, // Top-left corner position (coluna e linha são baseadas em 1, não 0)
                                ext: { width: 150, height: 120 } // Defina a largura e altura que desejar
                            })

                            worksheetFCProposto.getCell("A1").value = '                               PLANEJAMENTO FINANCEIRO E PATRIMONIAL: GESTÃO FINANCEIRA'
                            worksheetFCProposto.getRow(1).height = 60
                            worksheetFCProposto.getCell("A1").font = {
                                size: 20,
                                bold: true
                            }

                            worksheetFCProposto.getCell("B2").value = `                               Projeto ${dadoCliente.cod_cliente} - ${dadoCliente.nome} - ${dadoCliente.cpf}`
                            worksheetFCProposto.getRow(2).height = 40
                            worksheetFCProposto.getCell("B2").font = {
                                size: 15,
                                bold: true
                            }

                            worksheetFCProposto.getCell("A5").value = 'PROJEÇÃO DE RECEITAS E DESPESAS'
                            worksheetFCProposto.getRow(5).height = 30
                            worksheetFCProposto.getCell("A5").font = {
                                size: 15,
                                bold: true
                            }

                            const arrayDiaMesAno = new Date().toISOString().split("T")[0].split("-")
                            worksheetFCProposto.getCell("N2").value = arrayDiaMesAno[2] + "/" + arrayDiaMesAno[1] + "/" + arrayDiaMesAno[0]
                            worksheetFCProposto.getCell("N2").alignment = {
                                vertical: "middle",
                                horizontal: "center"
                            }
                            //retira pinta de branco rows de 1 a 6 
                            for (let i = 1; i <= 160; i = i + 1) {

                                worksheetFCProposto.getCell(`A${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`B${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`C${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`E${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`D${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`F${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`G${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`H${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`I${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`J${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`K${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`L${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`M${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`N${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`M${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`O${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`P${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`Q${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`R${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`S${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`T${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`U${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`V${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }

                                worksheetFCProposto.getCell(`W${i}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' } // Cor de preenchimento (bakcground) BRANCO
                                }
                            }
                            //congelo parte da tela para ficar de cabeçalho
                            worksheetFCProposto.views = [
                                { state: "frozen", ySplit: 7 }
                            ]
                            //linha 7 deve ser o cabecalho7
                            const meses = listarProximosMesesEAnos(parseInt(resposta.data.cabecalho[0].mesanobase.split("/")[0]), resposta.data.cabecalho[0].mesanobase.split("/")[1])
                            for (let i = 0; i < 1; i = i + 1) {
                                worksheetFCProposto.getCell("A7").value = "CONSOLIDADO"
                                worksheetFCProposto.getCell("B7").value = meses![0].mes + "/" + meses![0].ano
                                worksheetFCProposto.getCell("C7").value = meses![1].mes + "/" + meses![1].ano
                                worksheetFCProposto.getCell("D7").value = meses![2].mes + "/" + meses![2].ano
                                worksheetFCProposto.getCell("E7").value = meses![3].mes + "/" + meses![3].ano
                                worksheetFCProposto.getCell("F7").value = meses![4].mes + "/" + meses![4].ano
                                worksheetFCProposto.getCell("G7").value = meses![5].mes + "/" + meses![5].ano
                                worksheetFCProposto.getCell("H7").value = meses![6].mes + "/" + meses![6].ano
                                worksheetFCProposto.getCell("I7").value = meses![7].mes + "/" + meses![7].ano
                                worksheetFCProposto.getCell("J7").value = meses![8].mes + "/" + meses![8].ano
                                worksheetFCProposto.getCell("K7").value = meses![9].mes + "/" + meses![9].ano
                                worksheetFCProposto.getCell("L7").value = meses![10].mes + "/" + meses![10].ano
                                worksheetFCProposto.getCell("M7").value = meses![11].mes + "/" + meses![11].ano
                                worksheetFCProposto.getCell("A7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' } // Cor de preenchimento (background) preto
                                };

                                worksheetFCProposto.getCell("B7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("C7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("D7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("E7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("F7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("G7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("H7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("I7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("J7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("K7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("L7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("M7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("N7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };

                                worksheetFCProposto.getCell("O7").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000000' }
                                };
                                worksheetFCProposto.getCell("A7").font = {
                                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                                    bold: true // Deixa em negrito
                                };

                                worksheetFCProposto.getCell("B7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("C7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("D7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("E7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("F7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("G7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("H7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("I7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("J7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("K7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("L7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("M7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("N7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("O7").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                }
                                worksheetFCProposto.getCell("N7").value = "Total"
                                worksheetFCProposto.getCell("O7").value = "%Receita"
                                worksheetFCProposto.getCell("A7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("B7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("C7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("D7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("E7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("F7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("G7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("K7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("N7").alignment = { vertical: 'middle', horizontal: 'center' };
                                worksheetFCProposto.getCell("O7").alignment = { vertical: 'middle', horizontal: 'center' };
                            }
                            worksheetFCProposto.getRow(7).height = 20
                            const columA = worksheetFCProposto.getColumn("A")
                            columA.width = 25
                            const columB = worksheetFCProposto.getColumn("B")
                            columB.width = 10
                            const columnB = worksheetFCProposto.getColumn("B");
                            columnB.width = 10;
                            const columnC = worksheetFCProposto.getColumn("C");
                            columnC.width = 10;
                            const columnD = worksheetFCProposto.getColumn("D");
                            columnD.width = 10;
                            const columnE = worksheetFCProposto.getColumn("E");
                            columnE.width = 10;
                            const columnF = worksheetFCProposto.getColumn("F");
                            columnF.width = 10;
                            const columnG = worksheetFCProposto.getColumn("G");
                            columnG.width = 10;
                            const columnH = worksheetFCProposto.getColumn("H");
                            columnH.width = 10;
                            const columnI = worksheetFCProposto.getColumn("I");
                            columnI.width = 10;
                            const columnJ = worksheetFCProposto.getColumn("J");
                            columnJ.width = 10;
                            const columnK = worksheetFCProposto.getColumn("K");
                            columnK.width = 10;
                            const columnL = worksheetFCProposto.getColumn("L");
                            columnL.width = 10;
                            const columnM = worksheetFCProposto.getColumn("M");
                            columnM.width = 10;
                            const columnN = worksheetFCProposto.getColumn("N");
                            columnN.width = 10;
                            const columnO = worksheetFCProposto.getColumn("O");
                            columnO.width = 10;
                            const cellA8 = worksheetFCProposto.getCell("A8")
                            cellA8.value = "Receitas"
                            cellA8.alignment = { horizontal: "center", vertical: "middle" }
                            const cellA9 = worksheetFCProposto.getCell("A9")
                            cellA9.value = "Despesas"
                            cellA9.alignment = { horizontal: "center", vertical: "middle" }
                            const cellA10 = worksheetFCProposto.getCell("A10")
                            cellA10.value = "Variação"
                            cellA10.alignment = { horizontal: "center", vertical: "middle" }
                            const cellA11 = worksheetFCProposto.getCell("A11")
                            cellA11.alignment = { horizontal: "center", vertical: "middle" }
                            cellA11.value = "Saldo Projetado"
                            //calculando receitas (consolidado)
                            const totalReceitasMes = {
                                mes1: 0,
                                mes2: 0,
                                mes3: 0,
                                mes4: 0,
                                mes5: 0,
                                mes6: 0,
                                mes7: 0,
                                mes8: 0,
                                mes9: 0,
                                mes10: 0,
                                mes11: 0,
                                mes12: 0
                            }
                            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                                totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.ativos[i].mes1;
                                totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.ativos[i].mes2;
                                totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.ativos[i].mes3;
                                totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.ativos[i].mes4;
                                totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.ativos[i].mes5;
                                totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.ativos[i].mes6;
                                totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.ativos[i].mes7;
                                totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.ativos[i].mes8;
                                totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.ativos[i].mes9;
                                totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.ativos[i].mes10;
                                totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.ativos[i].mes11;
                                totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.ativos[i].mes12;
                            }
                            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                                totalReceitasMes.mes1 = totalReceitasMes.mes1 + resposta.data.passivos[i].mes1;
                                totalReceitasMes.mes2 = totalReceitasMes.mes2 + resposta.data.passivos[i].mes2;
                                totalReceitasMes.mes3 = totalReceitasMes.mes3 + resposta.data.passivos[i].mes3;
                                totalReceitasMes.mes4 = totalReceitasMes.mes4 + resposta.data.passivos[i].mes4;
                                totalReceitasMes.mes5 = totalReceitasMes.mes5 + resposta.data.passivos[i].mes5;
                                totalReceitasMes.mes6 = totalReceitasMes.mes6 + resposta.data.passivos[i].mes6;
                                totalReceitasMes.mes7 = totalReceitasMes.mes7 + resposta.data.passivos[i].mes7;
                                totalReceitasMes.mes8 = totalReceitasMes.mes8 + resposta.data.passivos[i].mes8;
                                totalReceitasMes.mes9 = totalReceitasMes.mes9 + resposta.data.passivos[i].mes9;
                                totalReceitasMes.mes10 = totalReceitasMes.mes10 + resposta.data.passivos[i].mes10;
                                totalReceitasMes.mes11 = totalReceitasMes.mes11 + resposta.data.passivos[i].mes11;
                                totalReceitasMes.mes12 = totalReceitasMes.mes12 + resposta.data.passivos[i].mes12;
                            }
                            const celB8 = worksheetFCProposto.getCell("B8");
                            celB8.value = formatarValor(totalReceitasMes.mes1.toString())
                            const celC8 = worksheetFCProposto.getCell("C8");
                            celC8.value = formatarValor(totalReceitasMes.mes2.toString())
                            const celD8 = worksheetFCProposto.getCell("D8");
                            celD8.value = formatarValor(totalReceitasMes.mes3.toString())
                            const celE8 = worksheetFCProposto.getCell("E8");
                            celE8.value = formatarValor(totalReceitasMes.mes4.toString())
                            const celF8 = worksheetFCProposto.getCell("F8");
                            celF8.value = formatarValor(totalReceitasMes.mes5.toString())
                            const celG8 = worksheetFCProposto.getCell("G8");
                            celG8.value = formatarValor(totalReceitasMes.mes6.toString())
                            const celH8 = worksheetFCProposto.getCell("H8");
                            celH8.value = formatarValor(totalReceitasMes.mes7.toString())
                            const celI8 = worksheetFCProposto.getCell("I8");
                            celI8.value = formatarValor(totalReceitasMes.mes8.toString())
                            const celJ8 = worksheetFCProposto.getCell("J8");
                            celJ8.value = formatarValor(totalReceitasMes.mes9.toString())
                            const celK8 = worksheetFCProposto.getCell("K8");
                            celK8.value = formatarValor(totalReceitasMes.mes10.toString());
                            const celL8 = worksheetFCProposto.getCell("L8");
                            celL8.value = formatarValor(totalReceitasMes.mes11.toString());
                            const celM8 = worksheetFCProposto.getCell("M8");
                            celM8.value = formatarValor(totalReceitasMes.mes12.toString());
                            const celN8 = worksheetFCProposto.getCell("N8");
                            celN8.value = formatarValor((totalReceitasMes.mes1 + totalReceitasMes.mes2 + totalReceitasMes.mes3 +
                                totalReceitasMes.mes4 + totalReceitasMes.mes5 + totalReceitasMes.mes6 +
                                totalReceitasMes.mes7 + totalReceitasMes.mes8 + totalReceitasMes.mes9 +
                                totalReceitasMes.mes10 + totalReceitasMes.mes11 + totalReceitasMes.mes12).toString())
                            worksheetFCProposto.getCell("O8").value = "100"
                            //gerando despesas totais(mes) consolidado
                            const totalDespesasMes = {
                                mes1: 0,
                                mes2: 0,
                                mes3: 0,
                                mes4: 0,
                                mes5: 0,
                                mes6: 0,
                                mes7: 0,
                                mes8: 0,
                                mes9: 0,
                                mes10: 0,
                                mes11: 0,
                                mes12: 0
                            }
                            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                                totalDespesasMes.mes1 = totalDespesasMes.mes1 + resposta.data.despesas[i].mes1;
                                totalDespesasMes.mes2 = totalDespesasMes.mes2 + resposta.data.despesas[i].mes2;
                                totalDespesasMes.mes3 = totalDespesasMes.mes3 + resposta.data.despesas[i].mes3;
                                totalDespesasMes.mes4 = totalDespesasMes.mes4 + resposta.data.despesas[i].mes4;
                                totalDespesasMes.mes5 = totalDespesasMes.mes5 + resposta.data.despesas[i].mes5;
                                totalDespesasMes.mes6 = totalDespesasMes.mes6 + resposta.data.despesas[i].mes6;
                                totalDespesasMes.mes7 = totalDespesasMes.mes7 + resposta.data.despesas[i].mes7;
                                totalDespesasMes.mes8 = totalDespesasMes.mes8 + resposta.data.despesas[i].mes8;
                                totalDespesasMes.mes9 = totalDespesasMes.mes9 + resposta.data.despesas[i].mes9;
                                totalDespesasMes.mes10 = totalDespesasMes.mes10 + resposta.data.despesas[i].mes10;
                                totalDespesasMes.mes11 = totalDespesasMes.mes11 + resposta.data.despesas[i].mes11;
                                totalDespesasMes.mes12 = totalDespesasMes.mes12 + resposta.data.despesas[i].mes12;
                            }
                            const celB9 = worksheetFCProposto.getCell("B9");
                            celB9.value = formatarValor(totalDespesasMes.mes1.toString());
                            const celC9 = worksheetFCProposto.getCell("C9");
                            celC9.value = formatarValor(totalDespesasMes.mes2.toString());
                            const celD9 = worksheetFCProposto.getCell("D9");
                            celD9.value = formatarValor(totalDespesasMes.mes3.toString());
                            const celE9 = worksheetFCProposto.getCell("E9");
                            celE9.value = formatarValor(totalDespesasMes.mes4.toString());
                            const celF9 = worksheetFCProposto.getCell("F9");
                            celF9.value = formatarValor(totalDespesasMes.mes5.toString());
                            const celG9 = worksheetFCProposto.getCell("G9");
                            celG9.value = formatarValor(totalDespesasMes.mes6.toString());
                            const celH9 = worksheetFCProposto.getCell("H9");
                            celH9.value = formatarValor(totalDespesasMes.mes7.toString());
                            const celI9 = worksheetFCProposto.getCell("I9");
                            celI9.value = formatarValor(totalDespesasMes.mes8.toString());
                            const celJ9 = worksheetFCProposto.getCell("J9");
                            celJ9.value = formatarValor(totalDespesasMes.mes9.toString());
                            const celK9 = worksheetFCProposto.getCell("K9");
                            celK9.value = formatarValor(totalDespesasMes.mes10.toString());
                            const celL9 = worksheetFCProposto.getCell("L9");
                            celL9.value = formatarValor(totalDespesasMes.mes11.toString());
                            const celM9 = worksheetFCProposto.getCell("M9");
                            celM9.value = formatarValor(totalDespesasMes.mes12.toString());
                            const celN9 = worksheetFCProposto.getCell("N9");
                            celN9.value = formatarValor((totalDespesasMes.mes1 + totalDespesasMes.mes2 + totalDespesasMes.mes3 +
                                totalDespesasMes.mes4 + totalDespesasMes.mes5 + totalDespesasMes.mes6 +
                                totalDespesasMes.mes7 + totalDespesasMes.mes8 + totalDespesasMes.mes9 +
                                totalDespesasMes.mes10 + totalDespesasMes.mes11 + totalDespesasMes.mes12).toString())
                            worksheetFCProposto.getCell("O9").value = ((parseFloat(celN9.value) / parseFloat(celN8.value)) * 100).toPrecision(2).toString()
                            const totalVariacaoMes = {
                                mes1: 0,
                                mes2: 0,
                                mes3: 0,
                                mes4: 0,
                                mes5: 0,
                                mes6: 0,
                                mes7: 0,
                                mes8: 0,
                                mes9: 0,
                                mes10: 0,
                                mes11: 0,
                                mes12: 0
                            }
                            for (let i = 0; i < 12; i = i + 1) {
                                totalVariacaoMes.mes1 = totalReceitasMes.mes1 - totalDespesasMes.mes1;
                                totalVariacaoMes.mes2 = totalReceitasMes.mes2 - totalDespesasMes.mes2;
                                totalVariacaoMes.mes3 = totalReceitasMes.mes3 - totalDespesasMes.mes3;
                                totalVariacaoMes.mes4 = totalReceitasMes.mes4 - totalDespesasMes.mes4;
                                totalVariacaoMes.mes5 = totalReceitasMes.mes5 - totalDespesasMes.mes5;
                                totalVariacaoMes.mes6 = totalReceitasMes.mes6 - totalDespesasMes.mes6;
                                totalVariacaoMes.mes7 = totalReceitasMes.mes7 - totalDespesasMes.mes7;
                                totalVariacaoMes.mes8 = totalReceitasMes.mes8 - totalDespesasMes.mes8;
                                totalVariacaoMes.mes9 = totalReceitasMes.mes9 - totalDespesasMes.mes9;
                                totalVariacaoMes.mes10 = totalReceitasMes.mes10 - totalDespesasMes.mes10;
                                totalVariacaoMes.mes11 = totalReceitasMes.mes11 - totalDespesasMes.mes11;
                                totalVariacaoMes.mes12 = totalReceitasMes.mes12 - totalDespesasMes.mes12;
                            }
                            const celB10 = worksheetFCProposto.getCell("B10");
                            celB10.value = formatarValor(totalVariacaoMes.mes1.toString());
                            const celC10 = worksheetFCProposto.getCell("C10");
                            celC10.value = formatarValor(totalVariacaoMes.mes2.toString());
                            const celD10 = worksheetFCProposto.getCell("D10");
                            celD10.value = formatarValor(totalVariacaoMes.mes3.toString());
                            const celE10 = worksheetFCProposto.getCell("E10");
                            celE10.value = formatarValor(totalVariacaoMes.mes4.toString());
                            const celF10 = worksheetFCProposto.getCell("F10");
                            celF10.value = formatarValor(totalVariacaoMes.mes5.toString());
                            const celG10 = worksheetFCProposto.getCell("G10");
                            celG10.value = formatarValor(totalVariacaoMes.mes6.toString());
                            const celH10 = worksheetFCProposto.getCell("H10");
                            celH10.value = formatarValor(totalVariacaoMes.mes7.toString());
                            const celI10 = worksheetFCProposto.getCell("I10");
                            celI10.value = formatarValor(totalVariacaoMes.mes8.toString());
                            const celJ10 = worksheetFCProposto.getCell("J10");
                            celJ10.value = formatarValor(totalVariacaoMes.mes9.toString());
                            const celK10 = worksheetFCProposto.getCell("K10");
                            celK10.value = formatarValor(totalVariacaoMes.mes10.toString());
                            const celL10 = worksheetFCProposto.getCell("L10");
                            celL10.value = formatarValor(totalVariacaoMes.mes11.toString());
                            const celM10 = worksheetFCProposto.getCell("M10");
                            celM10.value = formatarValor(totalVariacaoMes.mes12.toString());
                            const totalProjetadoMes = {
                                mes1: 0,
                                mes2: 0,
                                mes3: 0,
                                mes4: 0,
                                mes5: 0,
                                mes6: 0,
                                mes7: 0,
                                mes8: 0,
                                mes9: 0,
                                mes10: 0,
                                mes11: 0,
                                mes12: 0
                            }
                            for (let i = 0; i < 12; i = i + 1) {
                                totalProjetadoMes.mes1 = totalVariacaoMes.mes1;
                                totalProjetadoMes.mes2 = totalProjetadoMes.mes1 + totalVariacaoMes.mes2
                                totalProjetadoMes.mes3 = totalProjetadoMes.mes2 + totalVariacaoMes.mes3;
                                totalProjetadoMes.mes4 = totalProjetadoMes.mes3 + totalVariacaoMes.mes4;
                                totalProjetadoMes.mes5 = totalProjetadoMes.mes4 + totalVariacaoMes.mes5;
                                totalProjetadoMes.mes6 = totalProjetadoMes.mes5 + totalVariacaoMes.mes6;
                                totalProjetadoMes.mes7 = totalProjetadoMes.mes6 + totalVariacaoMes.mes7;
                                totalProjetadoMes.mes8 = totalProjetadoMes.mes7 + totalVariacaoMes.mes8;
                                totalProjetadoMes.mes9 = totalProjetadoMes.mes8 + totalVariacaoMes.mes9;
                                totalProjetadoMes.mes10 = totalProjetadoMes.mes9 + totalVariacaoMes.mes10;
                                totalProjetadoMes.mes11 = totalProjetadoMes.mes10 + totalVariacaoMes.mes11;
                                totalProjetadoMes.mes12 = totalProjetadoMes.mes11 + totalVariacaoMes.mes12
                            }
                            const celB11 = worksheetFCProposto.getCell("B11");
                            celB11.value = formatarValor(totalProjetadoMes.mes1.toString());
                            const celC11 = worksheetFCProposto.getCell("C11");
                            celC11.value = formatarValor(totalProjetadoMes.mes2.toString());
                            const celD11 = worksheetFCProposto.getCell("D11");
                            celD11.value = formatarValor(totalProjetadoMes.mes3.toString());
                            const celE11 = worksheetFCProposto.getCell("E11");
                            celE11.value = formatarValor(totalProjetadoMes.mes4.toString());
                            const celF11 = worksheetFCProposto.getCell("F11");
                            celF11.value = formatarValor(totalProjetadoMes.mes5.toString());
                            const celG11 = worksheetFCProposto.getCell("G11");
                            celG11.value = formatarValor(totalProjetadoMes.mes6.toString());
                            const celH11 = worksheetFCProposto.getCell("H11");
                            celH11.value = formatarValor(totalProjetadoMes.mes7.toString());
                            const celI11 = worksheetFCProposto.getCell("I11");
                            celI11.value = formatarValor(totalProjetadoMes.mes8.toString())
                            const celJ11 = worksheetFCProposto.getCell("J11");
                            celJ11.value = formatarValor(totalProjetadoMes.mes9.toString())
                            const celK11 = worksheetFCProposto.getCell("K11");
                            celK11.value = formatarValor(totalProjetadoMes.mes10.toString())
                            const celL11 = worksheetFCProposto.getCell("L11");
                            celL11.value = formatarValor(totalProjetadoMes.mes11.toString())
                            const celM11 = worksheetFCProposto.getCell("M11");
                            celM11.value = formatarValor(totalProjetadoMes.mes12.toString())
                            const cellA12 = worksheetFCProposto.getCell("A12")
                            cellA12.value = `RELAÇÃO DE RECEITAS`
                            cellA12.font = {
                                color: {
                                    argb: "000080"
                                },
                                size: 15,
                                bold: true
                            }
                            for (let i = 0; i < 1; i = i + 1) {
                                worksheetFCProposto.getCell("A13").value = "ORIGEM DA RENDA"
                                worksheetFCProposto.getCell("A13").alignment = { vertical: "middle", horizontal: "center" }
                                worksheetFCProposto.getCell("B13").value = meses![0].mes + "/" + meses![0].ano
                                worksheetFCProposto.getCell("C13").value = meses![1].mes + "/" + meses![1].ano
                                worksheetFCProposto.getCell("D13").value = meses![2].mes + "/" + meses![2].ano
                                worksheetFCProposto.getCell("E13").value = meses![3].mes + "/" + meses![3].ano
                                worksheetFCProposto.getCell("F13").value = meses![4].mes + "/" + meses![4].ano
                                worksheetFCProposto.getCell("G13").value = meses![5].mes + "/" + meses![5].ano
                                worksheetFCProposto.getCell("H13").value = meses![6].mes + "/" + meses![6].ano
                                worksheetFCProposto.getCell("I13").value = meses![7].mes + "/" + meses![7].ano
                                worksheetFCProposto.getCell("J13").value = meses![8].mes + "/" + meses![8].ano
                                worksheetFCProposto.getCell("K13").value = meses![9].mes + "/" + meses![9].ano
                                worksheetFCProposto.getCell("L13").value = meses![10].mes + "/" + meses![10].ano
                                worksheetFCProposto.getCell("M13").value = meses![11].mes + "/" + meses![11].ano
                                worksheetFCProposto.getCell("A13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' } // Cor de preenchimento (background) preto
                                };

                                worksheetFCProposto.getCell("B13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("C13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("D13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("E13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("F13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("G13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("H13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("I13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("J13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("K13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("L13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("M13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("N13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };

                                worksheetFCProposto.getCell("O13").fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: '000080' }
                                };
                                worksheetFCProposto.getCell("A13").font = {
                                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                                    bold: true // Deixa em negrito
                                };

                                worksheetFCProposto.getCell("B13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("C13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("D13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("E13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("F13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("G13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("H13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("I13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("J13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("K13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("L13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("M13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("N13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell("O13").font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                }
                                worksheetFCProposto.getCell("N13").value = "Total"
                                worksheetFCProposto.getCell("O13").value = "%Receita"
                            }
                            const cellA14 = worksheetFCProposto.getCell("A14")
                            cellA14.value = "RENDA ATIVA"
                            cellA14.font = {
                                bold: true
                            }
                            cellA14.alignment = {
                                horizontal: "center", vertical: "middle"
                            }
                            const totalRendaAtivaMes = {
                                mes1: 0,
                                mes2: 0,
                                mes3: 0,
                                mes4: 0,
                                mes5: 0,
                                mes6: 0,
                                mes7: 0,
                                mes8: 0,
                                mes9: 0,
                                mes10: 0,
                                mes11: 0,
                                mes12: 0
                            }
                            //soma valores MES
                            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                                totalRendaAtivaMes.mes1 = totalRendaAtivaMes.mes1 + resposta.data.ativos[i].mes1;
                                totalRendaAtivaMes.mes2 = totalRendaAtivaMes.mes2 + resposta.data.ativos[i].mes2;
                                totalRendaAtivaMes.mes3 = totalRendaAtivaMes.mes3 + resposta.data.ativos[i].mes3;
                                totalRendaAtivaMes.mes4 = totalRendaAtivaMes.mes4 + resposta.data.ativos[i].mes4;
                                totalRendaAtivaMes.mes5 = totalRendaAtivaMes.mes5 + resposta.data.ativos[i].mes5;
                                totalRendaAtivaMes.mes6 = totalRendaAtivaMes.mes6 + resposta.data.ativos[i].mes6;
                                totalRendaAtivaMes.mes7 = totalRendaAtivaMes.mes7 + resposta.data.ativos[i].mes7;
                                totalRendaAtivaMes.mes8 = totalRendaAtivaMes.mes8 + resposta.data.ativos[i].mes8;
                                totalRendaAtivaMes.mes9 = totalRendaAtivaMes.mes9 + resposta.data.ativos[i].mes9;
                                totalRendaAtivaMes.mes10 = totalRendaAtivaMes.mes10 + resposta.data.ativos[i].mes10;
                                totalRendaAtivaMes.mes11 = totalRendaAtivaMes.mes11 + resposta.data.ativos[i].mes11;
                                totalRendaAtivaMes.mes12 = totalRendaAtivaMes.mes12 + resposta.data.ativos[i].mes12;
                            }
                            //soma valores LINHA
                            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                                //soma os valores do mes para dar o total da linha
                                const ativo = resposta.data.ativos[i]
                                const total = ativo.mes1 + ativo.mes2 + ativo.mes3 + ativo.mes4 + ativo.mes5 + ativo.mes6 + ativo.mes7 + ativo.mes8 + ativo.mes9 + ativo.mes10 + ativo.mes11 + ativo.mes12
                                worksheetFCProposto.getCell(`N${i + 15}`).value = formatarValor((total).toString())
                                const totalReceita: any = worksheetFCProposto.getCell("N8")!.value
                                worksheetFCProposto.getCell(`O${i + 15}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
                            }
                            for (let i = 0; i < resposta.data.ativos.length; i = i + 1) {
                                const ativo = resposta.data.ativos[i]
                                const cellA = worksheetFCProposto.getCell(`A${15 + i}`)
                                cellA.value = ativo.label
                                cellA.alignment = { horizontal: "center", vertical: "middle" }
                                const cellB = worksheetFCProposto.getCell(`B${15 + i}`);
                                cellB.value = formatarValor(ativo.mes1.toString());
                                const cellC = worksheetFCProposto.getCell(`C${15 + i}`);
                                cellC.value = formatarValor(ativo.mes2.toString());
                                const cellD = worksheetFCProposto.getCell(`D${15 + i}`);
                                cellD.value = formatarValor(ativo.mes3.toString());
                                const cellE = worksheetFCProposto.getCell(`E${15 + i}`);
                                cellE.value = formatarValor(ativo.mes4.toString());
                                const cellF = worksheetFCProposto.getCell(`F${15 + i}`);
                                cellF.value = formatarValor(ativo.mes5.toString())
                                const cellG = worksheetFCProposto.getCell(`G${15 + i}`);
                                cellG.value = formatarValor(ativo.mes6.toString());
                                const cellH = worksheetFCProposto.getCell(`H${15 + i}`);
                                cellH.value = formatarValor(ativo.mes7.toString());
                                const cellI = worksheetFCProposto.getCell(`I${15 + i}`);
                                cellI.value = formatarValor(ativo.mes8.toString());
                                const cellJ = worksheetFCProposto.getCell(`J${15 + i}`);
                                cellJ.value = formatarValor(ativo.mes9.toString());
                                const cellK = worksheetFCProposto.getCell(`K${15 + i}`);
                                cellK.value = formatarValor(ativo.mes10.toString());
                                const cellL = worksheetFCProposto.getCell(`L${15 + i}`);
                                cellL.value = formatarValor(ativo.mes11.toString());
                                const cellM = worksheetFCProposto.getCell(`M${15 + i}`);
                                cellM.value = formatarValor(ativo.mes12.toString());
                            }
                            //preenchendo totais
                            const cellA_anyAtivo = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + 15}`)
                            cellA_anyAtivo.value = "Total Renda Ativa"
                            cellA_anyAtivo.font = {
                                bold: true
                            }
                            cellA_anyAtivo.alignment = {
                                vertical: "middle", horizontal: "center"
                            }
                            const cellB_anyTotalatv1 = worksheetFCProposto.getCell(`B${resposta.data.ativos.length + 15}`);
                            cellB_anyTotalatv1.value = formatarValor(totalRendaAtivaMes.mes1.toString());
                            const cellC_anyTotalatv2 = worksheetFCProposto.getCell(`C${resposta.data.ativos.length + 15}`);
                            cellC_anyTotalatv2.value = formatarValor(totalRendaAtivaMes.mes2.toString());
                            const cellD_anyTotalatv3 = worksheetFCProposto.getCell(`D${resposta.data.ativos.length + 15}`);
                            cellD_anyTotalatv3.value = formatarValor(totalRendaAtivaMes.mes3.toString());
                            const cellE_anyTotalatv4 = worksheetFCProposto.getCell(`E${resposta.data.ativos.length + 15}`);
                            cellE_anyTotalatv4.value = formatarValor(totalRendaAtivaMes.mes4.toString());
                            const cellF_anyTotalatv5 = worksheetFCProposto.getCell(`F${resposta.data.ativos.length + 15}`);
                            cellF_anyTotalatv5.value = formatarValor(totalRendaAtivaMes.mes5.toString());
                            const cellG_anyTotalatv6 = worksheetFCProposto.getCell(`G${resposta.data.ativos.length + 15}`);
                            cellG_anyTotalatv6.value = formatarValor(totalRendaAtivaMes.mes6.toString());
                            const cellH_anyTotalatv7 = worksheetFCProposto.getCell(`H${resposta.data.ativos.length + 15}`);
                            cellH_anyTotalatv7.value = formatarValor(totalRendaAtivaMes.mes7.toString());
                            const cellI_anyTotalatv8 = worksheetFCProposto.getCell(`I${resposta.data.ativos.length + 15}`);
                            cellI_anyTotalatv8.value = formatarValor(totalRendaAtivaMes.mes8.toString());
                            const cellJ_anyTotalatv9 = worksheetFCProposto.getCell(`J${resposta.data.ativos.length + 15}`);
                            cellJ_anyTotalatv9.value = formatarValor(totalRendaAtivaMes.mes9.toString());
                            const cellK_anyTotalatv10 = worksheetFCProposto.getCell(`K${resposta.data.ativos.length + 15}`);
                            cellK_anyTotalatv10.value = formatarValor(totalRendaAtivaMes.mes10.toString());
                            const cellL_anyTotalatv11 = worksheetFCProposto.getCell(`L${resposta.data.ativos.length + 15}`);
                            cellL_anyTotalatv11.value = formatarValor(totalRendaAtivaMes.mes11.toString());
                            const cellM_anyTotalatv12 = worksheetFCProposto.getCell(`M${resposta.data.ativos.length + 15}`);
                            cellM_anyTotalatv12.value = formatarValor(totalRendaAtivaMes.mes12.toString());
                            worksheetFCProposto.getCell(`N${resposta.data.ativos.length + 15}`).value = formatarValor((totalRendaAtivaMes.mes1 + totalRendaAtivaMes.mes2 + totalRendaAtivaMes.mes3 +
                                totalRendaAtivaMes.mes4 + totalRendaAtivaMes.mes5 + totalRendaAtivaMes.mes6 +
                                totalRendaAtivaMes.mes7 + totalRendaAtivaMes.mes8 + totalRendaAtivaMes.mes9 +
                                totalRendaAtivaMes.mes10 + totalRendaAtivaMes.mes11 + totalRendaAtivaMes.mes12).toString())
                            //gerar renda passiva
                            const totalRendaPassivaMes = {
                                mes1: 0,
                                mes2: 0,
                                mes3: 0,
                                mes4: 0,
                                mes5: 0,
                                mes6: 0,
                                mes7: 0,
                                mes8: 0,
                                mes9: 0,
                                mes10: 0,
                                mes11: 0,
                                mes12: 0
                            }
                            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                                totalRendaPassivaMes.mes1 = totalRendaPassivaMes.mes1 + resposta.data.passivos[i].mes1;
                                totalRendaPassivaMes.mes2 = totalRendaPassivaMes.mes2 + resposta.data.passivos[i].mes2;
                                totalRendaPassivaMes.mes3 = totalRendaPassivaMes.mes3 + resposta.data.passivos[i].mes3;
                                totalRendaPassivaMes.mes4 = totalRendaPassivaMes.mes4 + resposta.data.passivos[i].mes4;
                                totalRendaPassivaMes.mes5 = totalRendaPassivaMes.mes5 + resposta.data.passivos[i].mes5;
                                totalRendaPassivaMes.mes6 = totalRendaPassivaMes.mes6 + resposta.data.passivos[i].mes6;
                                totalRendaPassivaMes.mes7 = totalRendaPassivaMes.mes7 + resposta.data.passivos[i].mes7;
                                totalRendaPassivaMes.mes8 = totalRendaPassivaMes.mes8 + resposta.data.passivos[i].mes8;
                                totalRendaPassivaMes.mes9 = totalRendaPassivaMes.mes9 + resposta.data.passivos[i].mes9;
                                totalRendaPassivaMes.mes10 = totalRendaPassivaMes.mes10 + resposta.data.passivos[i].mes10;
                                totalRendaPassivaMes.mes11 = totalRendaPassivaMes.mes11 + resposta.data.passivos[i].mes11;
                                totalRendaPassivaMes.mes12 = totalRendaPassivaMes.mes12 + resposta.data.passivos[i].mes12;
                            }
                            //soma valores LINHA
                            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                                //soma os valores do mes para dar o total da linha
                                const passivo = resposta.data.passivos[i]
                                const total = passivo.mes1 + passivo.mes2 + passivo.mes3 + passivo.mes4 + passivo.mes5 + passivo.mes6 + passivo.mes7 + passivo.mes8 + passivo.mes9 + passivo.mes10 + passivo.mes11 + passivo.mes12
                                worksheetFCProposto.getCell(`N${i + 17 + resposta.data.ativos.length}`).value = formatarValor((total).toString())
                                const totalReceita: any = worksheetFCProposto.getCell("N8")!.value
                                worksheetFCProposto.getCell(`O${i + 17 + resposta.data.ativos.length}`).value = ((total / parseFloat(totalReceita.replaceAll(".", "").replaceAll(",", "."))) * 100).toPrecision(2).toString()
                            }
                            const cellA_any = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + 16}`)
                            cellA_any.value = "RENDA PASSIVA"
                            cellA_any.font = {
                                bold: true
                            }
                            cellA_any.alignment = { horizontal: "center", vertical: "middle" }
                            for (let i = 0; i < resposta.data.passivos.length; i = i + 1) {
                                const passivo = resposta.data.passivos[i]
                                const ultimoAtivo = resposta.data.ativos.length + 17
                                const cellA = worksheetFCProposto.getCell(`A${ultimoAtivo + i}`)
                                cellA.value = passivo.label
                                cellA.alignment = { horizontal: "center", vertical: "middle" }
                                const cellB = worksheetFCProposto.getCell(`B${ultimoAtivo + i}`);
                                cellB.value = formatarValor(passivo.mes1.toString());
                                const cellC = worksheetFCProposto.getCell(`C${ultimoAtivo + i}`);
                                cellC.value = formatarValor(passivo.mes2.toString());
                                const cellD = worksheetFCProposto.getCell(`D${ultimoAtivo + i}`);
                                cellD.value = formatarValor(passivo.mes3.toString());
                                const cellE = worksheetFCProposto.getCell(`E${ultimoAtivo + i}`);
                                cellE.value = formatarValor(passivo.mes4.toString());
                                const cellF = worksheetFCProposto.getCell(`F${ultimoAtivo + i}`);
                                cellF.value = formatarValor(passivo.mes5.toString())
                                const cellG = worksheetFCProposto.getCell(`G${ultimoAtivo + i}`);
                                cellG.value = formatarValor(passivo.mes6.toString());
                                const cellH = worksheetFCProposto.getCell(`H${ultimoAtivo + i}`);
                                cellH.value = formatarValor(passivo.mes7.toString());
                                const cellI = worksheetFCProposto.getCell(`I${ultimoAtivo + i}`);
                                cellI.value = formatarValor(passivo.mes8.toString());
                                const cellJ = worksheetFCProposto.getCell(`J${ultimoAtivo + i}`);
                                cellJ.value = formatarValor(passivo.mes9.toString());
                                const cellK = worksheetFCProposto.getCell(`K${ultimoAtivo + i}`);
                                cellK.value = formatarValor(passivo.mes10.toString());
                                const cellL = worksheetFCProposto.getCell(`L${ultimoAtivo + i}`);
                                cellL.value = formatarValor(passivo.mes11.toString());
                                const cellM = worksheetFCProposto.getCell(`M${ultimoAtivo + i}`);
                                cellM.value = formatarValor(passivo.mes12.toString());
                            }
                            //preenchendo totais
                            const cellA_anyPassivo = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + resposta.data.passivos.length + 17}`)
                            cellA_anyPassivo.value = "Total Renda Passiva"
                            cellA_anyPassivo.font = {
                                bold: true
                            }
                            cellA_anyPassivo.alignment = {
                                vertical: "middle", horizontal: "center"
                            }
                            const cellB_anyTotalpassivo1 = worksheetFCProposto.getCell(`B${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellB_anyTotalpassivo1.value = formatarValor(totalRendaPassivaMes.mes1.toString());
                            const cellC_anyTotalpassivo2 = worksheetFCProposto.getCell(`C${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellC_anyTotalpassivo2.value = formatarValor(totalRendaPassivaMes.mes2.toString());
                            const cellD_anyTotalpassivo3 = worksheetFCProposto.getCell(`D${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellD_anyTotalpassivo3.value = formatarValor(totalRendaPassivaMes.mes3.toString());
                            const cellE_anyTotalpassivo4 = worksheetFCProposto.getCell(`E${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellE_anyTotalpassivo4.value = formatarValor(totalRendaPassivaMes.mes4.toString());
                            const cellF_anyTotalpassivo5 = worksheetFCProposto.getCell(`F${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellF_anyTotalpassivo5.value = formatarValor(totalRendaPassivaMes.mes5.toString());
                            const cellG_anyTotalpassivo6 = worksheetFCProposto.getCell(`G${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellG_anyTotalpassivo6.value = formatarValor(totalRendaPassivaMes.mes6.toString());
                            const cellH_anyTotalpassivo7 = worksheetFCProposto.getCell(`H${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellH_anyTotalpassivo7.value = formatarValor(totalRendaPassivaMes.mes7.toString());
                            const cellI_anyTotalpassivo8 = worksheetFCProposto.getCell(`I${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellI_anyTotalpassivo8.value = formatarValor(totalRendaPassivaMes.mes8.toString());
                            const cellJ_anyTotalpassivo9 = worksheetFCProposto.getCell(`J${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellJ_anyTotalpassivo9.value = formatarValor(totalRendaPassivaMes.mes9.toString());
                            const cellK_anyTotalpassivo10 = worksheetFCProposto.getCell(`K${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellK_anyTotalpassivo10.value = formatarValor(totalRendaPassivaMes.mes10.toString());
                            const cellL_anyTotalpassivo11 = worksheetFCProposto.getCell(`L${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellL_anyTotalpassivo11.value = formatarValor(totalRendaPassivaMes.mes11.toString());
                            const cellM_anyTotalpassivo12 = worksheetFCProposto.getCell(`M${resposta.data.ativos.length + resposta.data.passivos.length + 17}`);
                            cellM_anyTotalpassivo12.value = formatarValor(totalRendaPassivaMes.mes12.toString());
                            worksheetFCProposto.getCell(`N${resposta.data.ativos.length + resposta.data.passivos.length + 17}`).value = formatarValor((totalRendaPassivaMes.mes1 + totalRendaPassivaMes.mes2 + totalRendaPassivaMes.mes3 +
                                totalRendaPassivaMes.mes4 + totalRendaPassivaMes.mes5 + totalRendaPassivaMes.mes6 +
                                totalRendaPassivaMes.mes7 + totalRendaPassivaMes.mes8 + totalRendaPassivaMes.mes9 +
                                totalRendaPassivaMes.mes10 + totalRendaPassivaMes.mes11 + totalRendaPassivaMes.mes12).toString())
                            //#region despesa
                            const linha = 18 + resposta.data.ativos.length + resposta.data.passivos.length
                            for (let i = 0; i < 1; i = i + 1) {
                                worksheetFCProposto.getCell(`A${linha}`).value = "DESPESAS"
                                worksheetFCProposto.getCell(`A${linha}`).alignment = { vertical: "middle", horizontal: "center" }
                                worksheetFCProposto.getCell(`B${linha}`).value = meses![0].mes + "/" + meses![0].ano
                                worksheetFCProposto.getCell(`C${linha}`).value = meses![1].mes + "/" + meses![1].ano
                                worksheetFCProposto.getCell(`D${linha}`).value = meses![2].mes + "/" + meses![2].ano
                                worksheetFCProposto.getCell(`E${linha}`).value = meses![3].mes + "/" + meses![3].ano
                                worksheetFCProposto.getCell(`F${linha}`).value = meses![4].mes + "/" + meses![4].ano
                                worksheetFCProposto.getCell(`G${linha}`).value = meses![5].mes + "/" + meses![5].ano
                                worksheetFCProposto.getCell(`H${linha}`).value = meses![6].mes + "/" + meses![6].ano
                                worksheetFCProposto.getCell(`I${linha}`).value = meses![7].mes + "/" + meses![7].ano
                                worksheetFCProposto.getCell(`J${linha}`).value = meses![8].mes + "/" + meses![8].ano
                                worksheetFCProposto.getCell(`K${linha}`).value = meses![9].mes + "/" + meses![9].ano
                                worksheetFCProposto.getCell(`L${linha}`).value = meses![10].mes + "/" + meses![10].ano
                                worksheetFCProposto.getCell(`M${linha}`).value = meses![11].mes + "/" + meses![11].ano
                                worksheetFCProposto.getCell(`A${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' } // Cor de preenchimento (background) preto
                                };

                                worksheetFCProposto.getCell(`B${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`C${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`D${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`E${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`F${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`G${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`H${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`I${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`J${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`K${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`L${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`M${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`N${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };

                                worksheetFCProposto.getCell(`O${linha}`).fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'dc3545' }
                                };
                                worksheetFCProposto.getCell(`A${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' }, // Cor da fonte branca
                                    bold: true // Deixa em negrito
                                };

                                worksheetFCProposto.getCell(`B${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`C${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`D${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`E${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`F${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`G${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`H${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`I${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`J${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`K${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`L${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`M${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`N${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                };

                                worksheetFCProposto.getCell(`O${linha}`).font = {
                                    color: { argb: 'FFFFFFFF' },
                                    bold: true
                                }

                                worksheetFCProposto.getCell(`N${linha} `).value = "Total"
                                worksheetFCProposto.getCell(`O${linha}`).value = "%Receita"
                            }
                            //gerar despesa
                            const totalDespesaMes = {
                                mes1: 0,
                                mes2: 0,
                                mes3: 0,
                                mes4: 0,
                                mes5: 0,
                                mes6: 0,
                                mes7: 0,
                                mes8: 0,
                                mes9: 0,
                                mes10: 0,
                                mes11: 0,
                                mes12: 0
                            }
                            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                                totalDespesaMes.mes1 = totalDespesaMes.mes1 + resposta.data.despesas[i].mes1;
                                totalDespesaMes.mes2 = totalDespesaMes.mes2 + resposta.data.despesas[i].mes2;
                                totalDespesaMes.mes3 = totalDespesaMes.mes3 + resposta.data.despesas[i].mes3;
                                totalDespesaMes.mes4 = totalDespesaMes.mes4 + resposta.data.despesas[i].mes4;
                                totalDespesaMes.mes5 = totalDespesaMes.mes5 + resposta.data.despesas[i].mes5;
                                totalDespesaMes.mes6 = totalDespesaMes.mes6 + resposta.data.despesas[i].mes6;
                                totalDespesaMes.mes7 = totalDespesaMes.mes7 + resposta.data.despesas[i].mes7;
                                totalDespesaMes.mes8 = totalDespesaMes.mes8 + resposta.data.despesas[i].mes8;
                                totalDespesaMes.mes9 = totalDespesaMes.mes9 + resposta.data.despesas[i].mes9;
                                totalDespesaMes.mes10 = totalDespesaMes.mes10 + resposta.data.despesas[i].mes10;
                                totalDespesaMes.mes11 = totalDespesaMes.mes11 + resposta.data.despesas[i].mes11;
                                totalDespesaMes.mes12 = totalDespesaMes.mes12 + resposta.data.despesas[i].mes12;
                            }
                            //soma valores LINHA
                            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                                //soma os valores do mes para dar o total da linha
                                const despesa = resposta.data.despesas[i]
                                const total = despesa.mes1 + despesa.mes2 + despesa.mes3 + despesa.mes4 + despesa.mes5 + despesa.mes6 + despesa.mes7 + despesa.mes8 + despesa.mes9 + despesa.mes10 + despesa.mes11 + despesa.mes12
                                worksheetFCProposto.getCell(`N${i + linha + 1}`).value = formatarValor((total).toString())
                                const totalDespesa: any = worksheetFCProposto.getCell("N9")!.value
                                worksheetFCProposto.getCell(`O${i + linha + 1}`).value = ((total / parseFloat(totalDespesa.replaceAll(".", "").replaceAll(",", "."))) * 100).toString()
                            }
                            for (let i = 0; i < resposta.data.despesas.length; i = i + 1) {
                                const despesa = resposta.data.despesas[i]
                                const cellA = worksheetFCProposto.getCell(`A${linha + 1 + i}`)
                                cellA.value = despesa.label
                                cellA.alignment = { horizontal: "center", vertical: "middle" }
                                const cellB = worksheetFCProposto.getCell(`B${linha + 1 + i}`);
                                cellB.value = formatarValor(despesa.mes1.toString());
                                const cellC = worksheetFCProposto.getCell(`C${linha + 1 + i}`);
                                cellC.value = formatarValor(despesa.mes2.toString());
                                const cellD = worksheetFCProposto.getCell(`D${linha + 1 + i}`);
                                cellD.value = formatarValor(despesa.mes3.toString());
                                const cellE = worksheetFCProposto.getCell(`E${linha + 1 + i}`);
                                cellE.value = formatarValor(despesa.mes4.toString());
                                const cellF = worksheetFCProposto.getCell(`F${linha + 1 + i}`);
                                cellF.value = formatarValor(despesa.mes5.toString())
                                const cellG = worksheetFCProposto.getCell(`G${linha + 1 + i}`);
                                cellG.value = formatarValor(despesa.mes6.toString());
                                const cellH = worksheetFCProposto.getCell(`H${linha + 1 + i}`);
                                cellH.value = formatarValor(despesa.mes7.toString());
                                const cellI = worksheetFCProposto.getCell(`I${linha + 1 + i}`);
                                cellI.value = formatarValor(despesa.mes8.toString());
                                const cellJ = worksheetFCProposto.getCell(`J${linha + 1 + i}`);
                                cellJ.value = formatarValor(despesa.mes9.toString());
                                const cellK = worksheetFCProposto.getCell(`K${linha + 1 + i}`);
                                cellK.value = formatarValor(despesa.mes10.toString());
                                const cellL = worksheetFCProposto.getCell(`L${linha + 1 + i}`);
                                cellL.value = formatarValor(despesa.mes11.toString());
                                const cellM = worksheetFCProposto.getCell(`M${linha + 1 + i}`);
                                cellM.value = formatarValor(despesa.mes12.toString());
                            }
                            //preenchendo totais
                            const cellA_anyDespesas = worksheetFCProposto.getCell(`A${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`)
                            cellA_anyDespesas.value = "Total de Despesas"
                            cellA_anyDespesas.font = {
                                bold: true
                            }
                            cellA_anyDespesas.alignment = {
                                vertical: "middle", horizontal: "center"
                            }
                            const cellB_anyTotaldespesa1 = worksheetFCProposto.getCell(`B${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellB_anyTotaldespesa1.value = formatarValor(totalDespesaMes.mes1.toString());
                            const cellC_anyTotaldespesa2 = worksheetFCProposto.getCell(`C${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellC_anyTotaldespesa2.value = formatarValor(totalDespesaMes.mes2.toString());
                            const cellD_anyTotaldespesa3 = worksheetFCProposto.getCell(`D${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellD_anyTotaldespesa3.value = formatarValor(totalDespesaMes.mes3.toString());
                            const cellE_anyTotaldespesa4 = worksheetFCProposto.getCell(`E${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellE_anyTotaldespesa4.value = formatarValor(totalDespesaMes.mes4.toString());
                            const cellF_anyTotaldespesa5 = worksheetFCProposto.getCell(`F${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellF_anyTotaldespesa5.value = formatarValor(totalDespesaMes.mes5.toString());
                            const cellG_anyTotaldespesa6 = worksheetFCProposto.getCell(`G${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellG_anyTotaldespesa6.value = formatarValor(totalDespesaMes.mes6.toString());
                            const cellH_anyTotaldespesa7 = worksheetFCProposto.getCell(`H${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellH_anyTotaldespesa7.value = formatarValor(totalDespesaMes.mes7.toString());
                            const cellI_anyTotaldespesa8 = worksheetFCProposto.getCell(`I${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellI_anyTotaldespesa8.value = formatarValor(totalDespesaMes.mes8.toString());
                            const cellJ_anyTotaldespesa9 = worksheetFCProposto.getCell(`J${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellJ_anyTotaldespesa9.value = formatarValor(totalDespesaMes.mes9.toString());
                            const cellK_anyTotaldespesa10 = worksheetFCProposto.getCell(`K${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellK_anyTotaldespesa10.value = formatarValor(totalDespesaMes.mes10.toString());
                            const cellL_anyTotaldespesa11 = worksheetFCProposto.getCell(`L${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellL_anyTotaldespesa11.value = formatarValor(totalDespesaMes.mes11.toString());
                            const cellM_anyTotaldespesa12 = worksheetFCProposto.getCell(`M${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`);
                            cellM_anyTotaldespesa12.value = formatarValor(totalDespesaMes.mes12.toString());
                            worksheetFCProposto.getCell(`N${resposta.data.ativos.length + + resposta.data.passivos.length + resposta.data.despesas.length + 19}`).value = formatarValor((totalDespesaMes.mes1 + totalDespesaMes.mes2 + totalDespesaMes.mes3 +
                                totalDespesaMes.mes4 + totalDespesaMes.mes5 + totalDespesaMes.mes6 +
                                totalDespesaMes.mes7 + totalDespesaMes.mes8 + totalDespesaMes.mes9 +
                                totalDespesaMes.mes10 + totalDespesaMes.mes11 + totalDespesaMes.mes12).toString())
                        }
                        //#region informações pessoais de cadastro
                        const cadastro = workbook.addWorksheet("Informações Pessoais", { properties: { tabColor: { argb: '0000ff' } } })

                        const columA_ = cadastro.getColumn("A")
                        columA_.width = 25

                        const columB_ = cadastro.getColumn("B")
                        columB_.width = 35

                        const columC_ = cadastro.getColumn("C")
                        columC_.width = 35

                        const cellA1 = cadastro.getCell("A1")
                        cellA1.value = "ID Cliente"
                        cellA1.font = {
                            bold: true
                        }
                        cellA1.border = {
                            right: {
                                style: "thin"
                            }
                        }


                        const cellB1_ = cadastro.getCell("B1")
                        cellB1_.value = dadoCliente.id_cliente

                        const cellA2_ = cadastro.getCell("A2")
                        cellA2_.value = "CPF"
                        cellA2_.font = {
                            bold: true
                        }
                        cellA2_.border = {
                            right: {
                                style: "thin"
                            }
                        }


                        const cellB2_ = cadastro.getCell("B2")
                        cellB2_.value = dadoCliente.cpf

                        
                        const cellC2_ = cadastro.getCell("C2")
                        cellC2_.value = dadoCliente.cpfparceiro || ""

                        const cellA3_ = cadastro.getCell("A3")
                        cellA3_.value = "Nascimento"
                        cellA3_.font = {
                            bold: true
                        }
                        cellA3_.border = {
                            right: {
                                style: "thin"
                            }
                        }

                        const cellB3_ = cadastro.getCell("B3")
                        const data_ = dadoCliente.dtnascimento.split("T")[0].split("-") || ""
                        const dataFormatada = data_ !== "" ? data_[2] + "/" + data_[1] + "/" + data_[0] : data_
                        cellB3_.value = dataFormatada

                        const cellC3_ = cadastro.getCell("C3")
                        const data = dadoCliente.datanascimentoparceiro !== null ? dadoCliente.datanascimentoparceiro.split("T")[0].split("-") : ""
                        const dataFormatadaParceiro = data !== "" ? data[2] + "/" + data[1] + "/" + data[0] : data
                        cellC3_.value = dataFormatadaParceiro

                        const cellA4_ = cadastro.getCell("A4")
                        cellA4_.value = "Nome"
                        cellA4_.font = {
                            bold: true
                        }
                        cellA4_.border = {
                            right: {
                                style: "thin"
                            }
                        }

                        const cellB4_ = cadastro.getCell("B4")
                        cellB4_.value = dadoCliente.nome

                        const cellC4_ = cadastro.getCell("C4")
                        cellC4_.value = dadoCliente.nomeparceiro || ""
                        //profissão
                        const cellA5_ = cadastro.getCell("A5")
                        cellA5_.value = "Profissão"
                        cellA5_.font = {
                            bold: true
                        }
                        cellA5_.border = {
                            right: {
                                style: "thin"
                            }
                        }

                        const cellB5_ = cadastro.getCell("B5")
                        cellB5_.value = dadoCliente.cargo || ""

                        const cellC5_ = cadastro.getCell("C5")
                        cellC5_.value = dadoCliente.cargoparceiro || ""
                        //empresa
                        const cellA6_ = cadastro.getCell("A6")
                        cellA6_.value = "Empresa"
                        cellA6_.font = {
                            bold: true
                        }
                        cellA6_.border = {
                            right: {
                                style: "thin"
                            }
                        }

                        const cellB6_ = cadastro.getCell("B6")
                        cellB6_.value = dadoCliente.empresa || ""
                        const cellC6_ = cadastro.getCell("C6")
                        cellC6_.value = dadoCliente.empresaparceiro || ""
                        //email
                        const cellA7_ = cadastro.getCell("A7")
                        cellA7_.value = "Email"
                        cellA7_.font = {
                            bold: true
                        }
                        cellA7_.border = {
                            right: {
                                style: "thin"
                            }
                        }

                        const cellB7_ = cadastro.getCell("B7")
                        cellB7_.value = dadoCliente.email

                        //Celular
                        const cellA8_ = cadastro.getCell("A8")
                        cellA8_.value = "Celular"
                        cellA8_.font = {
                            bold: true
                        }
                        cellA8_.border = {
                            right: {
                                style: "thin"
                            }
                        }

                        const cellB8_ = cadastro.getCell("B8")
                        cellB8_.value = dadoCliente.telcelular

                        //telefone
                        const cellA9_ = cadastro.getCell("A9")
                        cellA9_.value = "Telefone"
                        cellA9_.font = {
                            bold: true
                        }
                        cellA9_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB9_ = cadastro.getCell("B9")
                        cellB9_.value = dadoCliente.telfixo

                        //CEP
                        const cellA10_ = cadastro.getCell("A10")
                        cellA10_.value = "CEP"
                        cellA10_.font = {
                            bold: true
                        }
                        cellA10_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB10_ = cadastro.getCell("B10")
                        cellB10_.value = dadoCliente.cep

                        //numero
                        const cellA11_ = cadastro.getCell("A11")
                        cellA11_.value = "Numero"
                        cellA11_.font = {
                            bold: true
                        }
                        cellA11_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB11_ = cadastro.getCell("B11")
                        cellB11_.value = dadoCliente.numero

                        //complemento
                        const cellA12_ = cadastro.getCell("A12")
                        cellA12_.value = "Complemento"
                        cellA12_.font = {
                            bold: true
                        }
                        cellA12_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB12_ = cadastro.getCell("B12")
                        cellB12_.value = dadoCliente.compl

                        //logradouro
                        const cellA13_ = cadastro.getCell("A13")
                        cellA13_.value = "Logradouro"
                        cellA13_.font = {
                            bold: true
                        }
                        cellA13_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB13_ = cadastro.getCell("B13")
                        cellB13_.value = dadoCliente.logradouro

                        //BAIRRO
                        const cellA14_ = cadastro.getCell("A14")
                        cellA14_.value = "Bairro"
                        cellA14_.font = {
                            bold: true
                        }
                        cellA14_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB14_ = cadastro.getCell("B14")
                        cellB14_.value = dadoCliente.bairro

                        //cidade
                        const cellA15_ = cadastro.getCell("A15")
                        cellA15_.value = "Cidade"
                        cellA15_.font = {
                            bold: true
                        }
                        cellA15_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB15_ = cadastro.getCell("B15")
                        cellB15_.value = dadoCliente.cidade

                        //Cód mun
                        const cellA16_ = cadastro.getCell("A16")
                        cellA16_.value = "Cód.Municipio"
                        cellA16_.font = {
                            bold: true
                        }
                        cellA16_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB16_ = cadastro.getCell("B16")
                        cellB16_.value = dadoCliente.cnum

                        //UF
                        const cellA17_ = cadastro.getCell("A17")
                        cellA17_.value = "Estado"
                        cellA17_.font = {
                            bold: true
                        }
                        cellA17_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB17_ = cadastro.getCell("B17")
                        cellB17_.value = dadoCliente.cuf

                        //Observações
                        const cellA18_ = cadastro.getCell("A18")
                        cellA18_.value = "Observações"
                        cellA18_.font = {
                            bold: true
                        }
                        cellA18_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB18_ = cadastro.getCell("B18")
                        cellB18_.value = dadoCliente.observacao

                        //cod cliente
                        const cellA19_ = cadastro.getCell("A19")
                        cellA19_.value = "Cód Cliente"
                        cellA19_.font = {
                            bold: true
                        }
                        cellA19_.border = {
                            right: {
                                style: "thin"
                            }
                        }
                        const cellB19_ = cadastro.getCell("B19")
                        cellB19_.value = dadoCliente.cod_cliente

                        //#region Analise de perfil
                        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/analisedeperfil/0/${dadoCliente.id_cliente}?mesanobase=${mesAnoBaseEscolhido}`, {
                            headers: {
                                Authorization: getSessionData("MultfilialWebToken")
                            }
                        }).then(async function (resposta) {
                            const analisedeperfil = workbook.addWorksheet("Analise de Perfil", { properties: { tabColor: { argb: '0000ff' } } })

                            if (resposta.data.analise_perfil.length == 0) {

                                toast.info("Não existe dados de Analise de Perfil para este cliente.")
                                set_carregando(false)
                                return
                            }

                            const A1 = analisedeperfil.getCell("B1")
                            A1.value = "ANALISE DE PERFIL"

                            const A2 = analisedeperfil.getCell("B2")
                            A2.value = dadoCliente.cod_cliente + " - " + dadoCliente.nome + " - " + dadoCliente.cpf

                            const columA = analisedeperfil.getColumn("A")
                            columA.width = 15
                            columA.alignment = {
                                vertical: 'bottom', horizontal: 'right'
                            }

                            const columB = analisedeperfil.getColumn("B")
                            columB.width = 45
                            columB.alignment = {
                                vertical: 'bottom', horizontal: 'right'
                            }

                            const columC = analisedeperfil.getColumn("C")
                            columC.width = 45
                            columC.alignment = {
                                vertical: 'bottom', horizontal: 'right'
                            }

                            const columD = analisedeperfil.getColumn("D")
                            columD.width = 55
                            columD.alignment = {
                                vertical: 'bottom', horizontal: 'right'
                            }

                            //fazendo o bg branco
                            for (let i = 1; i < 60; i = i + 1) {

                                analisedeperfil.getCell(`A${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`B${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`C${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`D${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`E${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`F${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`G${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`H${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`I${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`J${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`K${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`L${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`M${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`N${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`O${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                analisedeperfil.getCell(`P${[i]}`).fill = {

                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }
                            }


                            A1.font = {
                                bold: true,
                                size: 14
                            }

                            const rowB = analisedeperfil.getRow(2)
                            rowB.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFF' }
                            }
                            const rowC = analisedeperfil.getRow(3)
                            rowC.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFF' }
                            }

                            //cabecalho
                            const row4 = analisedeperfil.getRow(4)
                            row4.font = {
                                bold: true
                            }

                            const A4 = analisedeperfil.getCell("A4")
                            A4.value = "#"
                            A4.border = {
                                bottom: { style: "thin" }
                            }

                            const B4 = analisedeperfil.getCell("B4")
                            B4.value = "PERFIL GERAL"
                            B4.border = {
                                bottom: { style: "thin" }
                            }

                            const C4 = analisedeperfil.getCell("C4")
                            C4.value = "RESPOSTA"
                            C4.border = {
                                bottom: { style: "thin" }
                            }

                            const D4 = analisedeperfil.getCell("D4")
                            D4.value = "COMENTÁRIO"
                            D4.border = {
                                bottom: { style: "thin" }
                            }


                            //preenchendo informações
                            for (let i = 1; i <= 18; i = i + 1) {

                                let row = i + 4
                                let celB: any = ListaPerfilGeral.find(function (perfil: any) {

                                    return perfil.value == resposta.data.analise_perfil[0][i < 10 ? `codperfil_0${i}` : `codperfil_${i}`]
                                })

                                let celC: any = ListaResposta.find(function (resposta_: any) {

                                    return resposta_.value == resposta.data.analise_perfil[0][i < 10 ? `codresposta_0${i}` : `codresposta_${i}`]
                                })

                                analisedeperfil.getCell(`A${row}`).value = i
                                analisedeperfil.getCell(`B${row}`).value = celB != undefined ? celB.label : ""
                                analisedeperfil.getCell(`C${row}`).value = celC != undefined ? celC.label : ""
                                analisedeperfil.getCell(`D${row}`).value = resposta.data.analise_perfil[0][i < 10 ? `comentario_0${i}` : `comentario_${i}`] || ""
                            }
                            const cellB23 = analisedeperfil.getCell("B23")
                            cellB23.value = "Observações Gerais:"
                            cellB23.font = {
                                bold: true
                            }
                            const cellB24 = analisedeperfil.getCell("B24")
                            cellB24.value = resposta.data.analise_perfil[0].observacao
                            //#region analise de risco
                            axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/analisederisco/0/${dadoCliente.id_cliente}?mesanobase=${mesAnoBaseEscolhido}`, {
                                headers: {
                                    Authorization: getSessionData("MultfilialWebToken")
                                }
                            }).then(async function (resposta) {
                                const analisedeperfil = workbook.addWorksheet("Analise de Riscos", { properties: { tabColor: { argb: '0000ff' } } })
                                if (resposta.data.length == 0) {

                                    toast.info("Não existe dados de Analise de Risco para este cliente.")
                                    set_carregando(false)
                                    return
                                }
                                const A1 = analisedeperfil.getCell("B1")
                                A1.value = "ANALISE DE RISCOS"

                                const A2 = analisedeperfil.getCell("B2")
                                A2.value = dadoCliente.cod_cliente + " - " + dadoCliente.nome + " - " + dadoCliente.cpf

                                const columA = analisedeperfil.getColumn("A")
                                columA.width = 15
                                columA.alignment = {
                                    vertical: 'bottom', horizontal: 'right'
                                }

                                const columB = analisedeperfil.getColumn("B")
                                columB.width = 45
                                columB.alignment = {
                                    vertical: 'bottom', horizontal: 'right'
                                }

                                const columC = analisedeperfil.getColumn("C")
                                columC.width = 25
                                columC.alignment = {
                                    vertical: 'bottom', horizontal: 'right'
                                }

                                const columD = analisedeperfil.getColumn("D")
                                columD.width = 45
                                columD.alignment = {
                                    vertical: 'bottom', horizontal: 'right'
                                }

                                const columE = analisedeperfil.getColumn("E")
                                columE.width = 55
                                columE.alignment = {
                                    vertical: 'bottom', horizontal: 'right'
                                }

                                //fazendo o bg branco
                                for (let i = 1; i < 60; i = i + 1) {

                                    analisedeperfil.getCell(`A${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`B${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`C${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`D${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`E${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`F${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`G${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`H${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`I${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`J${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`K${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`L${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`M${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`N${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`O${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    analisedeperfil.getCell(`P${[i]}`).fill = {

                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }
                                }


                                A1.font = {
                                    bold: true,
                                    size: 14
                                }

                                const rowB = analisedeperfil.getRow(2)
                                rowB.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }
                                const rowC = analisedeperfil.getRow(3)
                                rowC.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFF' }
                                }

                                //cabecalho
                                const row4 = analisedeperfil.getRow(4)
                                row4.font = {
                                    bold: true
                                }

                                const A4 = analisedeperfil.getCell("A4")
                                A4.value = "#"
                                A4.border = {
                                    bottom: { style: "thin" }
                                }

                                const B4 = analisedeperfil.getCell("B4")
                                B4.value = "EVENTO - CLIENTE"
                                B4.border = {
                                    bottom: { style: "thin" }
                                }

                                const C4 = analisedeperfil.getCell("C4")
                                C4.value = "TEM SEGURO?"
                                C4.border = {
                                    bottom: { style: "thin" }
                                }

                                const D4 = analisedeperfil.getCell("D4")
                                D4.value = "SEGURADORA"
                                D4.border = {
                                    bottom: { style: "thin" }
                                }

                                const E4 = analisedeperfil.getCell("E4")
                                E4.value = "COMENTÁRIO"
                                E4.border = {
                                    bottom: { style: "thin" }
                                }

                                //preenchendo informações
                                for (let i = 1; i <= 14; i = i + 1) {

                                    let row = i + 4
                                    let celB: any = ListaEvento.find(function (evento: any) {

                                        return evento.value == resposta.data[0][i <= 7 ? `codevento_cliente_0${i}` : `codevento_comp_0${i - 7}`]
                                    })
                                    analisedeperfil.getCell(`A${row}`).value = i
                                    analisedeperfil.getCell(`B${row}`).value = celB != undefined ? celB.label : ""
                                    analisedeperfil.getCell(`C${row}`).value = resposta.data[0][i <= 7 ? `codseguradora_cliente_0${i}` : `codseguradora_comp_0${i - 7}`]
                                    analisedeperfil.getCell(`D${row}`).value = resposta.data[0][i <= 7 ? `nomeseguradora${i}` : `nomeseguradora_comp${i - 7}`]
                                    analisedeperfil.getCell(`E${row}`).value = resposta.data[0][i <= 7 ? `custocliente_0${i}` : `custocomp_0${i - 7}`]
                                }
                                const cellB20 = analisedeperfil.getCell("B20")
                                cellB20.value = "Observações Gerais:"
                                cellB20.font = {
                                    bold: true
                                }
                                const cellB21 = analisedeperfil.getCell("B21")
                                cellB21.value = resposta.data[0].observacao

                                //comvert parae excel
                                //#region Balanço Patrimonial
                                axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/informa/balanco/${dadoCliente.id_cliente}`, {
                                    headers: {
                                        Authorization: getSessionData("MultfilialWebToken")!
                                    }
                                }).then(async function (resposta) {
                                    const worksheet = workbook.addWorksheet("Balanço Patrimonial", { properties: { tabColor: { argb: '0000ff' } } })

                                    if (resposta.data.info.orgfin_balancoativo.length == 0) {
                                        toast.error("Cliente não possuí Balanço Patrimonial criado.")
                                        return
                                    }

                                    let ativo_01: any = resposta.data.info.orgfin_balancoativo[0]["01_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["01_Ativo"]
                                        })
                                        : ""

                                    let ativo_02: any = resposta.data.info.orgfin_balancoativo[0]["02_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["02_Ativo"]
                                        })
                                        : ""


                                    let ativo_03: any = resposta.data.info.orgfin_balancoativo[0]["03_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["03_Ativo"]
                                        })
                                        : ""

                                    let ativo_04: any = resposta.data.info.orgfin_balancoativo[0]["04_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["04_Ativo"]
                                        })
                                        : ""

                                    let ativo_05: any = resposta.data.info.orgfin_balancoativo[0]["05_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["05_Ativo"]
                                        })
                                        : ""

                                    let ativo_06: any = resposta.data.info.orgfin_balancoativo[0]["06_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["06_Ativo"]
                                        })
                                        : ""

                                    let ativo_07: any = resposta.data.info.orgfin_balancoativo[0]["07_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["07_Ativo"]
                                        })
                                        : ""

                                    let ativo_08: any = resposta.data.info.orgfin_balancoativo[0]["08_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["08_Ativo"]
                                        })
                                        : ""

                                    let ativo_09: any = resposta.data.info.orgfin_balancoativo[0]["09_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["09_Ativo"]
                                        })
                                        : ""

                                    let ativo_10: any = resposta.data.info.orgfin_balancoativo[0]["10_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["10_Ativo"]
                                        })
                                        : ""

                                    let ativo_11: any = resposta.data.info.orgfin_balancoativo[0]["11_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["11_Ativo"]
                                        })
                                        : ""

                                    let ativo_12: any = resposta.data.info.orgfin_balancoativo[0]["12_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["12_Ativo"]
                                        })
                                        : ""

                                    let ativo_13: any = resposta.data.info.orgfin_balancoativo[0]["13_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["13_Ativo"]
                                        })
                                        : ""

                                    let ativo_14: any = resposta.data.info.orgfin_balancoativo[0]["14_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["14_Ativo"]
                                        })
                                        : ""
                                    let ativo_15: any = resposta.data.info.orgfin_balancoativo[0]["15_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["15_Ativo"]
                                        })
                                        : ""

                                    let ativo_16: any = resposta.data.info.orgfin_balancoativo[0]["16_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["16_Ativo"]
                                        })
                                        : ""

                                    let ativo_17: any = resposta.data.info.orgfin_balancoativo[0]["17_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["17_Ativo"]
                                        })
                                        : ""

                                    let ativo_18: any = resposta.data.info.orgfin_balancoativo[0]["18_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["18_Ativo"]
                                        })
                                        : ""

                                    let ativo_19: any = resposta.data.info.orgfin_balancoativo[0]["19_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["19_Ativo"]
                                        })
                                        : ""

                                    let ativo_20: any = resposta.data.info.orgfin_balancoativo[0]["20_Ativo"] != "" ?
                                        ListaAtivos.find(function (ativo: any) {

                                            return ativo.value == resposta.data.info.orgfin_balancoativo[0]["20_Ativo"]
                                        })
                                        : ""

                                    let passivo_01: any = resposta.data.info.orgfin_balancopassivo[0]["01_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["01_passivo"]
                                        })
                                        : ""

                                    let passivo_02: any = resposta.data.info.orgfin_balancopassivo[0]["02_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["02_passivo"]
                                        })
                                        : ""


                                    let passivo_03: any = resposta.data.info.orgfin_balancopassivo[0]["03_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["03_passivo"]
                                        })
                                        : ""

                                    let passivo_04: any = resposta.data.info.orgfin_balancopassivo[0]["04_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["04_passivo"]
                                        })
                                        : ""

                                    let passivo_05: any = resposta.data.info.orgfin_balancopassivo[0]["05_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["05_passivo"]
                                        })
                                        : ""

                                    let passivo_06: any = resposta.data.info.orgfin_balancopassivo[0]["06_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["06_passivo"]
                                        })
                                        : ""

                                    let passivo_07: any = resposta.data.info.orgfin_balancopassivo[0]["07_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["07_passivo"]
                                        })
                                        : ""

                                    let passivo_08: any = resposta.data.info.orgfin_balancopassivo[0]["08_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["08_passivo"]
                                        })
                                        : ""

                                    let passivo_09: any = resposta.data.info.orgfin_balancopassivo[0]["09_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["09_passivo"]
                                        })
                                        : ""

                                    let passivo_10: any = resposta.data.info.orgfin_balancopassivo[0]["10_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["10_passivo"]
                                        })
                                        : ""

                                    let passivo_11: any = resposta.data.info.orgfin_balancopassivo[0]["11_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["11_passivo"]
                                        })
                                        : ""

                                    let passivo_12: any = resposta.data.info.orgfin_balancopassivo[0]["12_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["12_passivo"]
                                        })
                                        : ""

                                    let passivo_13: any = resposta.data.info.orgfin_balancopassivo[0]["13_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["13_passivo"]
                                        })
                                        : ""

                                    let passivo_14: any = resposta.data.info.orgfin_balancopassivo[0]["14_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["14_passivo"]
                                        })
                                        : ""
                                    let passivo_15: any = resposta.data.info.orgfin_balancopassivo[0]["15_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["15_passivo"]
                                        })
                                        : ""

                                    let passivo_16: any = resposta.data.info.orgfin_balancopassivo[0]["16_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["16_passivo"]
                                        })
                                        : ""

                                    let passivo_17: any = resposta.data.info.orgfin_balancopassivo[0]["17_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["17_passivo"]
                                        })
                                        : ""

                                    let passivo_18: any = resposta.data.info.orgfin_balancopassivo[0]["18_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["18_passivo"]
                                        })
                                        : ""

                                    let passivo_19: any = resposta.data.info.orgfin_balancopassivo[0]["19_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["19_passivo"]
                                        })
                                        : ""

                                    let passivo_20: any = resposta.data.info.orgfin_balancopassivo[0]["20_passivo"] != "" ?
                                        Listapassivos.find(function (passivo: any) {

                                            return passivo.value == resposta.data.info.orgfin_balancopassivo[0]["20_passivo"]
                                        })
                                        : ""

                                    const A1 = worksheet.getCell("A1")
                                    A1.value = "BALANÇO PATRIMONIAL"

                                    const A2 = worksheet.getCell("A2")
                                    A2.value = dadoCliente.cod_cliente + " - " + dadoCliente.nome + " - " + dadoCliente.cpf

                                    const columA = worksheet.getColumn("A")
                                    columA.width = 45

                                    const columB = worksheet.getColumn("B")
                                    columB.width = 25

                                    const columC = worksheet.getColumn("C")
                                    columC.width = 35

                                    //fazendo o bg franco
                                    for (let i = 1; i < 60; i = i + 1) {

                                        worksheet.getCell(`A${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`B${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`C${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`D${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`E${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`F${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`G${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`H${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`I${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`J${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`K${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`L${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`M${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`N${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`O${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }

                                        worksheet.getCell(`P${[i]}`).fill = {

                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: 'FFFFFF' }
                                        }
                                    }

                                    A1.font = {
                                        bold: true,
                                        size: 14
                                    }

                                    const rowB = worksheet.getRow(2)
                                    rowB.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }
                                    const rowC = worksheet.getRow(3)
                                    rowC.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFFFFF' }
                                    }

                                    //preenchendo cabecalho - ATIVO
                                    const A4 = worksheet.getCell("A4")
                                    A4.border =
                                    {
                                        bottom: { style: 'thin' }
                                    }
                                    A4.value = "ATIVO"
                                    A4.font = {

                                        bold: true
                                    }
                                    A4.alignment = {
                                        vertical: 'middle', horizontal: 'center'
                                    }

                                    const B4 = worksheet.getCell("B4")
                                    B4.border =
                                    {
                                        bottom: { style: 'thin' }
                                    }
                                    B4.value = "VALOR"
                                    B4.font = {

                                        bold: true
                                    }
                                    B4.alignment = {
                                        vertical: 'middle', horizontal: 'center'
                                    }

                                    const C4 = worksheet.getCell("C4")
                                    C4.border =
                                    {
                                        bottom: { style: 'thin' }
                                    }
                                    C4.value = "DESCRIÇÃO"
                                    C4.font = {

                                        bold: true
                                    }
                                    C4.alignment = {
                                        vertical: 'middle', horizontal: 'center'
                                    }


                                    //looping para preenchimento dos ativos
                                    const ativos = []

                                    for (let i = 1; i <= 20; i = i + 1) {

                                        ativos.push({
                                            ativo: eval(`ativo_${i.toString().padStart(2, "0")}`),
                                            valor: resposta.data.info.orgfin_balancoativo[0][`${i.toString().padStart(2, "0")}_valor`],
                                            descricao: resposta.data.info.orgfin_balancoativo[0][`${i.toString().padStart(2, "0")}_descricao`]
                                        })
                                    }

                                    for (let i = 5; i < ativos.length + 5; i = i + 1) {

                                        let celA = worksheet.getCell(`A${i}`)
                                        celA.value = ativos[i - 5].ativo.label
                                        celA.alignment =
                                        {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }

                                        worksheet.getCell(`B${i}`).value = parseFloat(ativos[i - 5].valor)

                                        let celC = worksheet.getCell(`C${i}`)
                                        celC.value = ativos[i - 5].descricao
                                        celC.alignment =
                                        {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }

                                    const A27 = worksheet.getCell("A27")
                                    A27.value = "Observações Gerais - Ativos"
                                    A27.font = {
                                        bold: true
                                    }
                                    const A28 = worksheet.getCell("A28")
                                    A28.value = resposta.data.info.orgfin_balancoativo[0].observacao

                                    //preenchendo cabecalho - PASSIVO
                                    const A30 = worksheet.getCell("A30")
                                    A30.border =
                                    {
                                        bottom: { style: 'thin' }
                                    }
                                    A30.value = "PASSIVO"
                                    A30.font = {

                                        bold: true
                                    }
                                    A30.alignment = {
                                        vertical: 'middle', horizontal: 'center'
                                    }

                                    const B30 = worksheet.getCell("B30")
                                    B30.border =
                                    {
                                        bottom: { style: 'thin' }
                                    }
                                    B30.value = "VALOR"
                                    B30.font = {

                                        bold: true
                                    }
                                    B30.alignment = {
                                        vertical: 'middle', horizontal: 'center'
                                    }

                                    const C30 = worksheet.getCell("C30")
                                    C30.border =
                                    {
                                        bottom: { style: 'thin' }
                                    }
                                    C30.value = "DESCRIÇÃO"
                                    C30.font = {

                                        bold: true
                                    }
                                    C30.alignment = {
                                        vertical: 'middle', horizontal: 'center'
                                    }


                                    //looping para preenchimento dos passsivos
                                    const passivos = []

                                    for (let i = 1; i <= 20; i = i + 1) {

                                        passivos.push({
                                            passivo: eval(`passivo_${i.toString().padStart(2, "0")}`),
                                            valor: resposta.data.info.orgfin_balancopassivo[0][`${i.toString().padStart(2, "0")}_valor`],
                                            descricao: resposta.data.info.orgfin_balancopassivo[0][`${i.toString().padStart(2, "0")}_descricao`]
                                        })
                                    }

                                    for (let i = 31; i < passivos.length + 31; i = i + 1) {

                                        let celA = worksheet.getCell(`A${i}`)
                                        celA.value = passivos[i - 31].passivo.label
                                        celA.alignment =
                                        {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }

                                        worksheet.getCell(`B${i}`).value = parseFloat(passivos[i - 31].valor)

                                        let celC = worksheet.getCell(`C${i}`)
                                        celC.value = passivos[i - 31].descricao
                                        celC.alignment =
                                        {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }

                                    const A51 = worksheet.getCell("A51")
                                    A51.value = "Observações Gerais - Passivos"
                                    A51.font = {
                                        bold: true
                                    }
                                    const A52 = worksheet.getCell("A52")
                                    A52.value = resposta.data.info.orgfin_balancopassivo[0].observacao
                                    //#region Independencia Financeira
                                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/indep/mesanobase/${mesAnoBaseEscolhido}/${idCliente}`, {
                                        headers: {
                                            Authorization: sessionStorage.getItem("MultfilialWebToken")!
                                        }
                                    }).then(async function (indep) {
                                        if (indep.data.independencia.length == 0) {
                                            toast.error("Indepência Financeira não criada para este mes/ano base.")
                                            set_carregando(false)
                                            return
                                        }
                                        else {
                                            const independencia = indep.data.independencia
                                            const worksheetIndep = workbook.addWorksheet("Independência Financeira", { properties: { tabColor: { argb: '0000ff' } } })


                                            //fazendo o bg branco
                                            for (let i = 1; i < 60; i = i + 1) {

                                                worksheetIndep.getCell(`A${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`B${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`C${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`D${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`E${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`F${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`G${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`H${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`I${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`J${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`K${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`L${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`M${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`N${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`O${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetIndep.getCell(`P${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }
                                            }

                                            const columA = worksheetIndep.getColumn("A")
                                            columA.width = 55
                                            const columB = worksheetIndep.getColumn("B")
                                            columB.width = 20
                                            const columC = worksheetIndep.getColumn("C")
                                            columC.width = 35
                                            const celA1 = worksheetIndep.getCell("A1")
                                            celA1.alignment = { vertical: 'middle', horizontal: 'center' }
                                            celA1.border = {
                                                bottom: {
                                                    style: "thin"
                                                }
                                            }
                                            celA1.font = {
                                                bold: true
                                            }
                                            celA1.value = "Projeções de Independência Financeira Plena"

                                            const celA2 = worksheetIndep.getCell("A2")
                                            celA2.value = "Renda futura almejada em valor presente"
                                            celA2.alignment = { vertical: "middle", horizontal: "right" }

                                            const celB2 = worksheetIndep.getCell("B2")
                                            celB2.value = independencia[0].rendafutura || ""
                                            celB2.alignment = { vertical: "middle", horizontal: "center" }

                                            const celA3 = worksheetIndep.getCell("A3")
                                            celA3.value = "Idade Atual"
                                            celA3.alignment = { vertical: "middle", horizontal: "right" }

                                            const celB3 = worksheetIndep.getCell("B3")
                                            celB3.value = parseInt(independencia[0].idadeAtual) || ""
                                            celB3.alignment = { vertical: "middle", horizontal: "center" }

                                            const celC3 = worksheetIndep.getCell("C3")
                                            celC3.value = independencia[0].coment_07 || ""
                                            celC3.alignment = { vertical: "middle", horizontal: "center" }

                                            const celA4 = worksheetIndep.getCell("A4")
                                            celA4.value = "Idade Alvo"
                                            celA4.alignment = { vertical: "middle", horizontal: "right" }

                                            const celB4 = worksheetIndep.getCell("B4")
                                            celB4.value = independencia[0].idadealvo || ""
                                            celB4.alignment = { vertical: "middle", horizontal: "center" }


                                            const celA6 = worksheetIndep.getCell("A6")
                                            celA6.alignment = { vertical: 'middle', horizontal: 'center' }
                                            celA6.border = {
                                                bottom: {
                                                    style: "thin"
                                                }
                                            }
                                            celA6.font = {
                                                bold: true
                                            }
                                            celA6.value = "Previsão de entradas futuras em valor presente"

                                            const celA7 = worksheetIndep.getCell("A7")
                                            celA7.value = "Previdência Social (INSS)"
                                            celA7.alignment = { vertical: "middle", horizontal: "right" }

                                            const celB7 = worksheetIndep.getCell("B7")
                                            celB7.value = independencia[0].prevsocial || ""
                                            celB7.alignment = { vertical: "middle", horizontal: "center" }

                                            const celC7 = worksheetIndep.getCell("C7")
                                            celC7.value = independencia[0].coment_02 || ""
                                            celC7.alignment = { vertical: "middle", horizontal: "center" }

                                            const celA8 = worksheetIndep.getCell("A8")
                                            celA8.value = "Previdência Complementar"
                                            celA8.alignment = { vertical: "middle", horizontal: "right" }


                                            const celB8 = worksheetIndep.getCell("B8")
                                            celB8.value = independencia[0].prevcompl || ""
                                            celB8.alignment = { vertical: "middle", horizontal: "center" }

                                            const celC8 = worksheetIndep.getCell("C8")
                                            celC8.value = independencia[0].coment_03 || ""
                                            celC8.alignment = { vertical: "middle", horizontal: "center" }

                                            const celA9 = worksheetIndep.getCell("A9")
                                            celA9.value = "Aluguéis"
                                            celA9.alignment = { vertical: "middle", horizontal: "right" }

                                            const celB9 = worksheetIndep.getCell("B9")
                                            celB9.value = independencia[0].alugueis || ""
                                            celB9.alignment = { vertical: "middle", horizontal: "center" }

                                            const celC9 = worksheetIndep.getCell("C9")
                                            celC9.value = independencia[0].coment_04 || ""
                                            celC9.alignment = { vertical: "middle", horizontal: "center" }

                                            const cel10 = worksheetIndep.getCell("A10")
                                            cel10.value = "Dividendos"
                                            cel10.alignment = { vertical: "middle", horizontal: "right" }

                                            const celB10 = worksheetIndep.getCell("B10")
                                            celB10.value = independencia[0].dividendos || ""
                                            celB10.alignment = { vertical: "middle", horizontal: "center" }

                                            const celC10 = worksheetIndep.getCell("C10")
                                            celC10.value = independencia[0].coment_05 || ""
                                            celC10.alignment = { vertical: "middle", horizontal: "center" }

                                            const cel11 = worksheetIndep.getCell("A11")
                                            cel11.value = "Outras entradas"
                                            cel11.alignment = { vertical: "middle", horizontal: "right" }

                                            const celB11 = worksheetIndep.getCell("B11")
                                            celB11.value = independencia[0].outrasentradas || ""
                                            celB11.alignment = { vertical: "middle", horizontal: "center" }


                                            const celC11 = worksheetIndep.getCell("C11")
                                            celC11.value = independencia[0].coment_06 || ""
                                            celC11.alignment = { vertical: "middle", horizontal: "center" }

                                            const celA12 = worksheetIndep.getCell("A12")
                                            celA12.value = "Total"
                                            celA12.alignment = { vertical: "middle", horizontal: "right" }

                                            const celA14 = worksheetIndep.getCell("A14")
                                            celA14.value = "Observações Gerais"
                                            celA14.alignment = { vertical: "middle", horizontal: "center" }
                                            celA14.font = {
                                                bold: true
                                            }

                                            const celA15 = worksheetIndep.getCell("A15")
                                            celA15.alignment = { horizontal: "center" }
                                            celA15.value = independencia[0].observacao

                                            const columD = worksheetIndep.getColumn("D")
                                            columD.width = 55
                                            const celD18 = worksheetIndep.getCell("D18")
                                            celD18.font = {
                                                bold: true,
                                                size: 15
                                            }
                                            celD18.alignment = { vertical: "middle", horizontal: "right" }
                                            celD18.value = dadoCliente.nome + " - " + dadoCliente.cpf + " - " + dadoCliente.cod_cliente
                                        }
                                        //#region Sucessão Cliente
                                        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/sucessao/0/${dadoCliente.id_cliente}?mesanobase=${mesAnoBaseEscolhido}`, {
                                            headers: {
                                                Authorization: getSessionData("MultfilialWebToken")
                                            }
                                        }).then(async function (resposta) {
                                            const worksheetSucessao = workbook.addWorksheet("Sucessão", { properties: { tabColor: { argb: '0000ff' } } })

                                            const A1 = worksheetSucessao.getCell("B1")
                                            A1.value = "SUCESSÃO"

                                            const A2 = worksheetSucessao.getCell("B2")
                                            A2.value = dadoCliente.cod_cliente + " - " + dadoCliente.nome + " - " + dadoCliente.cpf

                                            const columA = worksheetSucessao.getColumn("A")
                                            columA.width = 35
                                            columA.alignment = {
                                                vertical: 'bottom', horizontal: 'right'
                                            }

                                            const columB = worksheetSucessao.getColumn("B")
                                            columB.width = 45
                                            columB.alignment = {
                                                vertical: 'bottom', horizontal: 'right'
                                            }

                                            const columC = worksheetSucessao.getColumn("C")
                                            columC.width = 35
                                            columC.alignment = {
                                                vertical: 'bottom', horizontal: 'right'
                                            }

                                            const columD = worksheetSucessao.getColumn("D")
                                            columD.width = 35
                                            columD.alignment = {
                                                vertical: 'bottom', horizontal: 'right'
                                            }

                                            const columE = worksheetSucessao.getColumn("E")
                                            columE.width = 35
                                            columE.alignment = {
                                                vertical: 'bottom', horizontal: 'right'
                                            }


                                            //fazendo o bg branco
                                            for (let i = 1; i < 60; i = i + 1) {

                                                worksheetSucessao.getCell(`A${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`B${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`C${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`D${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`E${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`F${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`G${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`H${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`I${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`J${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`K${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`L${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`M${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`N${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`O${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }

                                                worksheetSucessao.getCell(`P${[i]}`).fill = {

                                                    type: 'pattern',
                                                    pattern: 'solid',
                                                    fgColor: { argb: 'FFFFFF' }
                                                }
                                            }


                                            A1.font = {
                                                bold: true,
                                                size: 14
                                            }

                                            const rowB = worksheetSucessao.getRow(2)
                                            rowB.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: { argb: 'FFFFFF' }
                                            }
                                            const rowC = worksheetSucessao.getRow(3)
                                            rowC.fill = {
                                                type: 'pattern',
                                                pattern: 'solid',
                                                fgColor: { argb: 'FFFFFF' }
                                            }


                                            if (resposta.data.length == 0) {
                                                toast.error("Sucessão não criada para este cliente.")
                                                set_carregando(false)
                                                return
                                            }

                                            const estCivil: any = ListaEstadoCivil.find(function (estado: any) {
                                                if (dadoCliente.estado_civil != null) {
                                                    return estado.value == dadoCliente.estado_civil
                                                }
                                                else {
                                                    return null
                                                }
                                            })
                                            const estCivilComp: any = ListaEstadoCivil.find(function (estado: any) {
                                                if (resposta.data[0].estadocivilcomp != null) {
                                                    return estado.value == resposta.data[0].estadocivilcomp
                                                }
                                                else {
                                                    return null
                                                }
                                            })

                                            //cabecalho - 1
                                            const cellA4 = worksheetSucessao.getCell("A4")
                                            cellA4.value = "Premissas"
                                            cellA4.font = {
                                                bold: true
                                            }
                                            cellA4.border = {
                                                bottom: {
                                                    style: "thin"
                                                }
                                            }

                                            const cellB4 = worksheetSucessao.getCell("B4")
                                            cellB4.value = "Cliente"
                                            cellB4.font = {
                                                bold: true
                                            }
                                            cellB4.border = {
                                                bottom: {
                                                    style: "thin"
                                                }
                                            }

                                            const cellC4 = worksheetSucessao.getCell("C4")
                                            cellC4.value = "Companheiro(a)"
                                            cellC4.font = {
                                                bold: true
                                            }
                                            cellC4.border = {
                                                bottom: {
                                                    style: "thin"
                                                }
                                            }

                                            const cellA5 = worksheetSucessao.getCell("A5")
                                            cellA5.value = "Estado Civil"
                                            const cellB5 = worksheetSucessao.getCell("B5")
                                            cellB5.value = estCivil == null ? "" : estCivil!.label
                                            const cellC5 = worksheetSucessao.getCell("C5")
                                            cellC5.value = estCivilComp == null ? "" : estCivilComp!.label


                                            const cellA6 = worksheetSucessao.getCell("A6")
                                            cellA6.value = "Numero de filhos comuns"
                                            const cellB6 = worksheetSucessao.getCell("B6")
                                            cellB6.value = resposta.data[0].numfilhoscomum_cliente
                                            const cellC6 = worksheetSucessao.getCell("C6")
                                            cellC6.value = resposta.data[0].numfilhoscomum_comp

                                            const cellA7 = worksheetSucessao.getCell("A7")
                                            cellA7.value = "Numero de filhos particulares"
                                            const cellB7 = worksheetSucessao.getCell("B7")
                                            cellB7.value = resposta.data[0].numfilhospart_cliente
                                            const cellC7 = worksheetSucessao.getCell("C7")
                                            cellC7.value = resposta.data[0].numfilhospart_comp

                                            const cellA8 = worksheetSucessao.getCell("A8")
                                            cellA8.value = "Mãe"
                                            const cellB8 = worksheetSucessao.getCell("B8")
                                            cellB8.value = resposta.data[0].maeviva_cliente == 1 ? "Sim" : "Não"
                                            const cellC8 = worksheetSucessao.getCell("C8")
                                            cellC8.value = resposta.data[0].nmaeviva_comp == 1 ? "Sim" : "Não"

                                            const cellA9 = worksheetSucessao.getCell("A9")
                                            cellA9.value = "Pai"
                                            const cellB9 = worksheetSucessao.getCell("B9")
                                            cellB9.value = resposta.data[0].paivivo_cliente == 1 ? "Sim" : "Não"
                                            const cellC9 = worksheetSucessao.getCell("C9")
                                            cellC9.value = resposta.data[0].paivivo_comp == 1 ? "Sim" : "Não"

                                            //cabecalho 2
                                            const cellA11 = worksheetSucessao.getCell("A11")
                                            cellA11.value = "DEPENDENTE"
                                            cellA11.border = {
                                                "bottom": {
                                                    "style": "thin"
                                                }
                                            }

                                            const cellB11 = worksheetSucessao.getCell("B11")
                                            cellB11.value = "DATA DE NASCIMENTO"
                                            cellB11.border = {
                                                "bottom": {
                                                    "style": "thin"
                                                }
                                            }

                                            const cellC11 = worksheetSucessao.getCell("C11")
                                            cellC11.value = "NACIONALIDADE"
                                            cellC11.border = {
                                                "bottom": {
                                                    "style": "thin"
                                                }
                                            }

                                            const cellD11 = worksheetSucessao.getCell("D11")
                                            cellD11.value = "ESTADO CIVIL"
                                            cellD11.border = {
                                                "bottom": {
                                                    "style": "thin"
                                                }
                                            }

                                            const cellE11 = worksheetSucessao.getCell("E11")
                                            cellE11.value = "PARENTESCO"
                                            cellE11.border = {
                                                "bottom": {
                                                    "style": "thin"
                                                }
                                            }

                                            for (let i = 1; i < 5; i = i + 1) {

                                                let row = i + 11
                                                const dataNascimento = resposta.data[0][i < 10 ? `dep0${i}_nascimento` : `dep0${i}_nascimento`]
                                                    != null ? resposta.data[0][i < 10 ? `dep0${i}_nascimento` : `dep0${i}_nascimento`].split("T")[0].split("-") : ""
                                                const dataNascimentoFormatada = dataNascimento.length > 0 ? (dataNascimento[2] + '-' + dataNascimento[1] + "-" + dataNascimento[0]).replaceAll("-", "/") : dataNascimento

                                                worksheetSucessao.getCell(`A${row}`).value = resposta.data[0][i < 10 ? `dep0${i}_nome` : `dep${i}_nome`]
                                                worksheetSucessao.getCell(`B${row}`).value = dataNascimentoFormatada

                                                const nacionalidade: any = ListaNacionalidade.find(function (nacionalidade: any) {

                                                    return nacionalidade.value == resposta.data[0][i < 10 ? `dep0${i}_nacionalidade` : `dep${i}_nacionalidade`]
                                                })

                                                worksheetSucessao.getCell(`C${row}`).value = nacionalidade ? nacionalidade.label : ""

                                                const EstadoCivil: any = ListaEstadoCivil.find(function (estado: any) {

                                                    return estado.value == resposta.data[0][i < 10 ? `dep0${i}_estadocivil` : `dep${i}_estadocivil`]
                                                })

                                                worksheetSucessao.getCell(`D${row}`).value = EstadoCivil ? EstadoCivil.label : ""

                                                const parente: any = ListaParentesco.find(function (parente: any) {

                                                    return parente.value == resposta.data[0][i < 10 ? `dep0${i}_grauparentesco` : `dep${i}_grauparentesco`]
                                                })

                                                worksheetSucessao.getCell(`E${row}`).value = parente ? parente.label : ""
                                            }
                                            const cellB18 = worksheetSucessao.getCell("B18")
                                            cellB18.value = "Observações Gerais:"
                                            cellB18.font = {
                                                bold: true
                                            }
                                            const cellB19 = worksheetSucessao.getCell("B19")
                                            cellB19.value = resposta.data[0].observacao
                                            //#region projeção de ativos
                                            axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/previdencia/unica/mesanobase/${mesAnoBaseEscolhido}/${idCliente}`, {
                                                headers: {
                                                    Authorization: sessionStorage.getItem("MultfilialWebToken")!
                                                }
                                            }).then(async function (prev) {
                                                if (prev.data.previdencia.length == 0) {
                                                    set_carregando(false)
                                                    toast.error("Previdência não criada para este mês/ano base")
                                                    return
                                                }
                                                else {

                                                    const previdencia = prev.data.previdencia[0]

                                                    const worksheetPrevidencia = workbook.addWorksheet("Projeção de Ativos", { properties: { tabColor: { argb: '0000ff' } } })
                                                    //fazendo o bg branco
                                                    for (let i = 1; i < 60; i = i + 1) {

                                                        worksheetPrevidencia.getCell(`A${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`B${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`C${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`D${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`E${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`F${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`G${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`H${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`I${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`J${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`K${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`L${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`M${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`N${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`O${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }

                                                        worksheetPrevidencia.getCell(`P${[i]}`).fill = {

                                                            type: 'pattern',
                                                            pattern: 'solid',
                                                            fgColor: { argb: 'FFFFFF' }
                                                        }
                                                    }
                                                    const columA = worksheetPrevidencia.getColumn("A")
                                                    columA.width = 50
                                                    const cellA1 = worksheetPrevidencia.getCell("A1")
                                                    cellA1.font = {
                                                        bold: true
                                                    }
                                                    cellA1.value = dadoCliente.nome + " - " + dadoCliente.cpf + " - " + dadoCliente.cod_cliente

                                                    const columF = worksheetPrevidencia.getColumn("F")
                                                    columF.width = 40

                                                    const cellA3 = worksheetPrevidencia.getCell("A3")
                                                    cellA3.font = {
                                                        bold: true
                                                    }
                                                    cellA3.alignment = {
                                                        "horizontal": "right",
                                                        "vertical": "middle"
                                                    }
                                                    cellA3.border = {
                                                        bottom: {
                                                            "style": "thin"
                                                        }
                                                    }
                                                    cellA3.value = "Projeção de Ativos Financeiros"

                                                    const cellA11 = worksheetPrevidencia.getCell("A11")
                                                    cellA11.alignment = {
                                                        "horizontal": "right",
                                                        "vertical": "middle"
                                                    }
                                                    cellA11.value = "Custo de vida Mensal/extras anuais/Anual"

                                                    const cellC11 = worksheetPrevidencia.getCell("C11")
                                                    cellC11.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellC11.value = previdencia.custovidamensal

                                                    const cellD11 = worksheetPrevidencia.getCell("D11")
                                                    cellD11.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellD11.value = previdencia.extrasanuais

                                                    const cellE11 = worksheetPrevidencia.getCell("E11")
                                                    cellE11.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellE11.value = previdencia.custovidaanual

                                                    const cellF11 = worksheetPrevidencia.getCell("F11")
                                                    cellF11.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellF11.value = previdencia.coment_01

                                                    const cellA12 = worksheetPrevidencia.getCell("A12")
                                                    cellA12.alignment = {
                                                        "horizontal": "right",
                                                        "vertical": "middle"
                                                    }
                                                    cellA12.value = "Contribui com a previdência?"

                                                    const cellB12 = worksheetPrevidencia.getCell("B12")
                                                    cellB12.value = previdencia.prevcorp == 1 ? "Sim" : "Não"
                                                    cellB12.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }

                                                    const cellA13 = worksheetPrevidencia.getCell("A13")
                                                    cellA13.alignment = {
                                                        "horizontal": "right",
                                                        "vertical": "middle"
                                                    }
                                                    cellA13.value = "Participante"

                                                    const cellB13 = worksheetPrevidencia.getCell("B13")
                                                    cellB13.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellB13.value = previdencia.vlrparticipante || ""

                                                    const cellF13 = worksheetPrevidencia.getCell("F13")
                                                    cellF13.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellF13.value = previdencia.coment_02

                                                    const cellA14 = worksheetPrevidencia.getCell("A14")
                                                    cellA14.alignment = {
                                                        "horizontal": "right",
                                                        "vertical": "middle"
                                                    }
                                                    cellA14.value = "Patrocinadora"

                                                    const cellB14 = worksheetPrevidencia.getCell("B14")
                                                    cellB14.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellB14.value = previdencia.vlrpatrocinador || ""

                                                    const cellF14 = worksheetPrevidencia.getCell("F14")
                                                    cellF14.alignment = {
                                                        "horizontal": "center",
                                                        "vertical": "middle"
                                                    }
                                                    cellF14.value = previdencia.coment_03

                                                    const cellA16 = worksheetPrevidencia.getCell("A16")
                                                    cellA16.value = "Observações gerais"
                                                    cellA16.font = {
                                                        bold: true
                                                    }

                                                    const cellA17 = worksheetPrevidencia.getCell("A17")
                                                    cellA17.value = previdencia.observacao

                                                    const row17 = worksheetPrevidencia.getRow(17)
                                                    row17.height = 35

                                                    const columE = worksheetPrevidencia.getColumn("B")
                                                    columE.width = 25

                                                    //preenchendo os valores de renda ativa e passiva
                                                    for (let i = 1; i < 7; i = i + 1) {
                                                        const cellA = worksheetPrevidencia.getCell(`A${i + 3}`)
                                                        cellA.alignment = {
                                                            "horizontal": "right",
                                                            "vertical": "middle"
                                                        }
                                                        cellA.value = 'Renda Ativa Mensal/extras anuais/Anual'
                                                        //encontra o label da renda ativaa
                                                        const rendaAtiva: any = listaRendaAtiva.find(function (rendaAtiva: any) {
                                                            return rendaAtiva.value == previdencia[`rendaativa${i}`]
                                                        })
                                                        const cellB = worksheetPrevidencia.getCell(`B${i + 3}`)
                                                        cellB.alignment = {
                                                            horizontal: "center", vertical: "middle"
                                                        }
                                                        cellB.value = rendaAtiva?.label || ""
                                                        //preenche os valores
                                                        const cellC = worksheetPrevidencia.getCell(`C${i + 3}`)
                                                        cellC.alignment = {
                                                            horizontal: "center", vertical: "middle"
                                                        }
                                                        cellC.value = previdencia[`valorendaativa${i}`]
                                                        const cellD = worksheetPrevidencia.getCell(`D${i + 3}`)
                                                        cellD.alignment = {
                                                            horizontal: "center", vertical: "middle"
                                                        }
                                                        cellD.value = previdencia[`valorrendativa${i}extras`]
                                                        const cellE = worksheetPrevidencia.getCell(`E${i + 3}`)
                                                        cellE.alignment = {
                                                            horizontal: "center", vertical: "middle"
                                                        }
                                                        cellE.value = previdencia[`valorrendaativa${i}anual`]
                                                        const cellF = worksheetPrevidencia.getCell(`F${i + 3}`)
                                                        cellF.alignment = {
                                                            horizontal: "center", vertical: "middle"
                                                        }
                                                        cellF.value = previdencia[`observacaorenadaativa${i}`]

                                                        //preenche renda passiva (só existe uma)
                                                        if (i == 1) {
                                                            const cellA = worksheetPrevidencia.getCell(`A10`)
                                                            cellA.alignment = {
                                                                "horizontal": "right",
                                                                "vertical": "middle"
                                                            }
                                                            cellA.value = 'Renda Passiva Mensal/extras anuais/Anual'
                                                            //encontra o label da renda passiva
                                                            const rendaPassiva: any = listaRendaPassiva.find(function (rendaPassiva: any) {
                                                                return rendaPassiva.value == previdencia[`rendapassiva1`]
                                                            })
                                                            console.log(rendaPassiva)
                                                            console.log(previdencia)
                                                            const cellB = worksheetPrevidencia.getCell(`B10`)
                                                            cellB.alignment = {
                                                                horizontal: "center", vertical: "middle"
                                                            }
                                                            cellB.value = rendaPassiva?.label || ""
                                                            //preenche os valores
                                                            const cellC = worksheetPrevidencia.getCell(`C10`)
                                                            cellC.alignment = {
                                                                horizontal: "center", vertical: "middle"
                                                            }
                                                            cellC.value = previdencia[`valorendapassiva1`]
                                                            const cellD = worksheetPrevidencia.getCell(`D10`)
                                                            cellD.alignment = {
                                                                horizontal: "center", vertical: "middle"
                                                            }
                                                            cellD.value = previdencia[`valorrendpassiva1extras`]
                                                            const cellE = worksheetPrevidencia.getCell(`E10`)
                                                            cellE.alignment = {
                                                                horizontal: "center", vertical: "middle"
                                                            }
                                                            cellE.value = previdencia[`valorrendapassiva1anual`]
                                                            const cellF = worksheetPrevidencia.getCell(`F10`)
                                                            cellF.alignment = {
                                                                horizontal: "center", vertical: "middle"
                                                            }
                                                            cellF.value = previdencia[`observacaorenadapassiva${i}`]
                                                        }
                                                    }
                                                    //#region exportação do arquivo
                                                    const buffer = await workbook.xlsx.writeBuffer()
                                                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                                                    FileSaver.saveAs(blob, "FC Completo.xlsx")
                                                    set_carregando(false)
                                                }
                                            }).catch(function (erro) {
                                                set_carregando(false)
                                                toast.error(erro.response.data.message || erro.message)
                                            })
                                        }).catch(function (erro) {
                                            console.log(erro)
                                            set_carregando(false)
                                            toast.error("Erro ao gerar Sucessão: " + erro.response.data.message || erro.message)
                                        })
                                    }).catch(function (erro) {
                                        set_carregando(false)
                                        toast.error("Erro ao gerar Independência Financeira: " + erro.response.data.message || erro.message)
                                    })
                                }).catch(function (erro) {
                                    console.log(erro)
                                    set_carregando(false)
                                    toast.error("Erro ao gerar Balanço Patrimonial: " + erro.message)
                                })
                            }).catch(function (erro) {
                                set_carregando(false)
                                toast.error("Erro ao gerar Analise de Risco: " + erro.response.data.message || erro.message)
                            })
                        }).catch(function (erro) {
                            set_carregando(false)
                            toast.error("Erro ao gerar Analise de Perfil: " + erro.response.data.message || erro.message)
                        })
                    }).catch(function (erro) {
                        console.log(erro)
                        set_carregando(false)
                        toast.error(erro.response.data.message || erro.message)
                    })
                }).catch(function (erro) {
                    console.log(erro)
                    set_carregando(false)
                    toast.error(erro.response.data.message || erro.message)
                })
            }).catch(function (erro) {
                console.log(erro)
                set_carregando(false)
                toast.error(erro.response.data.message || erro.message)
            })
        } catch (error) {
            console.log(error)
            set_carregando(false)
        }
    }
    return (
        <>
            <div className="col">
                <ContainerTitulo titulo="Fluxo de Caixa" />

                <Row>
                    <Col sm={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="fantasia"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput"
                                                placeholder=" "
                                                label="Procurar por cliente"
                                            />
                                        </Col>
                                    </div>
                                </Row>

                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={clientes}
                                        itemsPerPage={10}
                                        onVisuClick={visualizar}
                                        onEditClick={editar}
                                        onEnvio={abrirModal}
                                        descEnvio="Exportar .xlsx"
                                        usaEnvio={true}
                                        iconeEnvio={mdiFileExcel}
                                        onDeleteClick={function () {

                                        }}
                                        id="codigo"
                                        permissao_editar={true}
                                        permissao_visualizar={permissao_visualizar}
                                        permissao_excluir={true}
                                        permissao_envio={permissao_visualizar}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ModalLoading show={carregando} />
            </div>


            <button type="button" id="btnEscolherFC" className="btn btn-primary" data-toggle="modal" data-target="#ModalEscolherFc" hidden>
                Launch demo modal
            </button>

            <div className="modal fade" id="ModalEscolherFc" tabIndex={-1} role="dialog" aria-labelledby="ModalEscolherFcLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ModalEscolherFcLabel">Escolher mês/ano para exportação.</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">Escolha o mês/ano para exportação.</p>
                            <div className="form-floating">
                                <select value={mesAnoBaseEscolhido}
                                    onChange={function (e) {

                                        setMesAnoBaseEscolhido(e.target.value)
                                    }}
                                    className="form-select" id="floatingSelect" aria-label="Floating label select example">
                                    <option selected value={""}>Selecione...</option>
                                    {ListaMesAnoBaseCliente.map(function (mes: any) {

                                        return (
                                            <>
                                                <option value={mes.mesanobase.replace("/", "-")}>{mes.mesanobase}</option>
                                            </>
                                        )
                                    })}
                                </select>
                                <label>Mês/Ano</label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={function () {

                                if (mesAnoBaseEscolhido == "") {

                                    toast.error("Selecione um Mês/Ano.")
                                }
                                else {
                                    NovoExportaFCCompleto()
                                }
                            }} type="button" className="btn btn-primary">Exportar .xlsx</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default FluxoCaixaList