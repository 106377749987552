export function TextoFormatoUpperCaseAcento(texto: string): string {
  const textoConvertido = texto
    .normalize("NFD") // Passo 1: Normaliza os caracteres acentuados
    .replace(/[\u0300-\u036f]/g, "") // Passo 2: Remove caracteres acentuados
    .replace(/[^a-zA-Z0-9\/-]/g, "") // Passo 3: Remove caracteres que não sejam letras, dígitos, barras e hífens
    .toUpperCase(); // Passo 4: Converte o resultado para letras maiúsculas
    

  return textoConvertido;
}
