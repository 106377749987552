import React from 'react';


const calcularComplexidade = (senha: string): number => {
  let score = 0;

  if (senha.length >= 8) {
    score += 20;
  }

  if (/[A-Z]/.test(senha)) {
    score += 20;
  }

  if (/[a-z]/.test(senha)) {
    score += 20;
  }

  if (/[0-9]/.test(senha)) {
    score += 20;
  }

  if (/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(senha)) {
    score += 20;
  }

  return score;
};

const ConsultaComplexidade = (senha: string): number => {
  return calcularComplexidade(senha);
};

export default ConsultaComplexidade;
