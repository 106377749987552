export const dadosPais = [
  { codpais: "1058", pais: "Brasil" },
  { codpais: "132", pais: "Afeganistao" },
  { codpais: "7560", pais: "Africa Do Sul" },
  { codpais: "175", pais: "Albania" },
  { codpais: "230", pais: "Alemanha" },
  { codpais: "370", pais: "Andorra" },
  { codpais: "400", pais: "Angola" },
  { codpais: "418", pais: "Anguilla" },
  { codpais: "434", pais: "Antigua E Barbuda" },
  { codpais: "531", pais: "Arabia Saudita" },
  { codpais: "590", pais: "Argelia" },
  { codpais: "639", pais: "Argentina" },
  { codpais: "647", pais: "Armenia" },
  { codpais: "655", pais: "Aruba" },
  { codpais: "698", pais: "Australia" },
  { codpais: "728", pais: "Austria" },
  { codpais: "736", pais: "Azerbaidjao" },
  { codpais: "779", pais: "Bahamas, Ilhas" },
  { codpais: "817", pais: "Bangladesh" },
  { codpais: "833", pais: "Barbados" },
  { codpais: "809", pais: "Barein" },
  { codpais: "850", pais: "Belarus" },
  { codpais: "876", pais: "Belgica" },
  { codpais: "884", pais: "Belize" },
  { codpais: "2291", pais: "Benin" },
  { codpais: "906", pais: "Bermudas" },
  { codpais: "973", pais: "Bolivia" },
  { codpais: "990", pais: "Bonaire, Saint Eustatius E Saba" },
  { codpais: "981", pais: "Bosnia-Herzegovina" },
  { codpais: "1015", pais: "Botsuana" },
  { codpais: "1098", pais: "Bouvet, Ilha" },
  { codpais: "1082", pais: "Brunei" },
  { codpais: "1112", pais: "Bulgaria" },
  { codpais: "310", pais: "Burkina Faso" },
  { codpais: "1155", pais: "Burundi" },
  { codpais: "1198", pais: "Butao" },
  { codpais: "1279", pais: "Cabo Verde" },
  { codpais: "1457", pais: "Camaroes" },
  { codpais: "1414", pais: "Camboja" },
  { codpais: "1490", pais: "Canada" },
  { codpais: "1546", pais: "Catar" },
  { codpais: "1376", pais: "Cayman" },
  { codpais: "1538", pais: "Cazaquistao" },
  { codpais: "7889", pais: "Chade" },
  { codpais: "1589", pais: "Chile" },
  { codpais: "1600", pais: "China" },
  { codpais: "1635", pais: "Chipre" },
  { codpais: "5118", pais: "Christmas, Ilha (Navidad)" },
  { codpais: "7412", pais: "Cingapura" },
  { codpais: "1651", pais: "Cocos (Keelings)" },
  { codpais: "1694", pais: "Colombia" },
  { codpais: "1732", pais: "Comores" },
  { codpais: "1775", pais: "Congo" },
  { codpais: "1830", pais: "Cook" },
  { codpais: "1872", pais: "Coreia Do Norte" },
  { codpais: "1902", pais: "Coreia Do Sul" },
  { codpais: "1937", pais: "Costa Do Marfim" },
  { codpais: "1961", pais: "Costa Rica" },
  { codpais: "1953", pais: "Croacia" },
  { codpais: "1996", pais: "Cuba" },
  { codpais: "2003", pais: "Curacao" },
  { codpais: "2321", pais: "Dinamarca" },
  { codpais: "7838", pais: "Djibuti" },
  { codpais: "2356", pais: "Dominica" },
  { codpais: "2402", pais: "Egito" },
  { codpais: "6874", pais: "El Salvador" },
  { codpais: "2445", pais: "Emirados Arabes Unidos" },
  { codpais: "2399", pais: "Equador" },
  { codpais: "2437", pais: "Eritreia" },
  { codpais: "2470", pais: "Eslovaquia" },
  { codpais: "2461", pais: "Eslovenia" },
  { codpais: "2453", pais: "Espanha" },
  { codpais: "2496", pais: "Estados Unidos" },
  { codpais: "2518", pais: "Estonia" },
  { codpais: "7544", pais: "Eswatini (Antiga Suazilandia" },
  { codpais: "2534", pais: "Etiopia" },
  { codpais: "2550", pais: "Falkland (Malvinas)" },
  { codpais: "2593", pais: "Faroe" },
  { codpais: "8702", pais: "Fiji" },
  { codpais: "2674", pais: "Filipinas" },
  { codpais: "2712", pais: "Finlandia" },
  { codpais: "2755", pais: "Franca" },
  { codpais: "2810", pais: "Gabao" },
  { codpais: "2852", pais: "Gambia" },
  { codpais: "2895", pais: "Gana" },
  { codpais: "2917", pais: "Georgia" },
  { codpais: "2933", pais: "Gibraltar" },
  { codpais: "2976", pais: "Granada" },
  { codpais: "3018", pais: "Grecia" },
  { codpais: "3050", pais: "Groenlandia" },
  { codpais: "3093", pais: "Guadalupe" },
  { codpais: "3131", pais: "Guam" },
  { codpais: "3174", pais: "Guatemala" },
  {
    codpais: "1504",
    pais: "Guernsey, Ilha Do Canal (Inclui Alderney E Sark)",
  },
  { codpais: "3379", pais: "Guiana" },
  { codpais: "3255", pais: "Guiana Francesa" },
  { codpais: "3298", pais: "Guine" },
  { codpais: "3344", pais: "Guine-Bissau" },
  { codpais: "3310", pais: "Guine-Equatorial" },
  { codpais: "3417", pais: "Haiti" },
  { codpais: "5738", pais: "Holanda (Paises Baixos)" },
  { codpais: "3450", pais: "Honduras" },
  { codpais: "3514", pais: "Hong Kong" },
  { codpais: "3557", pais: "Hungria" },
  { codpais: "3573", pais: "Iemen" },
  { codpais: "3611", pais: "India" },
  { codpais: "3654", pais: "Indonesia" },
  { codpais: "3727", pais: "Ira" },
  { codpais: "3697", pais: "Iraque" },
  { codpais: "3751", pais: "Irlanda" },
  { codpais: "3794", pais: "Islandia" },
  { codpais: "3832", pais: "Israel" },
  { codpais: "3867", pais: "Italia" },
  { codpais: "3913", pais: "Jamaica" },
  { codpais: "3999", pais: "Japao" },
  { codpais: "1508", pais: "Jersey, Ilha Do Canal" },
  { codpais: "3964", pais: "Johnston" },
  { codpais: "4030", pais: "Jordania" },
  { codpais: "4111", pais: "Kiribati" },
  { codpais: "1988", pais: "KUWEIT (Ou Coveite)" },
  { codpais: "4200", pais: "Laos" },
  { codpais: "4260", pais: "Lesoto" },
  { codpais: "4278", pais: "Letonia" },
  { codpais: "4316", pais: "Libano" },
  { codpais: "4340", pais: "Liberia" },
  { codpais: "4383", pais: "Libia" },
  { codpais: "4405", pais: "Liechtenstein" },
  { codpais: "4421", pais: "Lituania" },
  { codpais: "4456", pais: "Luxemburgo" },
  { codpais: "4472", pais: "Macau" },
  { codpais: "4421", pais: "Macedonia" },
  { codpais: "4502", pais: "Madagascar" },
  { codpais: "4553", pais: "Malasia" },
  { codpais: "4588", pais: "Malavi" },
  { codpais: "4618", pais: "Maldivas" },
  { codpais: "4642", pais: "Mali" },
  { codpais: "4677", pais: "Malta" },
  { codpais: "3595", pais: "Man, Ilha De" },
  { codpais: "4723", pais: "Marianas Do Norte" },
  { codpais: "4740", pais: "Marrocos" },
  { codpais: "4766", pais: "Marshall, Ilhas" },
  { codpais: "4774", pais: "Martinica" },
  { codpais: "4855", pais: "Mauricio" },
  { codpais: "4880", pais: "Mauritania" },
  { codpais: "4936", pais: "Mexico" },
  { codpais: "930", pais: "Mianmar" },
  { codpais: "4995", pais: "Micronesia" },
  { codpais: "5053", pais: "Mocambique" },
  { codpais: "4944", pais: "Moldavia" },
  { codpais: "4952", pais: "Monaco" },
  { codpais: "4979", pais: "Mongolia" },
  { codpais: "4985", pais: "Montenegro" },
  { codpais: "5010", pais: "Montserrat" },
  { codpais: "5070", pais: "Namibia" },
  { codpais: "5088", pais: "Nauru" },
  { codpais: "5177", pais: "Nepal" },
  { codpais: "5215", pais: "Nicaragua" },
  { codpais: "5258", pais: "Niger" },
  { codpais: "5282", pais: "Nigeria" },
  { codpais: "5312", pais: "Niue" },
  { codpais: "5355", pais: "Norfolk, Ilha" },
  { codpais: "5380", pais: "Noruega" },
  { codpais: "5428", pais: "Nova Caledonia" },
  { codpais: "5487", pais: "Nova Zelandia" },
  { codpais: "5568", pais: "Oma" },
  { codpais: "5665", pais: "Pacifico, Ilhas Do (Possessao Dos Eua)" },
  { codpais: "5754", pais: "Palau" },
  { codpais: "5780", pais: "Palestina" },
  { codpais: "5800", pais: "Panama" },
  { codpais: "5452", pais: "Papua Nova Guine" },
  { codpais: "5762", pais: "Paquistao" },
  { codpais: "5860", pais: "Paraguai" },
  { codpais: "5894", pais: "Peru" },
  { codpais: "5932", pais: "Pitcairn" },
  { codpais: "5991", pais: "Polinesia Francesa" },
  { codpais: "6033", pais: "Polonia" },
  { codpais: "6114", pais: "Porto Rico" },
  { codpais: "6076", pais: "Portugal" },
  { codpais: "6238", pais: "Quenia" },
  { codpais: "6254", pais: "Quirguistao" },
  { codpais: "6289", pais: "Reino Unido" },
  { codpais: "6408", pais: "Republica Centro-Africana" },
  { codpais: "8885", pais: "Republica Democratica Do Congo" },
  { codpais: "6475", pais: "Republica Dominicana" },
  { codpais: "7919", pais: "Republica Tcheca" },
  { codpais: "6602", pais: "Reuniao" },
  { codpais: "6700", pais: "Romenia" },
  { codpais: "6750", pais: "Ruanda" },
  { codpais: "6769", pais: "Russia" },
  { codpais: "6858", pais: "Saara Ocidental" },
  { codpais: "6777", pais: "Salomao, Ilhas" },
  { codpais: "6904", pais: "Samoa" },
  { codpais: "6912", pais: "Samoa Americana" },
  { codpais: "6971", pais: "San Marino" },
  { codpais: "7102", pais: "Santa Helena" },
  { codpais: "7153", pais: "Santa Lucia" },
  { codpais: "6955", pais: "Sao Cristovao E Neves" },
  { codpais: "6980", pais: "Sao Martinho, Ilha De (Parte Francesa)" },
  { codpais: "6998", pais: "Sao Martinho, Ilha De (Parte Holandesa)" },
  { codpais: "7005", pais: "Sao Pedro E Miquelon" },
  { codpais: "7200", pais: "Sao Tome E Principe" },
  { codpais: "7056", pais: "Sao Vicente E Granadinas" },
  { codpais: "7315", pais: "Seicheles" },
  { codpais: "7285", pais: "Senegal" },
  { codpais: "7358", pais: "Serra Leoa" },
  { codpais: "7370", pais: "Servia" },
  { codpais: "7447", pais: "Siria" },
  { codpais: "7480", pais: "Somalia" },
  { codpais: "7501", pais: "Sri Lanka" },
  { codpais: "7595", pais: "Sudao" },
  { codpais: "7600", pais: "Sudão Do Sul" },
  { codpais: "7641", pais: "Suecia" },
  { codpais: "7676", pais: "Suica" },
  { codpais: "7706", pais: "Suriname" },
  { codpais: "7552", pais: "Svalbard E Jan Mayen" },
  { codpais: "7722", pais: "Tadjiquistao" },
  { codpais: "7765", pais: "Tailandia" },
  { codpais: "1619", pais: "Taiwan" },
  { codpais: "7803", pais: "Tanzania" },
  { codpais: "7820", pais: "Territorio Britanico Oceano Indico" },
  { codpais: "7951", pais: "Timor Leste" },
  { codpais: "8001", pais: "Togo" },
  { codpais: "8109", pais: "Tonga" },
  { codpais: "8052", pais: "Toquelau" },
  { codpais: "8150", pais: "Trinidad E Tobago" },
  { codpais: "8206", pais: "Tunisia" },
  { codpais: "8230", pais: "Turcas E Caicos" },
  { codpais: "8249", pais: "Turcomenistao" },
  { codpais: "8273", pais: "Turquia" },
  { codpais: "8281", pais: "Tuvalu" },
  { codpais: "8311", pais: "Ucrania" },
  { codpais: "8338", pais: "Uganda" },
  { codpais: "8451", pais: "Uruguai" },
  { codpais: "8478", pais: "Uzbequistao" },
  { codpais: "5517", pais: "Vanuatu" },
  { codpais: "8486", pais: "Vaticano" },
  { codpais: "8508", pais: "Venezuela" },
  { codpais: "8583", pais: "Vietna" },
  { codpais: "8630", pais: "Virgens, Ilhas (Britanicas)" },
  { codpais: "8664", pais: "Virgens, Ilhas (Eua)" },
  { codpais: "8753", pais: "Wallis E Futuna, Ilhas" },
  { codpais: "8907", pais: "Zambia" },
  { codpais: "6653", pais: "Zimbabue" },
];
