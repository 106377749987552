const ConverterBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result && typeof reader.result === "string") {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        } else {
          reject(new Error("Falha ao ler arquivo."));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

export default ConverterBase64;
