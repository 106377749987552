import ContainerTitulo from "../../components/Formularios/Container/ContainerTitulo"
import { Row, Col, Card } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InputSemBorda from "../../components/Formularios/Inputs/InputsSemBorda";
import { getSessionData } from "../../utils/storageUtils";
import axios from "axios";
import { Button as ButtonCustom } from "../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import { toast } from "react-toastify";
import { mascaraTelefoneCelular, mascaraTelefoneFixo, mascaraCPF, mascaraCEP } from "../../hooks/mascaras";
import ModalLoading from "../../components/Formularios/Modal/ModalLoading";
import { dadosUF } from "../../components/dadosFixos/UF";
import { Form, InputGroup } from "react-bootstrap";
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";


function CadClienteExterno() {


    const params = useParams()
    const navigate = useNavigate()

    const [cpf, set_cpf] = useState("")
    const [nome, set_nome] = useState("")
    const [email, set_email] = useState("")
    const [celular, set_celular] = useState("")
    const [telefone, set_telefone] = useState("")
    const [data_nascimento, set_data_nascimento] = useState("19-02-2004")
    const [cep, set_cep] = useState("")
    const [logradouro, set_logradouro] = useState("")
    const [complemento, set_complemento] = useState("")
    const [numero, set_numero] = useState("")
    const [bairro, set_bairro] = useState("")
    const [cidade, set_cidade] = useState("")
    const [codmunicipio, set_codmunicipio] = useState("")
    const [uf, set_uf] = useState("")
    const [obs, set_obs] = useState("")
    const [empresa, setEmpresa] = useState("")
    const [cargo, setCargo] = useState("")
    const [nomeParceiro, setNomeParceiro] = useState("")
    const [regimeCasamento, setRegimeCasamento] = useState("")
    const [cpfParceiro, setCpfParceiro] = useState("")
    const [dataNascimentoParceiro, setDataNascimentoParceiro] = useState("")
    const [cargoParceiro, setCargoParceiro] = useState("")
    const [empresaParceiro, setEmpresaParceiro] = useState("")
    const [ListaEstadoCivil, setListaEstadoCivil] = useState([])


    const buscarCep = async (cep: string) => {
        if (cep.length < 8) {
            toast.error("CEP inválido!");
        } else if (cep.length == 8) {
            await axios
                .get(`https://viacep.com.br/ws/${cep}/json/`)
                .then((retorno: any) => {
                    if (retorno.data?.erro) {
                        toast.error("CEP inválido ou Não Localizado!");
                    } else {
                        const dadosCep = retorno.data;
                        set_logradouro(dadosCep.logradouro);
                        set_complemento(dadosCep.complemento);
                        set_bairro(dadosCep.bairro);
                        set_cidade(dadosCep.localidade);
                        set_uf(dadosCep.uf);
                        set_codmunicipio(dadosCep.ibge);
                    }
                })
                .catch((erro) => {
                    console.log("erro: ", erro);
                    const msg =
                        erro.response?.data.message === undefined
                            ? erro.message
                            : erro.response.data.message;
                    toast.error(`Erro ao buscar cep. Motivo: ${msg}`);
                });
        }
    }
    //modal loading
    const [carregando, set_carregando] = useState(false)

    function atualizarCliente(e: any) {

        e.preventDefault()
        set_carregando(true)
        const dados = {

            //id_filial: getSessionData("DadosIdfilial"),
            cpf: cpf,
            nome: nome,
            email: email,
            celular: celular,
            telefone: telefone,
            data_nascimento: data_nascimento,
            cep: cep,
            logradouro: logradouro,
            complemento: complemento,
            numero: numero,
            bairro: bairro,
            cidade: cidade,
            codmunicipio: codmunicipio,
            uf: uf,
            obs: obs,
            empresa,
            cargo,
            nomeParceiro,
            regimeCasamento,
            cpfParceiro,
            empresaParceiro,
            cargoParceiro,
            dataNascimentoParceiro
        }

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/cliente/cadastro/${params.id_cliente}?precad=1`, dados, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {


                set_carregando(false)
                if (resposta.data.codigo == 200) {

                    toast.success(resposta.data.message)
                    setTimeout(function () {

                        navigate(-1)
                    }, 2000)
                }
                else {

                    toast.error(resposta.data.message)
                }
            }).catch(function (erro) {


                set_carregando(false)
                toast.error(erro)
            })
    }

    function carregarCliente(id_cliente: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cadastro/cliente/${id_cliente}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {

                set_carregando(false)
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {

                    const data = resposta.data.cliente[0].dtnascimento != null ? resposta.data.cliente[0].dtnascimento : [""]
                    set_data_nascimento(data[0])

                    set_cpf(resposta.data.cliente[0].cpf || "")
                    set_nome(resposta.data.cliente[0].nome || "")
                    set_telefone(resposta.data.cliente[0].telfixo || "")
                    set_celular(resposta.data.cliente[0].telcelular || "")
                    set_email(resposta.data.cliente[0].email || "")
                    set_logradouro(resposta.data.cliente[0].logradouro || "")
                    set_numero(resposta.data.cliente[0].numero || "")
                    set_bairro(resposta.data.cliente[0].bairro || "")
                    set_complemento(resposta.data.cliente[0].compl)
                    set_codmunicipio(resposta.data.cliente[0].cnum)
                    set_cep(resposta.data.cliente[0].cep || "")
                    set_obs(resposta.data.cliente[0].observacao || "")
                    set_uf(resposta.data.cliente[0].cuf || "")
                    set_cidade(resposta.data.cliente[0].cidade || "")
                    setEmpresa(resposta.data.cliente[0].empresa || "")
                    setCargo(resposta.data.cliente[0].cargo || "")
                    setNomeParceiro(resposta.data.cliente[0].nomeparceiro || "")
                    setCpfParceiro(resposta.data.cliente[0].cpfparceiro || "")
                    setRegimeCasamento(resposta.data.cliente[0].estado_civil || "")
                    setEmpresaParceiro(resposta.data.cliente[0].empresaparceiro || "")
                    setCargoParceiro(resposta.data.cliente[0].cargoparceiro || "")
                    const dataParceiroNascimento = resposta.data.cliente[0].dtnascimento.split("T")
                    setDataNascimentoParceiro(dataParceiroNascimento[0] || "")
                }
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }

    async function verificaValidadeToken(token: any) {

        const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/${token}`)

        if (resposta.data.codigo != 200) {

            toast.error(resposta.data.message)
            navigate(-1)
        }
    }

    function CarregarEstadoCivil() {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/ESTADOCIVIL`, {
            headers: {
                Authorization: sessionStorage.getItem("MultfilialWebToken")!
            }
        }).then(function (resposta) {
            setListaEstadoCivil(resposta.data)
            set_carregando(false)
        }).catch(function (erro) {
            console.log(erro)
            set_carregando(false)
        })
    }

    useEffect(function () {

        verificaValidadeToken(getSessionData("tokenPreCad"))
        CarregarEstadoCivil()
        carregarCliente(params.id_cliente)
    }, [])

    return (
        <>
            <div className="container-fluid mt-2">
                <ContainerTitulo titulo="Completar Cadastro" />
                <Card>
                    <Form onSubmit={atualizarCliente}>
                        <Card.Body>
                            <Box sx={{ width: "100%" }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm col-md-4 col-lg-2">
                                            <InputSemBorda
                                                name="text"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraCPF(cpf)}
                                                onChange={set_cpf}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="CPF"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-2">
                                            <InputSemBorda
                                                name="Data nasc."
                                                type="date"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={data_nascimento}
                                                required
                                                onChange={set_data_nascimento}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Data de nascimento"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-6">
                                            <InputSemBorda
                                                name="nome"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={nome}
                                                required
                                                onChange={set_nome}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Nome"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm col-md-4 col-lg-2">
                                            <InputSemBorda
                                                name="celular"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraTelefoneCelular(celular)}
                                                onChange={set_celular}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Celular"
                                            />
                                        </div>
                                        <div className="col-sm col-md-4 col-lg-2">
                                            <InputSemBorda
                                                name="telefone"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraTelefoneFixo(telefone)}
                                                onChange={set_telefone}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Telefone"
                                            />
                                        </div>
                                        <div className="col-sm col-md-4 col-lg-2">
                                            <InputSemBorda
                                                name="e-mail"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={email}
                                                required
                                                onChange={set_email}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="E-mail"
                                            />
                                        </div>
                                        <div className="col-sm col-md-4 col-lg-4">
                                            <InputSemBorda
                                                name="empresa"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={empresa}
                                                onChange={setEmpresa}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Empresa Atual"
                                            />
                                        </div>
                                        <div className="col-sm col-md-4 col-lg-2">
                                            <InputSemBorda
                                                name="cargoAtual"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={cargo}
                                                onChange={setCargo}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Profissão Atual"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm col-md-4 col-lg-4">
                                            <InputSemBorda
                                                name="nomeParceiro"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={nomeParceiro}
                                                onChange={setNomeParceiro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Nome Parceiro(a)"
                                            />
                                        </div>
                                        <div className="col-sm col-md-4 col-lg-4">
                                            <InputSemBorda
                                                name="cpfParceiro"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraCPF(cpfParceiro)}
                                                onChange={setCpfParceiro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="CPF Parceiro(a)"
                                            />
                                        </div>
                                        <div className="col-md-3 col-lg-2">
                                            <div className="form-floating">
                                                <select disabled={params.acao == 'visualizar' ? true : false} value={regimeCasamento} onChange={function (e) {
                                                    setRegimeCasamento(e.target.value)
                                                }} className="form-select bordasInferiorInput text-dark">
                                                    <option value="">Selecione...</option>
                                                    {
                                                        ListaEstadoCivil.map(function (codigo: any) {
                                                            return <option value={codigo.value}>{codigo.label}</option>
                                                        })
                                                    }
                                                </select>
                                                <label>Estado Civil</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm col-md-2 col-lg-2">
                                            <InputSemBorda
                                                name="cep"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={mascaraCEP(cep)}
                                                onChange={set_cep}
                                                onBlur={function () {
                                                    const cep_formatado = cep.replace(/[.\-/]/g, '')
                                                    set_cep(cep_formatado)
                                                    buscarCep(cep_formatado)
                                                }}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Cep"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-2">
                                            <InputSemBorda
                                                name="numero"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={numero}
                                                onChange={set_numero}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Numero"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-4">
                                            <InputSemBorda
                                                name="complemento"
                                                type="text"
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={complemento}
                                                onChange={set_complemento}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Complemento"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-4">
                                            <InputSemBorda
                                                name="Logradouro"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={logradouro}
                                                onChange={set_logradouro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Logradouro"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-2">
                                            <InputSemBorda
                                                name="bairro"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={bairro}
                                                onChange={set_bairro}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Bairro"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-4">
                                            <InputSemBorda
                                                name="cidade"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={cidade}
                                                onChange={set_cidade}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Cidade"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-2">
                                            <InputSemBorda
                                                name="codmunicipio"
                                                type="text"
                                                required
                                                readonly={params.acao == 'visualizar' ? true : false}
                                                value={codmunicipio}
                                                onChange={set_codmunicipio}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Cód. Município"
                                            />
                                        </div>
                                        <div className="col-sm col-md-2 col-lg-4">
                                            <FormControl
                                                variant="standard"
                                                sx={{ m: 1.5, width: "100%" }}
                                            >
                                                <InputLabel>UF</InputLabel>
                                                <Select
                                                    value={uf}
                                                    onChange={(e: SelectChangeEvent) =>
                                                        set_uf(e.target.value)
                                                    }
                                                    label="UF"
                                                    required
                                                    disabled={params.acao === "visualizar" ? true : false}
                                                >
                                                    <MenuItem value="">
                                                        <em>Selecione...</em>
                                                    </MenuItem>
                                                    {dadosUF.map((item: any) => (
                                                        <MenuItem value={item.uf}>{item.nome}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-sm-2 col-md-5 col-lg">
                                            <InputSemBorda
                                                name="Observação"
                                                type="textarea"

                                                value={obs}
                                                onChange={set_obs}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Observações"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex my-2 gap-1 justify-content-between">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Voltar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        navigate(-1);
                                    }}
                                />
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' ? true : false}
                                    type="submit"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Salvar"
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </Card.Footer>
                    </Form>
                </Card>
                <ModalLoading show={carregando} />
            </div>
        </>
    )
}

export default CadClienteExterno