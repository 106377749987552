const setSessionData = (key: string, dados: any) => {
  sessionStorage.setItem(key, JSON.stringify(dados));
};

const getSessionData = (key: string) => {
  const storedData = sessionStorage.getItem(key);
  return storedData ? storedData : null;
};

const removeSessionData = (key: string) => {
  console.log("key: ", key);
  sessionStorage.removeItem(key);
};

export { setSessionData, getSessionData, removeSessionData };
