import axios from "axios";
import { Api } from "../../api";

interface ApiDeleteParams {
  url: string;
  parametros?: Record<string, string>;
  queryParametros?: Record<string, any>;
  headers?: Record<string, string>;
}

const ApiDelete = async ({
  url,
  parametros,
  queryParametros,
  headers,
}: ApiDeleteParams) => {
  try {
    let urlFormatada = url;
    if (parametros) {
      Object.keys(parametros).forEach((paramKey) => {
        urlFormatada = urlFormatada.replace(
          `:${paramKey}`,
          parametros[paramKey]
        );
      });
    }
    const response = await axios.delete(Api + urlFormatada, {
      params: queryParametros,
      headers: headers || {},
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export default ApiDelete;
