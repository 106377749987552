import { useNavigate, useParams } from "react-router-dom"
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import {
    Box,
    Switch,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Tab,
    Avatar,
    Stack,
    Button,
    Paper,
    Typography,
} from "@mui/material";
import ContainerTitulo from "../../../../components/Formularios/Container/ContainerTitulo"
import { useEffect, useState } from "react";
import { Button as ButtonCustom } from "../../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import MiniInput from "../../../../components/Formularios/Inputs/miniInput";
function NovoFCPropostoForm() {
    const token = sessionStorage.getItem("MultfilialWebToken")!
    const params = useParams()
    const navigate = useNavigate()
    //data atual
    const dataHoje = new Date().toISOString()
    //modal de selecionar mes
    const [ModalMesAno, set_ModalMesAno] = useState(true)
    const [carregando, set_carregando] = useState(false)
    const [meses, set_meses] = useState<any>([])
    function gerarMeses() {
        const dataToday = new Date();
        const anoAtual = dataToday.getFullYear(); // Obtém o ano atual
        const mesAtual = dataToday.getMonth() + 1; // Obtém o mês atual (de 0 a 11)
        const datas = [];

        // Gera 3 meses para trás
        let ano = anoAtual;
        let mes = mesAtual;
        for (let i = 0; i < 3; i++) {
            mes--;
            if (mes < 1) {
                mes = 12;
                ano--;
            }
            datas.unshift({ mes: mes.toString().padStart(2, '0'), ano: ano }); // Adiciona ao início do array
        }

        // Reseta para o mês atual e ano
        ano = anoAtual;
        mes = mesAtual;

        // Gera 12 meses para frente
        for (let i = 0; i < 12; i++) {
            datas.push({ mes: mes.toString().padStart(2, '0'), ano: ano });
            mes++;
            if (mes > 12) {
                mes = 1;
                ano++;
            }
        }

        // Atualiza o estado com o array de datas
        set_meses(datas);
    }
    function setMesAnoBase(dataHoje: any) {

        if (dataHoje.split("T")) {

            set_mesAnoBase(dataHoje.split("T")[0].split("-")[1] + "/" + dataHoje.split("T")[0].split("-")[0])
        }
        else {

            set_mesAnoBase(dataHoje.split("-")[1] + "/" + dataHoje.split("-")[0])
        }

    }
    const [ListaMeses, set_ListaMeses] = useState<any>([])
    const [mesAnoBase, set_mesAnoBase] = useState<any>()
    function listarProximosMesesEAnos(numeroMes: any, anoAtual: any) {
        // Validar se o número do mês está dentro do intervalo válido (1 a 12)
        if (numeroMes < 1 || numeroMes > 12) {
            return null;
        }

        // Array de nomes dos meses
        let meses = [
            "Jan", "Fev", "Mar", "Abr",
            "Mai", "Jun", "Jul", "Ago",
            "Set", "Out", "Nov", "Dez"
        ];

        // Lista para armazenar os próximos 12 meses e anos
        let proximosMesesEAnos = [];

        // Loop para adicionar os próximos 12 meses e anos à lista
        for (let i = 0; i < 12; i++) {
            let mesIndex = (numeroMes + i - 1) % 12;
            let ano = anoAtual + Math.floor((numeroMes + i - 1) / 12);
            proximosMesesEAnos.push({ mes: meses[mesIndex], ano: ano });
        }

        set_ListaMeses(proximosMesesEAnos)
        return proximosMesesEAnos;
    }
    const [listaRendaAtiva, set_listaRendaAtiva] = useState([])
    const [listaRendaPassiva, set_listaRendaPassiva] = useState([])
    const [listadespesas, set_listadespesas] = useState([])
    function ListarCODSistema(tabela: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/codigosistema/listar/${tabela}`)
            .then(function (resposta) {

                if (tabela == "RENDAATIVA") {

                    set_listaRendaAtiva(resposta.data)
                }
                else if (tabela == "RENDAPASSIVA") {

                    set_listaRendaPassiva(resposta.data)
                }
                else if (tabela == "DESPESAS") {

                    set_listadespesas(resposta.data)
                }

                set_carregando(false)
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }
    useEffect(function () {
        listarProximosMesesEAnos(parseInt(dataHoje.split("T")[0].split("-")[1]), parseInt(dataHoje.split("T")[0].split("-")[0]))
        gerarMeses()
        //setMesAnoBase(dataHoje)
        ListarCODSistema("RENDAATIVA")
        ListarCODSistema("RENDAPASSIVA")
        ListarCODSistema("DESPESAS")
    }, [])
    function formatarValor(valor: string) {
        if (!valor) return "0,00";

        // Verifica se o valor já está formatado no padrão com vírgula e ponto
        const regexFormato = /^\d{1,3}(\.\d{3})*,\d{2}$/;
        if (regexFormato.test(valor)) {
            return valor;
        }

        return parseFloat(valor)
            .toFixed(2) // Garante duas casas decimais
            .replace('.', ',') // Substitui o ponto por vírgula
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Adiciona o ponto como separador de milhar
    }
    const [QtdeLinhasAtivos, setQtdeLinhasAtivos] = useState([1])
    const [ObjLinhas, setObjLinhas] = useState<any>([])
    const [totalGeralAtivos, setTotalGeralAtivos] = useState(0)
    function CalcularTotalEPorcemAtivos(linha: any) {
        const novosObjetos = []
        let NovototalGeral = 0
        for (let i = 1; i <= QtdeLinhasAtivos.length; i = i + 1) {
            const RendaAtiva = (document.querySelector(`#rendaatv${i}`) as HTMLInputElement).value
            let valueInputA = (document.querySelector(`#inputA${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputA${i}`) as HTMLInputElement).value
            let valueInputB = (document.querySelector(`#inputB${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputB${i}`) as HTMLInputElement).value
            let valueInputC = (document.querySelector(`#inputC${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputC${i}`) as HTMLInputElement).value
            let valueInputD = (document.querySelector(`#inputD${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputD${i}`) as HTMLInputElement).value
            let valueInputE = (document.querySelector(`#inputE${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputE${i}`) as HTMLInputElement).value
            let valueInputF = (document.querySelector(`#inputF${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputF${i}`) as HTMLInputElement).value
            let valueInputG = (document.querySelector(`#inputG${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputG${i}`) as HTMLInputElement).value
            let valueInputH = (document.querySelector(`#inputH${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputH${i}`) as HTMLInputElement).value
            let valueInputI = (document.querySelector(`#inputI${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputI${i}`) as HTMLInputElement).value
            let valueInputJ = (document.querySelector(`#inputJ${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputJ${i}`) as HTMLInputElement).value
            let valueInputK = (document.querySelector(`#inputK${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputK${i}`) as HTMLInputElement).value
            let valueInputL = (document.querySelector(`#inputL${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputL${i}`) as HTMLInputElement).value
            const novoObjLinha = {
                rendaAtiva: RendaAtiva,
                mes1: parseFloat(valueInputA.replaceAll(".", "").replaceAll(",", ".")),
                mes2: parseFloat(valueInputB.replaceAll(".", "").replaceAll(",", ".")),
                mes3: parseFloat(valueInputC.replaceAll(".", "").replaceAll(",", ".")),
                mes4: parseFloat(valueInputD.replaceAll(".", "").replaceAll(",", ".")),
                mes5: parseFloat(valueInputE.replaceAll(".", "").replaceAll(",", ".")),
                mes6: parseFloat(valueInputF.replaceAll(".", "").replaceAll(",", ".")),
                mes7: parseFloat(valueInputG.replaceAll(".", "").replaceAll(",", ".")),
                mes8: parseFloat(valueInputH.replaceAll(".", "").replaceAll(",", ".")),
                mes9: parseFloat(valueInputI.replaceAll(".", "").replaceAll(",", ".")),
                mes10: parseFloat(valueInputJ.replaceAll(".", "").replaceAll(",", ".")),
                mes11: parseFloat(valueInputK.replaceAll(".", "").replaceAll(",", ".")),
                mes12: parseFloat(valueInputL.replaceAll(".", "").replaceAll(",", "."))
            }
            const total = document.querySelector(`#total${i}`) as HTMLInputElement
            total.value = formatarValor((novoObjLinha.mes1 + novoObjLinha.mes2 + novoObjLinha.mes3 + novoObjLinha.mes4 + novoObjLinha.mes5 + novoObjLinha.mes6 + novoObjLinha.mes7 + novoObjLinha.mes8 + novoObjLinha.mes9 + novoObjLinha.mes10 + novoObjLinha.mes11 + novoObjLinha.mes12).toString())
            NovototalGeral = NovototalGeral + parseFloat(total.value.replaceAll(".", "").replaceAll(",", "."))
            novosObjetos.push(novoObjLinha)
        }
        setTotalGeralAtivos(NovototalGeral)
        setObjLinhas(novosObjetos)
        if (linha !== 0) {
            CriarOuAtualizarLinha("ativos", id_fluxo, linha, novosObjetos)
        }
    }
    const [QtdeLinhasPassivos, setQtdeLinhasPassivos] = useState([1])
    const [ObjLinhasPassivos, setObjLinhasPassivos] = useState<any>([])
    const [totalGeralPassivos, setTotalGeralPassivos] = useState(0)
    function CalcularTotalEPorcemPassivos(numeroLinha: any) {
        const novosObjetos = []
        let NovototalGeral = 0
        for (let i = 1; i <= QtdeLinhasPassivos.length; i = i + 1) {

            const RendaPass = (document.querySelector(`#rendapass${i}`) as HTMLInputElement).value
            let valueInputA = (document.querySelector(`#inputpassA${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassA${i}`) as HTMLInputElement).value
            let valueInputB = (document.querySelector(`#inputpassB${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassB${i}`) as HTMLInputElement).value
            let valueInputC = (document.querySelector(`#inputpassC${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassC${i}`) as HTMLInputElement).value
            let valueInputD = (document.querySelector(`#inputpassD${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassD${i}`) as HTMLInputElement).value
            let valueInputE = (document.querySelector(`#inputpassE${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassE${i}`) as HTMLInputElement).value
            let valueInputF = (document.querySelector(`#inputpassF${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassF${i}`) as HTMLInputElement).value
            let valueInputG = (document.querySelector(`#inputpassG${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassG${i}`) as HTMLInputElement).value
            let valueInputH = (document.querySelector(`#inputpassH${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassH${i}`) as HTMLInputElement).value
            let valueInputI = (document.querySelector(`#inputpassI${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassI${i}`) as HTMLInputElement).value
            let valueInputJ = (document.querySelector(`#inputpassJ${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassJ${i}`) as HTMLInputElement).value
            let valueInputK = (document.querySelector(`#inputpassK${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassK${i}`) as HTMLInputElement).value
            let valueInputL = (document.querySelector(`#inputpassL${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputpassL${i}`) as HTMLInputElement).value
            const novoObjLinha = {
                rendaPass: RendaPass,
                mes1: parseFloat(valueInputA.replaceAll(".", "").replaceAll(",", ".")),
                mes2: parseFloat(valueInputB.replaceAll(".", "").replaceAll(",", ".")),
                mes3: parseFloat(valueInputC.replaceAll(".", "").replaceAll(",", ".")),
                mes4: parseFloat(valueInputD.replaceAll(".", "").replaceAll(",", ".")),
                mes5: parseFloat(valueInputE.replaceAll(".", "").replaceAll(",", ".")),
                mes6: parseFloat(valueInputF.replaceAll(".", "").replaceAll(",", ".")),
                mes7: parseFloat(valueInputG.replaceAll(".", "").replaceAll(",", ".")),
                mes8: parseFloat(valueInputH.replaceAll(".", "").replaceAll(",", ".")),
                mes9: parseFloat(valueInputI.replaceAll(".", "").replaceAll(",", ".")),
                mes10: parseFloat(valueInputJ.replaceAll(".", "").replaceAll(",", ".")),
                mes11: parseFloat(valueInputK.replaceAll(".", "").replaceAll(",", ".")),
                mes12: parseFloat(valueInputL.replaceAll(".", "").replaceAll(",", "."))
            }
            const total = document.querySelector(`#totalpass${i}`) as HTMLInputElement
            total.value = formatarValor((novoObjLinha.mes1 + novoObjLinha.mes2 + novoObjLinha.mes3 + novoObjLinha.mes4 + novoObjLinha.mes5 + novoObjLinha.mes6 + novoObjLinha.mes7 + novoObjLinha.mes8 + novoObjLinha.mes9 + novoObjLinha.mes10 + novoObjLinha.mes11 + novoObjLinha.mes12).toString())
            NovototalGeral = NovototalGeral + parseFloat(total.value.replaceAll(".", "").replaceAll(",", "."))
            novosObjetos.push(novoObjLinha)
            if (numeroLinha !== 0) {
                CriarOuAtualizarLinha("passivos", id_fluxo, numeroLinha, novosObjetos)
            }
        }
        setTotalGeralPassivos(NovototalGeral)
        setObjLinhasPassivos(novosObjetos)
    }
    useEffect(function () {
        for (let i = 1; i <= QtdeLinhasPassivos.length; i = i + 1) {
            const total = document.querySelector(`#totalpass${i}`) as HTMLInputElement
            const porcem = document.querySelector(`#porcempass${i}`) as HTMLInputElement
            if (total.value !== "0,00") {
                porcem.value = (((parseFloat(total.value.replaceAll(".", "").replace(",", ".")) / (totalGeralPassivos + totalGeralAtivos)) * 100).toFixed(2)).toString()
                if (porcem.value == "NaN") {
                    porcem.value = "0.00"
                }
            }
        }
        //calculando total passivos -- inicio
        let totaisPassivos: any = {
            mes1: 0,
            mes2: 0,
            mes3: 0,
            mes4: 0,
            mes5: 0,
            mes6: 0,
            mes7: 0,
            mes8: 0,
            mes9: 0,
            mes10: 0,
            mes11: 0,
            mes12: 0,
        }
        for (let i = 0; i < ObjLinhasPassivos.length; i = i + 1) {
            totaisPassivos.mes1 = totaisPassivos.mes1 + ObjLinhasPassivos[i].mes1
            totaisPassivos.mes2 = totaisPassivos.mes2 + ObjLinhasPassivos[i].mes2
            totaisPassivos.mes3 = totaisPassivos.mes3 + ObjLinhasPassivos[i].mes3
            totaisPassivos.mes4 = totaisPassivos.mes4 + ObjLinhasPassivos[i].mes4
            totaisPassivos.mes5 = totaisPassivos.mes5 + ObjLinhasPassivos[i].mes5
            totaisPassivos.mes6 = totaisPassivos.mes1 + ObjLinhasPassivos[i].mes6
            totaisPassivos.mes7 = totaisPassivos.mes7 + ObjLinhasPassivos[i].mes7
            totaisPassivos.mes8 = totaisPassivos.mes8 + ObjLinhasPassivos[i].mes8
            totaisPassivos.mes9 = totaisPassivos.mes9 + ObjLinhasPassivos[i].mes9
            totaisPassivos.mes10 = totaisPassivos.mes10 + ObjLinhasPassivos[i].mes10
            totaisPassivos.mes11 = totaisPassivos.mes11 + ObjLinhasPassivos[i].mes11
            totaisPassivos.mes12 = totaisPassivos.mes12 + ObjLinhasPassivos[i].mes12
        }
        for (let i = 0; i < 12; i = i + 1) {
            const total = document.querySelector(`#totalmes${i + 1}_passivos`) as HTMLInputElement
            total.value = formatarValor(totaisPassivos[`mes${i + 1}`].toString())
        }
        //calculando totasl passivos -- fim
    }, [totalGeralPassivos, totalGeralAtivos])
    useEffect(function () {
        for (let i = 1; i <= QtdeLinhasAtivos.length; i = i + 1) {
            const total = document.querySelector(`#total${i}`) as HTMLInputElement
            const porcem = document.querySelector(`#porcem${i}`) as HTMLInputElement
            if (total.value !== "0,00") {
                porcem.value = (((parseFloat(total.value.replaceAll(".", "").replace(",", ".")) / (totalGeralAtivos + totalGeralPassivos)) * 100).toFixed(2)).toString()
                if (porcem.value == "NaN") {
                    porcem.value = "0.00"
                }
            }
        }
        //calculando total ativos -- inicio
        let totaisAtivos: any = {
            mes1: 0,
            mes2: 0,
            mes3: 0,
            mes4: 0,
            mes5: 0,
            mes6: 0,
            mes7: 0,
            mes8: 0,
            mes9: 0,
            mes10: 0,
            mes11: 0,
            mes12: 0,
        }
        for (let i = 0; i < ObjLinhas.length; i = i + 1) {
            totaisAtivos.mes1 = totaisAtivos.mes1 + ObjLinhas[i].mes1
            totaisAtivos.mes2 = totaisAtivos.mes2 + ObjLinhas[i].mes2
            totaisAtivos.mes3 = totaisAtivos.mes3 + ObjLinhas[i].mes3
            totaisAtivos.mes4 = totaisAtivos.mes4 + ObjLinhas[i].mes4
            totaisAtivos.mes5 = totaisAtivos.mes5 + ObjLinhas[i].mes5
            totaisAtivos.mes6 = totaisAtivos.mes1 + ObjLinhas[i].mes6
            totaisAtivos.mes7 = totaisAtivos.mes7 + ObjLinhas[i].mes7
            totaisAtivos.mes8 = totaisAtivos.mes8 + ObjLinhas[i].mes8
            totaisAtivos.mes9 = totaisAtivos.mes9 + ObjLinhas[i].mes9
            totaisAtivos.mes10 = totaisAtivos.mes10 + ObjLinhas[i].mes10
            totaisAtivos.mes11 = totaisAtivos.mes11 + ObjLinhas[i].mes11
            totaisAtivos.mes12 = totaisAtivos.mes12 + ObjLinhas[i].mes12
        }
        for (let i = 0; i < 12; i = i + 1) {
            const total = document.querySelector(`#totalmes${i + 1}_ativos`) as HTMLInputElement
            total.value = formatarValor(totaisAtivos[`mes${i + 1}`].toString())
        }
        //calculando totasl ativos -- fim
    }, [totalGeralAtivos, totalGeralPassivos])
    const [QtdeLinhasDespesas, setQtdeLinhasDespesas] = useState([1])
    const [ObjLinhasDespesas, setObjLinhasDespesas] = useState<any>([])
    const [totalGeralDespesas, setTotalGeralDespesas] = useState(0)
    function CalcularTotalEPorcemDespesas(numeroLinha: any) {
        const novosObjetos = []
        let NovototalGeral = 0
        for (let i = 1; i <= QtdeLinhasDespesas.length; i = i + 1) {

            const despesa = (document.querySelector(`#despesa${i}`) as HTMLInputElement).value
            let valueInputA = (document.querySelector(`#inputdespesaA${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaA${i}`) as HTMLInputElement).value
            let valueInputB = (document.querySelector(`#inputdespesaB${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaB${i}`) as HTMLInputElement).value
            let valueInputC = (document.querySelector(`#inputdespesaC${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaC${i}`) as HTMLInputElement).value
            let valueInputD = (document.querySelector(`#inputdespesaD${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaD${i}`) as HTMLInputElement).value
            let valueInputE = (document.querySelector(`#inputdespesaE${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaE${i}`) as HTMLInputElement).value
            let valueInputF = (document.querySelector(`#inputdespesaF${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaF${i}`) as HTMLInputElement).value
            let valueInputG = (document.querySelector(`#inputdespesaG${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaG${i}`) as HTMLInputElement).value
            let valueInputH = (document.querySelector(`#inputdespesaH${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaH${i}`) as HTMLInputElement).value
            let valueInputI = (document.querySelector(`#inputdespesaI${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaI${i}`) as HTMLInputElement).value
            let valueInputJ = (document.querySelector(`#inputdespesaJ${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaJ${i}`) as HTMLInputElement).value
            let valueInputK = (document.querySelector(`#inputdespesaK${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaK${i}`) as HTMLInputElement).value
            let valueInputL = (document.querySelector(`#inputdespesaL${i}`) as HTMLInputElement).value == "" ? "0,00" : (document.querySelector(`#inputdespesaL${i}`) as HTMLInputElement).value
            const novoObjLinha = {
                despesa: despesa,
                mes1: parseFloat(valueInputA.replaceAll(".", "").replaceAll(",", ".")),
                mes2: parseFloat(valueInputB.replaceAll(".", "").replaceAll(",", ".")),
                mes3: parseFloat(valueInputC.replaceAll(".", "").replaceAll(",", ".")),
                mes4: parseFloat(valueInputD.replaceAll(".", "").replaceAll(",", ".")),
                mes5: parseFloat(valueInputE.replaceAll(".", "").replaceAll(",", ".")),
                mes6: parseFloat(valueInputF.replaceAll(".", "").replaceAll(",", ".")),
                mes7: parseFloat(valueInputG.replaceAll(".", "").replaceAll(",", ".")),
                mes8: parseFloat(valueInputH.replaceAll(".", "").replaceAll(",", ".")),
                mes9: parseFloat(valueInputI.replaceAll(".", "").replaceAll(",", ".")),
                mes10: parseFloat(valueInputJ.replaceAll(".", "").replaceAll(",", ".")),
                mes11: parseFloat(valueInputK.replaceAll(".", "").replaceAll(",", ".")),
                mes12: parseFloat(valueInputL.replaceAll(".", "").replaceAll(",", "."))
            }
            const total = document.querySelector(`#totaldespesa${i}`) as HTMLInputElement
            total.value = formatarValor((novoObjLinha.mes1 + novoObjLinha.mes2 + novoObjLinha.mes3 + novoObjLinha.mes4 + novoObjLinha.mes5 + novoObjLinha.mes6 + novoObjLinha.mes7 + novoObjLinha.mes8 + novoObjLinha.mes9 + novoObjLinha.mes10 + novoObjLinha.mes11 + novoObjLinha.mes12).toString())
            NovototalGeral = NovototalGeral + parseFloat(total.value.replaceAll(".", "").replaceAll(",", "."))
            novosObjetos.push(novoObjLinha)
        }
        setTotalGeralDespesas(NovototalGeral)
        setObjLinhasDespesas(novosObjetos)
        if (numeroLinha !== 0) {
            CriarOuAtualizarLinha("despesa", id_fluxo, numeroLinha, novosObjetos)
        }
    }
    useEffect(function () {
        for (let i = 1; i <= QtdeLinhasDespesas.length; i = i + 1) {
            const total = document.querySelector(`#totaldespesa${i}`) as HTMLInputElement
            const porcem = document.querySelector(`#porcemdespesa${i}`) as HTMLInputElement
            if (total.value !== "0,00") {
                porcem.value = (((parseFloat(total.value.replaceAll(".", "").replace(",", ".")) / (totalGeralAtivos + totalGeralPassivos)) * 100).toFixed(2)).toString()
                if (porcem.value == "NaN") {
                    porcem.value = "0.00"
                }
            }
        }
        //calculando totasl despesa -- inicio
        let totaisDespesa: any = {
            mes1: 0,
            mes2: 0,
            mes3: 0,
            mes4: 0,
            mes5: 0,
            mes6: 0,
            mes7: 0,
            mes8: 0,
            mes9: 0,
            mes10: 0,
            mes11: 0,
            mes12: 0,
        }
        for (let i = 0; i < ObjLinhasDespesas.length; i = i + 1) {
            totaisDespesa.mes1 = totaisDespesa.mes1 + ObjLinhasDespesas[i].mes1
            totaisDespesa.mes2 = totaisDespesa.mes2 + ObjLinhasDespesas[i].mes2
            totaisDespesa.mes3 = totaisDespesa.mes3 + ObjLinhasDespesas[i].mes3
            totaisDespesa.mes4 = totaisDespesa.mes4 + ObjLinhasDespesas[i].mes4
            totaisDespesa.mes5 = totaisDespesa.mes5 + ObjLinhasDespesas[i].mes5
            totaisDespesa.mes6 = totaisDespesa.mes6 + ObjLinhasDespesas[i].mes6
            totaisDespesa.mes7 = totaisDespesa.mes7 + ObjLinhasDespesas[i].mes7
            totaisDespesa.mes8 = totaisDespesa.mes8 + ObjLinhasDespesas[i].mes8
            totaisDespesa.mes9 = totaisDespesa.mes9 + ObjLinhasDespesas[i].mes9
            totaisDespesa.mes10 = totaisDespesa.mes10 + ObjLinhasDespesas[i].mes10
            totaisDespesa.mes11 = totaisDespesa.mes11 + ObjLinhasDespesas[i].mes11
            totaisDespesa.mes12 = totaisDespesa.mes12 + ObjLinhasDespesas[i].mes12
        }
        for (let i = 0; i < 12; i = i + 1) {
            const total = document.querySelector(`#totalmes${i + 1}_despesa`) as HTMLInputElement
            total.value = formatarValor(totaisDespesa[`mes${i + 1}`].toString())
        }
        //calculando totasl despesa -- fim
        CalcularTotalEPorcemPassivos(0)
        CalcularTotalEPorcemAtivos(0)
    }, [totalGeralDespesas])
    useEffect(function () {
        //calculos para os campos do painel consolidado
        //calculando total RECEITAS -- inicio
        let totaisReceita: any = {
            mes1: 0,
            mes2: 0,
            mes3: 0,
            mes4: 0,
            mes5: 0,
            mes6: 0,
            mes7: 0,
            mes8: 0,
            mes9: 0,
            mes10: 0,
            mes11: 0,
            mes12: 0,
        }
        for (let i = 0; i < ObjLinhas.length; i = i + 1) {
            totaisReceita.mes1 = totaisReceita.mes1 + ObjLinhas[i].mes1
            totaisReceita.mes2 = totaisReceita.mes2 + ObjLinhas[i].mes2
            totaisReceita.mes3 = totaisReceita.mes3 + ObjLinhas[i].mes3
            totaisReceita.mes4 = totaisReceita.mes4 + ObjLinhas[i].mes4
            totaisReceita.mes5 = totaisReceita.mes5 + ObjLinhas[i].mes5
            totaisReceita.mes6 = totaisReceita.mes6 + ObjLinhas[i].mes6
            totaisReceita.mes7 = totaisReceita.mes7 + ObjLinhas[i].mes7
            totaisReceita.mes8 = totaisReceita.mes8 + ObjLinhas[i].mes8
            totaisReceita.mes9 = totaisReceita.mes9 + ObjLinhas[i].mes9
            totaisReceita.mes10 = totaisReceita.mes10 + ObjLinhas[i].mes10
            totaisReceita.mes11 = totaisReceita.mes11 + ObjLinhas[i].mes11
            totaisReceita.mes12 = totaisReceita.mes12 + ObjLinhas[i].mes12
        }
        for (let i = 0; i < ObjLinhasPassivos.length; i = i + 1) {
            totaisReceita.mes1 = totaisReceita.mes1 + ObjLinhasPassivos[i].mes1
            totaisReceita.mes2 = totaisReceita.mes2 + ObjLinhasPassivos[i].mes2
            totaisReceita.mes3 = totaisReceita.mes3 + ObjLinhasPassivos[i].mes3
            totaisReceita.mes4 = totaisReceita.mes4 + ObjLinhasPassivos[i].mes4
            totaisReceita.mes5 = totaisReceita.mes5 + ObjLinhasPassivos[i].mes5
            totaisReceita.mes6 = totaisReceita.mes6 + ObjLinhasPassivos[i].mes6
            totaisReceita.mes7 = totaisReceita.mes7 + ObjLinhasPassivos[i].mes7
            totaisReceita.mes8 = totaisReceita.mes8 + ObjLinhasPassivos[i].mes8
            totaisReceita.mes9 = totaisReceita.mes9 + ObjLinhasPassivos[i].mes9
            totaisReceita.mes10 = totaisReceita.mes10 + ObjLinhasPassivos[i].mes10
            totaisReceita.mes11 = totaisReceita.mes11 + ObjLinhasPassivos[i].mes11
            totaisReceita.mes12 = totaisReceita.mes12 + ObjLinhasPassivos[i].mes12
        }
        for (let i = 0; i < 12; i = i + 1) {
            const total = document.querySelector(`#totalmes${i + 1}_receitas`) as HTMLInputElement
            total.value = formatarValor(totaisReceita[`mes${i + 1}`].toString())
        }
        const totalGeralConsolidadoReiceitas = totalGeralAtivos + totalGeralPassivos
        const inputTotalGeralConsolidadoReiceitas = document.querySelector(`#totalGeralConsolidadoReiceitas`) as HTMLInputElement
        inputTotalGeralConsolidadoReiceitas.value = formatarValor(totalGeralConsolidadoReiceitas.toString())

        //calculando variacao
        let totaisDespesa: any = {
            mes1: 0,
            mes2: 0,
            mes3: 0,
            mes4: 0,
            mes5: 0,
            mes6: 0,
            mes7: 0,
            mes8: 0,
            mes9: 0,
            mes10: 0,
            mes11: 0,
            mes12: 0,
        }
        for (let i = 0; i < ObjLinhasDespesas.length; i = i + 1) {
            totaisDespesa.mes1 = totaisDespesa.mes1 + ObjLinhasDespesas[i].mes1
            totaisDespesa.mes2 = totaisDespesa.mes2 + ObjLinhasDespesas[i].mes2
            totaisDespesa.mes3 = totaisDespesa.mes3 + ObjLinhasDespesas[i].mes3
            totaisDespesa.mes4 = totaisDespesa.mes4 + ObjLinhasDespesas[i].mes4
            totaisDespesa.mes5 = totaisDespesa.mes5 + ObjLinhasDespesas[i].mes5
            totaisDespesa.mes6 = totaisDespesa.mes6 + ObjLinhasDespesas[i].mes6
            totaisDespesa.mes7 = totaisDespesa.mes7 + ObjLinhasDespesas[i].mes7
            totaisDespesa.mes8 = totaisDespesa.mes8 + ObjLinhasDespesas[i].mes8
            totaisDespesa.mes9 = totaisDespesa.mes9 + ObjLinhasDespesas[i].mes9
            totaisDespesa.mes10 = totaisDespesa.mes10 + ObjLinhasDespesas[i].mes10
            totaisDespesa.mes11 = totaisDespesa.mes11 + ObjLinhasDespesas[i].mes11
            totaisDespesa.mes12 = totaisDespesa.mes12 + ObjLinhasDespesas[i].mes12
        }
        for (let i = 0; i < 12; i = i + 1) {
            const total = document.querySelector(`#totalmes${i + 1}_variacao`) as HTMLInputElement
            total.value = formatarValor((totaisReceita[`mes${i + 1}`] - totaisDespesa[`mes${i + 1}`]).toString())
        }
        //calculando saldo projetado
        for (let i = 0; i < 12; i = i + 1) {
            if (i == 0) {
                const variacao = document.querySelector(`#totalmes${i + 1}_variacao`) as HTMLInputElement
                const saldo = document.querySelector(`#totalmes${i + 1}_saldo`) as HTMLInputElement
                saldo.value = variacao.value
                //formatando cor do texto
                if (saldo.value.includes("-")) {
                    saldo.className = 'form-control border border-danger text-danger'
                }
                else {
                    saldo.className = 'form-control border border-success text-success'
                }
            }
            else {
                if (i == 11) {
                    const variacaoMesPosterior = document.querySelector(`#totalmes${12}_variacao`) as HTMLInputElement
                    const saldoMesAnterior = document.querySelector(`#totalmes${11}_saldo`) as HTMLInputElement
                    const saldo = document.querySelector(`#totalmes${12}_saldo`) as HTMLInputElement
                    saldo.value = formatarValor((parseFloat(variacaoMesPosterior.value.replaceAll(".", "").replaceAll(",", ".")) + parseFloat(saldoMesAnterior.value.replaceAll(".", "").replaceAll(",", "."))).toString())
                    //formatando cor do texto
                    if (saldo.value.includes("-")) {
                        saldo.className = 'form-control border border-danger text-danger'
                    }
                    else {
                        saldo.className = 'form-control border border-success text-success'
                    }
                }
                else {
                    const variacaoMesPosterior = document.querySelector(`#totalmes${i + 1}_variacao`) as HTMLInputElement
                    const saldoMesAnterior = document.querySelector(`#totalmes${i}_saldo`) as HTMLInputElement
                    const saldo = document.querySelector(`#totalmes${i + 1}_saldo`) as HTMLInputElement
                    saldo.value = formatarValor((parseFloat(variacaoMesPosterior.value.replaceAll(".", "").replaceAll(",", ".")) + parseFloat(saldoMesAnterior.value.replaceAll(".", "").replaceAll(",", "."))).toString())
                    //formatando cor do texto
                    if (saldo.value.includes("-")) {
                        saldo.className = 'form-control border border-danger text-danger'
                    }
                    else {
                        saldo.className = 'form-control border border-success text-success'
                    }
                }
            }
        }
    }, [totalGeralAtivos, totalGeralPassivos, totalGeralDespesas])
    useEffect(function () {
        //calculos para os campos do painel consolidado
        //calculando total ativos -- inicio
        //calculando totasl despesa -- inicio
        let totaisDespesa: any = {
            mes1: 0,
            mes2: 0,
            mes3: 0,
            mes4: 0,
            mes5: 0,
            mes6: 0,
            mes7: 0,
            mes8: 0,
            mes9: 0,
            mes10: 0,
            mes11: 0,
            mes12: 0,
        }
        for (let i = 0; i < ObjLinhasDespesas.length; i = i + 1) {
            totaisDespesa.mes1 = totaisDespesa.mes1 + ObjLinhasDespesas[i].mes1
            totaisDespesa.mes2 = totaisDespesa.mes2 + ObjLinhasDespesas[i].mes2
            totaisDespesa.mes3 = totaisDespesa.mes3 + ObjLinhasDespesas[i].mes3
            totaisDespesa.mes4 = totaisDespesa.mes4 + ObjLinhasDespesas[i].mes4
            totaisDespesa.mes5 = totaisDespesa.mes5 + ObjLinhasDespesas[i].mes5
            totaisDespesa.mes6 = totaisDespesa.mes6 + ObjLinhasDespesas[i].mes6
            totaisDespesa.mes7 = totaisDespesa.mes7 + ObjLinhasDespesas[i].mes7
            totaisDespesa.mes8 = totaisDespesa.mes8 + ObjLinhasDespesas[i].mes8
            totaisDespesa.mes9 = totaisDespesa.mes9 + ObjLinhasDespesas[i].mes9
            totaisDespesa.mes10 = totaisDespesa.mes10 + ObjLinhasDespesas[i].mes10
            totaisDespesa.mes11 = totaisDespesa.mes11 + ObjLinhasDespesas[i].mes11
            totaisDespesa.mes12 = totaisDespesa.mes12 + ObjLinhasDespesas[i].mes12
        }
        for (let i = 0; i < 12; i = i + 1) {
            const total = document.querySelector(`#totalmes${i + 1}_despesas`) as HTMLInputElement
            total.value = formatarValor(totaisDespesa[`mes${i + 1}`].toString())
        }
        const totalGeralConsolidadoDespesas = totalGeralDespesas
        const inputTotalGeralConsolidadoDespesas = document.querySelector(`#totalGeralConsolidadoDespesas`) as HTMLInputElement
        inputTotalGeralConsolidadoDespesas.value = formatarValor(totalGeralConsolidadoDespesas.toString())
        if (totalGeralDespesas !== 0 && totalGeralAtivos + totalGeralAtivos !== 0) {
            const porcemreceita_despesa = (totalGeralDespesas / (totalGeralAtivos + totalGeralPassivos)) * 100
            const inputCondolidadoPorcemDespesa = document.querySelector("#porcemreceita_despesa") as HTMLInputElement
            inputCondolidadoPorcemDespesa.value = formatarValor(porcemreceita_despesa.toString())
        }
        else {
            const inputCondolidadoPorcemDespesa = document.querySelector("#porcemreceita_despesa") as HTMLInputElement
            inputCondolidadoPorcemDespesa.value = "0"
        }
    }, [totalGeralDespesas])
    const [id_fluxo, set_id_fluxo] = useState("0")
    async function CriarOuAtualizarLinha(rota: string, id_fc: any, linha: number, ObjLinhas: any) {
        console.log(rota)
        console.log(linha)
        try {
            if (params.acao !== "visualizar") {
                set_carregando(true)
                const dados = {
                    linhaEnviada: ObjLinhas[linha - 1]
                }
                const resposta = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/cria/atualiza/linha/${rota}/fc/${id_fc}/${linha}`, dados, {
                    headers: {
                        Authorization: token
                    }
                })
                set_carregando(false)
                //carregarLinhasFC()
            }
        } catch (error: any) {
            set_carregando(false)
            toast.error(error.response.data.message || error.message)
        }
    }
    async function carregarLinhasFC() {
        try {
            set_carregando(true)
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/${id_fluxo}`, {
                headers: {
                    Authorization: token
                }
            })
            const ativosCarregados = resposta.data.ativos.length
            const novaQtdeAtivos = [1]
            for (let i = 1; i < ativosCarregados; i = i + 1) {
                const num = i + 1
                novaQtdeAtivos.push(num)
            }
            setQtdeLinhasAtivos(novaQtdeAtivos)
            const passivosCarregados = resposta.data.passivos.length
            const novaQtdePassivos = [1]
            for (let i = 1; i < passivosCarregados; i = i + 1) {
                const num = i + 1
                novaQtdePassivos.push(num)
            }
            setQtdeLinhasPassivos(novaQtdePassivos)
            const despesasCarregados = resposta.data.despesas.length
            const novaQtdeDespesas = [1]
            for (let i = 1; i < despesasCarregados; i = i + 1) {
                const num = i + 1
                novaQtdeDespesas.push(num)
            }
            setQtdeLinhasDespesas(novaQtdeDespesas)
            set_carregando(false)
        } catch (error: any) {
            set_carregando(false)
            toast.error(error.response.data.message || error.message)
        }
    }
    useEffect(function () {
        CalcularTotalEPorcemAtivos(0)
        if (QtdeLinhasAtivos.length > 0) {
            try {
                set_carregando(true)
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/${id_fluxo}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    const ativosCarregados = resposta.data.ativos
                    if (ativosCarregados.length > 0) {
                        for (let i = 0; i < ativosCarregados.length; i = i + 1) {
                            for (let x = 1; x <= 12; x = x + 1) {
                                const alfabeto = [
                                    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"
                                ]
                                const inputSelecionado = document.querySelector(`#input${alfabeto[x - 1]}${i + 1}`) as HTMLInputElement
                                inputSelecionado.value = formatarValor(resposta.data.ativos[i][`mes${x}`].toString())
                            }
                            const RendaAtiva = document.querySelector(`#rendaatv${i + 1}`) as HTMLInputElement
                            RendaAtiva.value = resposta.data.ativos[i].ativo
                        }
                    }
                    CalcularTotalEPorcemAtivos(0)
                    set_carregando(false)
                })
            } catch (error) {

            }
        }
    }, [QtdeLinhasAtivos])
    useEffect(function () {
        CalcularTotalEPorcemPassivos(0)
        if (QtdeLinhasPassivos.length > 0 || params.acao == "visualizar" || params.acao == "editar") {
            try {
                set_carregando(true)
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/${id_fluxo}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    const passivosCarregados = resposta.data.passivos
                    if (passivosCarregados.length > 0) {
                        for (let i = 0; i < passivosCarregados.length; i = i + 1) {
                            for (let x = 1; x <= 12; x = x + 1) {
                                const alfabeto = [
                                    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"
                                ]
                                const inputSelecionado = document.querySelector(`#inputpass${alfabeto[x - 1]}${i + 1}`) as HTMLInputElement
                                inputSelecionado.value = formatarValor(resposta.data.passivos[i][`mes${x}`].toString())
                            }
                            const passivo = document.querySelector(`#rendapass${i + 1}`) as HTMLInputElement
                            passivo.value = resposta.data.passivos[i].passivo
                        }
                    }
                    CalcularTotalEPorcemPassivos(0)
                    set_carregando(false)
                })
            } catch (error) {

            }
        }
    }, [QtdeLinhasPassivos])
    useEffect(function () {
        CalcularTotalEPorcemDespesas(0)
        if (QtdeLinhasDespesas.length > 0) {
            try {
                set_carregando(true)
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/fc/criado/${id_fluxo}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    const despesasCarregados = resposta.data.despesas
                    if (despesasCarregados.length > 0) {
                        for (let i = 0; i < despesasCarregados.length; i = i + 1) {
                            for (let x = 1; x <= 12; x = x + 1) {
                                const alfabeto = [
                                    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"
                                ]
                                const inputSelecionado = document.querySelector(`#inputdespesa${alfabeto[x - 1]}${i + 1}`) as HTMLInputElement
                                inputSelecionado.value = formatarValor(resposta.data.despesas[i][`mes${x}`].toString())
                            }
                            const despesa = document.querySelector(`#despesa${i + 1}`) as HTMLInputElement
                            despesa.value = resposta.data.despesas[i].despesa
                        }
                    }
                    CalcularTotalEPorcemDespesas(0)
                    if (id_otm !== "") {
                        CarregarDetalhesOtm()
                    }
                    set_carregando(false)
                })
            } catch (error) {

            }
        }
    }, [QtdeLinhasDespesas])
    useEffect(function () {
        if (params.id_fluxo != '0') {
            set_ModalMesAno(false)
            set_id_fluxo(params!.id_fluxo!)
        }
        else {
            CarregarFluxoCaixa()
            CarregarOtm()
        }
    }, [])
    useEffect(function () {
        if (id_fluxo != '0' && id_fluxo !== "") {
            carregarLinhasFC()
        }
    }, [id_fluxo])

    //#region carrega otm e fc disponiveis
    const [ListaFluxosCaixas, setListaFluxosCaias] = useState([])
    function CarregarFluxoCaixa() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/cabecalho/fluxos/caixa/${params.id_cliente}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setListaFluxosCaias(resposta.data.cabecalhos)
        }).catch(function (error) {
            toast.error(error.response.data.message || error.message)
        })
    }
    const [ListaOtm, setListaOtm] = useState([])
    const [id_otm, set_id_otm] = useState("")
    function CarregarOtm() {
        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/todos/cabecalhos/${params.id_cliente}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setListaOtm(resposta.data.cabecalhos)
            set_carregando(false)
        }).catch(function (error) {
            set_carregando(false)
            toast.error(error.response.data.message || error.message)
        })
    }
    useEffect(function () {
        if (id_otm !== "") {
            CarregarDetalhesOtm()
        }
    }, [id_otm])
    function CarregarDetalhesOtm() {
        set_carregando(true)
        axios.get(process.env.REACT_APP_API_BASE_URL + `/carregar/linhas/otmz/${params.id_cliente}/${id_otm}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const linhas = resposta.data.linhas
            if (QtdeLinhasDespesas.length > 0) {
                for (let i = 0; i < QtdeLinhasDespesas.length; i = i + 1) {
                    for (let x = 1; x <= 12; x = x + 1) {
                        const alfabeto = [
                            "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"
                        ]
                        const inputSelecionado = document.querySelector(`#inputdespesa${alfabeto[x - 1]}${i + 1}`) as HTMLInputElement
                        inputSelecionado.value = formatarValor(linhas[i][`novoMensal`].toString())
                    }
                }
                CalcularTotalEPorcemDespesas(0)
                CalcularTotalEPorcemPassivos(0)
                CalcularTotalEPorcemAtivos(0)
            }
            set_carregando(false)
        }).catch(function (erro) {
            set_carregando(false)
            console.log(erro)
            //toast.error(erro.response.data.message || erro.message)
        })
    }
    const filial = sessionStorage.getItem("DadosIdfilial")!
    const [id_fluxo_proposto, set_id_fluxo_proposto] = useState("")
    function CriarFluxoProposto() {
        set_carregando(true)
        const dados = {
            mesanobase: mesAnoBase,
            ativos: ObjLinhas,
            passivos: ObjLinhasPassivos,
            despesas: ObjLinhasDespesas
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/novo/fc/proposto/${params.id_cliente}/${filial}/${id_otm}/${id_fluxo}`, dados, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            set_id_fluxo_proposto(resposta.data.fluxoCriado)
            set_ModalMesAno(false)
            set_carregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            set_carregando(false)
        })
    }
    useEffect(function () {
        if (params.acao == "visualizar") {
            set_id_otm(params!.id_otm!)
        }
    }, [])
    useEffect(function () {
        set_mesAnoBase(sessionStorage.getItem("mesanobase")!)
        if (params.acao !== "novo") {
            //forçando dos ativos
            setTimeout(() => {
                const input1 = document.querySelector("#inputA1") as HTMLInputElement
                input1.focus()
                input1.blur()
            }, 1000);
            //forçando dos passivos
            setTimeout(() => {
                const input1 = document.querySelector("#inputpassA1") as HTMLInputElement
                input1.focus()
                input1.blur()
            }, 1100);
            //forçando das despesas
            setTimeout(() => {
                const input1 = document.querySelector("#inputdespesaA1") as HTMLInputElement
                input1.focus()
                input1.blur()
            }, 1200)
        }
    }, [])
    function colorirLabelConsolidado(id: string): string {
        const input = document.querySelector(`#${id}`) as HTMLInputElement
        if (input) {
            if (input.value.includes("-")) {
                return 'form-control border border-danger text-danger'
            }
            else {
                return 'form-control border border-success text-success'
            }
        }
        else {
            return ""
        }
    }
    return (
        <>
            <ModalLoading show={carregando} />
            <div className="main">
                <ContainerTitulo titulo={"Fluxo de Caixa Proposto"} />
                <div className="card">
                    <div className="card-body">
                        <h4 className="text-center">Projeção de receitas e despesas mês/Ano base: {mesAnoBase}</h4>
                        <div>
                            <ButtonCustom
                                invisivel={false}
                                type="button"
                                className="btn btn-light text-danger bg-white border-0"
                                descricaoBotao="Voltar"
                                icone={mdiKeyboardReturn}
                                onclick={() => {
                                    navigate(-1);
                                }}
                            />
                        </div>
                        <div className="row overflow-auto">
                            <div className="d-flex text-white">
                                <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-dark">
                                    <p className="text-center m-0">CONSOLIDADO</p>
                                </div>
                                {ListaMeses.map(function (data: any, index: any) {

                                    return (
                                        <>
                                            <div style={{ width: "80px" }} className={`col-sm col-md-3 col-lg-2 py-1 bg-dark`}>
                                                <p className={`text-center m-0`}>{data.mes}-{data.ano}</p>
                                            </div>
                                        </>
                                    )
                                })}
                                <div style={{ width: "80px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-dark">
                                    <p className="text-center m-0">Total</p>
                                </div>
                                <div style={{ width: "100px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-dark">
                                    <p className="text-center m-0">%Receita</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "150px" }}>
                                    <p className="text-center mt-1"><b>Receitas</b></p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes1_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes2_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes3_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes4_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes5_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes6_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes7_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes8_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes9_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes10_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes11_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes12_receitas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalGeralConsolidadoReiceitas"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        value="100"
                                        type="text"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label="%"
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "150px" }}>
                                    <p className="text-center mt-1"><b>Despesas</b></p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes1_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes2_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes3_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes4_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes5_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes6_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes7_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes8_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes9_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes10_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes11_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes12_despesas"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalGeralConsolidadoDespesas"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="porcemreceita_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label="%"
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "150px" }}>
                                    <p className="text-center mt-1"><b>Variação</b></p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes1_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes2_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes3_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes4_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes5_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes6_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes7_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes8_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes9_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes10_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes11_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-dark"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes12_variacao"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={function () {
                                            return 'form-control border border-dark'
                                        }}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "150px" }}>
                                    <p className="text-center mt-1"><b>Saldo Projetado</b></p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes1_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes1_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes2_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes2_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes3_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes3_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes4_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes4_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes5_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes5_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes6_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes6_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes7_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes7_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes8_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes8_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes9_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes9_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes10_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes10_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes11_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes11_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes12_saldo"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda={colorirLabelConsolidado("totalmes12_saldo")}
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex text-white">
                                    <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-primary">
                                        <p className="text-center m-0">ORIGEM</p>
                                    </div>
                                    {ListaMeses.map(function (data: any) {
                                        return (
                                            <>
                                                <div style={{ width: "80px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-primary">
                                                    <p className="text-center m-0">{data.mes}-{data.ano}</p>
                                                </div>
                                            </>
                                        )
                                    })}
                                    <div style={{ width: "80px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-primary">
                                        <p className="text-center m-0">Total</p>
                                    </div>
                                    <div style={{ width: "80px" }} className="col-sm col-md-3 col-lg-3 py-1 bg-primary">
                                        <p className="text-center m-0">%Renda</p>
                                    </div>
                                </div>
                            </div>
                            {QtdeLinhasAtivos.map(function (numeroLinha) {
                                return <div className="row">
                                    <div className="d-flex text-black">
                                        <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1 d-flex">
                                            <select style={{ width: "150px" }} disabled={params.acao == "visualizar" ? true : false} className="text-dark" id={`rendaatv${numeroLinha}`} aria-label="Floating label select example">
                                                <option value="">Selecione...</option>
                                                {listaRendaAtiva.map((item: any) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputA${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputB${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputC${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputD${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputE${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputF${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputG${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputH${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputI${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputJ${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputK${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputL${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`total${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label="Total R$"
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`porcem${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label="%"
                                            />
                                        </div>
                                    </div>
                                </div>
                            })}
                            <div className="col-sm col-md-3 col-lg-3 py-1">
                                <button className="btn btn-secondary"
                                    onClick={function () {
                                        setQtdeLinhasAtivos([...QtdeLinhasAtivos, QtdeLinhasAtivos.length + 1])
                                    }}>
                                    Add Renda Atv.
                                </button>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1">
                                    <p className="text-center mt-1"><b>Total Renda Ativa</b></p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes1_ativos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes2_ativos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes3_ativos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes4_ativos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes5_ativos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes6_ativos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes7_ativos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes8_ativos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes9_ativos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes10_ativos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes11_ativos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes12_ativos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        value={formatarValor((totalGeralAtivos).toString())}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label="Total R$"
                                    />
                                </div>
                            </div>
                            {QtdeLinhasPassivos.map(function (numeroLinha) {
                                return <div className="row">
                                    <div className="d-flex text-black">
                                        <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1 d-flex">
                                            <select style={{ width: "150px" }} disabled={params.acao == "visualizar" ? true : false} className="text-dark" id={`rendapass${numeroLinha}`} aria-label="Floating label select example">
                                                <option value="">Selecione...</option>
                                                {listaRendaPassiva.map((item: any) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassA${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassB${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassC${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassD${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassE${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassF${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassG${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassH${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassI${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassJ${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassK${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputpassL${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`totalpass${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label="Total R$"
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`porcempass${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label="%"
                                            />
                                        </div>
                                    </div>
                                </div>
                            })}
                            <div className="col-sm col-md-3 col-lg-2 py-1">
                                <button className="btn btn-secondary"
                                    onClick={function () {
                                        setQtdeLinhasPassivos([...QtdeLinhasPassivos, QtdeLinhasPassivos.length + 1])
                                    }}>
                                    Add Renda Pass.
                                </button>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1">
                                    <p className="text-center mt-1"><b>Total Renda Passiva</b></p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes1_passivos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes2_passivos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes3_passivos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes4_passivos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes5_passivos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes6_passivos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes7_passivos"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes8_passivos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes9_passivos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes10_passivos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes11_passivos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes12_passivos"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        value={formatarValor((totalGeralPassivos).toString())}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label="Total R$"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex text-white">
                                    <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-danger">
                                        <p className="text-center m-0">DESTINO</p>
                                    </div>
                                    {ListaMeses.map(function (data: any) {
                                        return (
                                            <>
                                                <div style={{ width: "80px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-danger">
                                                    <p className="text-center m-0">{data.mes}-{data.ano}</p>
                                                </div>
                                            </>
                                        )
                                    })}
                                    <div style={{ width: "80px" }} className="col-sm col-md-3 col-lg-2 py-1 bg-danger">
                                        <p className="text-center m-0">Total</p>
                                    </div>
                                    <div style={{ width: "80px" }} className="col-sm col-md-3 col-lg-3 py-1 bg-danger">
                                        <p className="text-center m-0">%Despesa</p>
                                    </div>
                                </div>
                            </div>
                            {QtdeLinhasDespesas.map(function (numeroLinha) {
                                return <div className="row">
                                    <div className="d-flex text-black">
                                        <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1 d-flex">
                                            <select style={{ width: "150px" }} disabled={params.acao == "visualizar" ? true : false} className="text-dark" id={`despesa${numeroLinha}`} aria-label="Floating label select example">
                                                <option value="">Selecione...</option>
                                                {listadespesas.map((item: any) => (
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaA${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                onBlur={function () {
                                                    console.log("aqui")
                                                    CalcularTotalEPorcemDespesas(numeroLinha)
                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaB${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaC${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaD${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaE${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaF${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaG${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaH${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaI${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaJ${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaK${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`inputdespesaL${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label=""
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`totaldespesa${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label="Total R$"
                                            />
                                        </div>
                                        <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                            <MiniInput
                                                name="real"
                                                type="text"
                                                id={`porcemdespesa${numeroLinha}`}
                                                onChange={function () {

                                                }}
                                                readonly={params.acao == "visualizar"}
                                                classNameInputsSemBorda="form-control text-dark"
                                                placeholder=" "
                                                label="%"
                                            />
                                        </div>
                                    </div>
                                </div>
                            })}
                            <div className="col-sm col-md-3 col-lg-2 py-1">
                                <button className="btn btn-secondary"
                                    onClick={function () {
                                        setQtdeLinhasDespesas([...QtdeLinhasDespesas, QtdeLinhasDespesas.length + 1])
                                    }}>
                                    Add Desp.
                                </button>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: "150px" }} className="col-sm col-md-3 col-lg-2 py-1">
                                    <p className="text-center mt-1"><b>Total de Despesa </b></p>
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes1_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes2_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes3_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes4_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes5_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes6_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        id="totalmes7_despesa"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes8_despesa"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes9_despesa"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes10_despesa"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes11_despesa"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        id="totalmes12_despesa"
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label=""
                                    />
                                </div>
                                <div className="col-sm col-md-3 col-lg-2 py-1" style={{ width: "80px" }}>
                                    <MiniInput
                                        name="real"
                                        type="text"
                                        readonly={true}
                                        onChange={function () {

                                        }}
                                        value={formatarValor((totalGeralDespesas).toString())}
                                        classNameInputsSemBorda="form-control border border-secondary"
                                        placeholder=" "
                                        label="Total R$"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={ModalMesAno}>

                <Modal.Header className="bg-white">
                    <Modal.Title>Mês/Ano Base do Fluxo a ser criado</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o mês/ano do inicio do Fluxo a ser criado.</h4>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select name="" id="" onChange={function (e) {

                                    listarProximosMesesEAnos(parseInt(e.target.value.split("-")[1]), parseInt(e.target.value.split("-")[0]))
                                    set_mesAnoBase(e.target.value)
                                    setMesAnoBase(e.target.value)
                                }} className="select-control bordasInferiorInput w-100">
                                    <option value="" selected></option>
                                    {meses.map(function (data: any) {

                                        return <option className="text-center" value={`${data.ano}-${data.mes}`}>{`${data.mes}/${data.ano}`}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina o Fluxo de caixa Pessoal.</h4>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select value={id_fluxo} name="" id="" onChange={function (e) {
                                    set_id_fluxo(e.target.value)
                                }} className="select-control bordasInferiorInput text-center w-100">
                                    <option value=""></option>
                                    {
                                        ListaFluxosCaixas.map(function (fluxo: any) {
                                            return <option value={fluxo.id_fc}>{fluxo.mesanobase}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg col-md col-sm">
                                <h4 className="text-center">Defina a otimização a ser usada.</h4>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-lg-6 col-md col-sm text-center m-auto">
                                <select value={id_otm} name="" id="" onChange={function (e) {
                                    set_id_otm(e.target.value)
                                }} className="select-control bordasInferiorInput text-center w-100">
                                    <option value=""></option>
                                    {ListaOtm.map(function (otm: any) {
                                        return <option value={otm.id_otm}>{otm.mesanobase}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ButtonCustom
                                    invisivel={false}
                                    type="button"
                                    className="btn btn-light text-danger bg-white border-0"
                                    descricaoBotao="Cancelar"
                                    icone={mdiKeyboardReturn}
                                    onclick={() => {
                                        set_ModalMesAno(false)
                                        navigate(-1);
                                    }}
                                />
                            </div>
                            <div className="col text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    disabled={params.acao == 'visualizar' || id_otm == "" || id_fluxo == "" ? true : false}
                                    type="button"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Continuar"
                                    onclick={function () {
                                        CriarFluxoProposto()
                                    }}
                                    icone={mdiCheckCircle}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NovoFCPropostoForm